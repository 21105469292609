import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';
import ReactHtmlParser from 'react-html-parser';
import { Picture } from '../../../common';
import { ReactComponent as NoAvatar } from '../../../common/images/no-avatar.icon.svg';
import { ReactComponent as IconVote } from './images/vote.icon.svg';

class CommentsItem extends Component {

  static #propTypes = {
    comment: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }
  
  constructor() {
    super();

    this.state = {
      rate: null,
      rated: null,
      isSpam: false,
    };
  }

  handleAddRate = (isPositive) => {
    const { comment } = this.props;
    const commentId = comment.id;
    const uri = `/udata/comments/rate/${commentId}/${isPositive}.json`;

    fetch(uri, { credentials: 'same-origin' })
      .then(response => response.json())
      .then(json => {
        this.setState({
          rate: json.rate,
          rated: true,
          isSpam: json.isSpam,
        });
      });
  }

  render () {

    const { user, comment } = this.props;
    const { rate, rated, isSpam } = this.state;
    const date = moment(comment.publishTime * 1000);
    const rating = rate !== null ? rate : comment.rate || 0;
    const userIsGuest = user.type === 'guest';
    const userIsOwner = comment.author?.id === user.id;
    const isRated = rated !== null ? rated : comment.userRated;
    const canRate = !userIsGuest && !userIsOwner && !isRated;
    const avatar = userIsOwner ? user.avatar : comment.author?.avatar;
    const authorName = userIsOwner ? user.fname : (comment.author?.fname || 'Автор удален');

    return !isSpam
      ? (
        <div className="comments-item">
          <div className="comments-item__image">
            {
              avatar
                ? (
                  <Picture image={avatar} alt={authorName} width={50}/>
                )
                : (
                  <NoAvatar/>
                )
            }
          </div>
          <div className="comments-item__area">
            <div className="comments-item__bar">
              <div className="comments-item__bar-section">
                <div className="comments-item__author">
                  {authorName}
                </div>
                <div className="comments-item__date" title={date.format('DD.MM.YYYY в HH:mm')}>
                  {date.startOf('minute').fromNow()}
                </div>
              </div>
              <div className="comments-item__rate">
                {
                  canRate
                    ? (
                      <div className="comments-item__rate">
                        {/* eslint-disable-next-line */}
                        <div
                          className="comments-item__rate-control mod_negative"
                          onClick={() => this.handleAddRate(0)}
                        >
                          <IconVote/>
                        </div>
                        <div className="comments-item__rate-number">
                          {rating}
                        </div>
                        {/* eslint-disable-next-line */}
                        <div
                          className="comments-item__rate-control"
                          onClick={() => this.handleAddRate(1)}
                        >
                          <IconVote/>
                        </div>
                      </div>
                    )
                    : (
                      <div className="comments-item__rate">
                        <div className="comments-item__rate-number">
                          {rating}
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
            <div className="comments-item__message">
              {ReactHtmlParser(comment.message.replace(/(?:\r\n|\r|\n)/g, '<br>'))}
            </div>
          </div>
        </div>
      )
      : null;
  }
}

export default CommentsItem;
