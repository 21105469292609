import React from 'react';
import Item from '../Item';

export const List = ({ places, load }) => (
  places.length
    ? (
      places.map(place =>
        <Item
          key={place.id}
          place={place}
          load={load}
        />,
      )
    )
    : (
      <div className="my-places-list__empty">
        <div className="my-places-list__empty-caption">
            Добавь любимые места
        </div>
        <div className="my-places-list__empty-legend">
          <div className="my-places-list__empty-desc">
              нажми кнопку «Подписаться»
              на&nbsp;детальной странице заведения
          </div>
          <div className="place-item__subscribe my-places-list__empty-button">
            <div className="button-loader mod_pink">
              <div className="button-loader__button" style={{ cursor: 'default' }}>
                <div className="button-loader__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14 14">
                    <path fill="#D994AB" d="M7.5.557v5.846h5.777v1H7.5v6.218h-1V7.403H.214v-1H6.5V.557h1z"/>
                  </svg>
                </div>
                <div className="button-loader__caption">Подписаться</div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-places-list__empty-infographic"/>
      </div>
    )
);

export const Placeholder = () => (
  [...(new Array(10))].map((i, key) =>
    <div className="my-places-list-item mod_placeholder" key={key}>
      <div className="my-places-list-item__image mod_visible"/>
      <div className="my-places-list-item__info">
        <div className="my-places-list-item__caption mod_visible"/>
        <div className="my-places-list-item__type mod_visible"/>
        <div className="my-places-list-item__button-row">
          <div className="button-loader mod_visible"/>
        </div>
      </div>
    </div>,
  )
);
