import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { PageContext } from '../../../../contexts';
import {
  Picture,
  CombinedDate,
  PhoneNumber,
  PlaceName, 
} from '../../../common';
import Control from '../Control';
import { ReactComponent as LikeIcon } from './images/like.icon.svg';
import { ReactComponent as ChoiceIcon } from './images/choice.icon.svg';
import Share from '../Share';
import PartyArtists from '../PartyArtists';
import Properties from '../Properties';
import CommentsList from '../../../comments/list/List';
import { TopDate } from '../TopDate';

const Party = (
  {
    id,
    h1,
    image,
    date_start: dateStart,
    date_end: dateEnd,
    minAge,
    fc,
    dc,
    goGo,
    place,
    weekly,
    spg,
    mcs,
    djs,
    cost,
    preorder,
    likes,
    willGo,
    content,
    compliment,
    promo,
    partners,
    setLiked,
    setWillGo,
    likedUsersBlocked,
    willGoUsersBlocked,
  },
) => {

  const { page, user } = useContext(PageContext);
  const isMobile = page.device.mobile;
  const startDate = moment(dateStart['unix-timestamp'] * 1000);
  const endDate = moment(dateEnd['unix-timestamp'] * 1000);
  const isLiked = likes.includes(user.id);
  const isWillGo = willGo.includes(user.id);

  const fcDcText = (fc ? 'FC' : '') + (fc && dc ? '/' : '') + (dc ? 'DC' : '');
  const goGoText = goGo ? 'GoGo' : '';

  const partnersList = partners?.item?.doArray() || [];

  const [imageLoaded, setImageLoaded] = useState(0);
  const formattedCost = parseInt(cost, 10) === 0
    ? 'free'
    : cost && (cost.trim() + (/[^₽a-zA-Zа-яА-ЯёЁ]+$/.test(cost.trim()) ? ' ₽' : ''));

  const handleSetLike = value => {
    if ( !likedUsersBlocked ) {
      setLiked({ id, isLiked: value, userId: user.id });
    }
  };

  const handleSetWillGo = () => {
    if ( !willGoUsersBlocked ) {
      setWillGo({ id, isWillGo, userId: user.id });
    }
  };

  return (
    <div className="party-item">
      <div className="party-item__title">
        <div className="party-item__date">
          <TopDate
            startDate={startDate}
            endDate={endDate}
            weekly={weekly}
            isMobile={isMobile}
          />
        </div>
        <h1 className="party-item__title-name">
          {h1}
        </h1>
      </div>
      <div className="party-item__area">
        <div className="party-item__left-side">
          <div className={`party-item__image${  !imageLoaded ? ' mod_preload' : ''}`}>
            <Picture image={image} width={444} onImageLoaded={() => setImageLoaded(1)}/>
          </div>

          <div className="party-item__controls">
            <Control
              is_active={isLiked}
              isBlocked={likedUsersBlocked}
              isMobile={isMobile}
              user={user}
              mod="like"
              icon={<LikeIcon/>}
              noactive_text="не нравится"
              active_text="мне нравится"
              onChange={handleSetLike}
            />

            <Control
              is_active={isWillGo}
              isBlocked={willGoUsersBlocked}
              isMobile={isMobile}
              user={user}
              mod="check"
              icon={<ChoiceIcon/>}
              noactive_text="не иду"
              active_text="я иду"
              onChange={handleSetWillGo}
            />

            <Share/>

            <div className="party-item__will-go">
              Пойдут: {willGo.length}&nbsp;чел.
            </div>
          </div>
        </div>

        <div className="party-item__enter">
          <div className="party-item__min-age">
            {minAge ? `${minAge  }+` : null}
          </div>
          <div className="party-item__fc-dc">
            {fcDcText}
          </div>
          <div className="party-item__fc-dc">
            {goGoText}
          </div>
        </div>

        <div className="party-item__properties">
          <Properties list={[
            {
              caption: 'Где',
              mod: 'place',
              value: (
                place ?
                  <div className="party-item__place">
                    <div className="party-item__place-image">
                      <Link to={place.link} title={place.name}>
                        <Picture image={place.img}/>
                      </Link>
                    </div>
                    <div>
                      <h2 className="party-item__place-caption">
                        <Link to={place.link} title={place.name}>
                          <PlaceName name={place.name}/>
                        </Link>
                      </h2>
                      <div className="party-item__place-type">
                        {place.type}
                      </div>
                      <div className="party-item__place-address">
                        {place.address}
                      </div>
                    </div>
                  </div>
                  : null
              ),
            },
            {
              caption: 'Когда',
              value: <CombinedDate startDate={startDate} endDate={endDate} hideTime isWeekly={weekly}/>,
            },
            {
              caption: 'Время',
              value: <CombinedDate startDate={startDate} endDate={endDate} hideDays isWeekly={weekly}/>,
            },
            {
              caption: 'Гости',
              mod: 'artists',
              value: (
                spg ?
                  <PartyArtists artists={spg.item}/>
                  : null
              ),
            },
            {
              caption: 'MCs',
              mod: 'artists',
              value: (
                mcs ?
                  <PartyArtists artists={mcs.item}/>
                  : null
              ),
            },
            {
              caption: 'DJs',
              mod: 'artists',
              value: (
                djs ?
                  <PartyArtists artists={djs.item}/>
                  : null
              ),
            },
            {
              caption: 'Тел',
              mod: 'strong',
              value: (
                place ?
                  <PhoneNumber phone={place.phone}/>
                  : null
              ),
            },
            {
              caption: 'Вход',
              mod: 'strong',
              value: (
                cost ?
                  formattedCost
                  : null
              ),
            },
            {
              caption: 'Места',
              value: (
                preorder ?
                  'ограничено'
                  : null
              ),
            },
          ]}
          />
        </div>
      </div>

      <div className="party-item__content">
        {ReactHtmlParser(content)}
      </div>

      {
        compliment || promo || partnersList.length
          ? (
            <div className="party-item__options">
              {
                compliment || promo
                  ? (
                    <div className="party-item__options-section mod_first">
                      {compliment
                        ? (
                          <div className="party-item__option">
                            <div className="party-item__option-caption">
                              Комплимент от заведения
                            </div>
                            <div className="party-item__option-value">
                              {ReactHtmlParser(compliment)}
                            </div>
                          </div>
                        )
                        : null
                      }
                      {
                        promo
                          ? (
                            <div className="party-item__option">
                              <div className="party-item__option-caption">
                                Акции и промокоды
                              </div>
                              <div className="party-item__option-value">
                                {ReactHtmlParser(promo)}
                              </div>
                            </div>
                          )
                          : null
                      }

                    </div>
                  )
                  : null
              }
              <div className="party-item__options-section">
                {
                  partnersList.length
                    ?
                    <div className="party-item__option">
                      <div className="party-item__option-caption">
                        Генеральные партнеры мероприятия
                      </div>
                      <div className="party-item__option-value">
                        <div className="party-item__partners">
                          {
                            partnersList.map((item, i) => {
                              const img = item.image ?
                                (<img
                                  src={item.image.src}
                                  alt={item.name}
                                  title={item.name}
                                  height="40"
                                />
                                )
                                : (
                                  <div
                                    style={{ fontSize: '12px', textAlign: 'center', maxWidth: '80px' }}
                                  >
                                    Загрузите логотип <strong>{item.name}</strong>
                                  </div>
                                );

                              return (
                                <div className="party-item__partner" key={i}>
                                  {
                                    item.link
                                      ? <a href={item.link} target="_blank" rel="noopener noreferrer nofollow">{img}</a>
                                      : img
                                  }
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          )
          : null
      }

      <div className="party-item__comments">
        <CommentsList pageId={id} user={user}/>
      </div>
    </div>
  );
};

export default Party;
