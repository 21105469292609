import React from 'react';
import './styles.scss';
import { ReactComponent as PlaceIcon } from '../List/images/place.icon.svg';

const SearchListPlaceholder = () => (
  <div className="search-list">
    <div className="search-list__placeholder">
      <div className="search-list__places">
        <div className="search-list__places-caption">
          <div className="search-list__places-caption-icon">
            <PlaceIcon/>
          </div>
          Места
        </div>
        <div className="search-list__places-list">
          <div className="search-list__places-item"/>
          <div className="search-list__places-item"/>
          <div className="search-list__places-item"/>
          <div className="search-list__places-item"/>
        </div>
      </div>
      <div className="search-list__other">
        <div className="search-list__other-caption mod_visible"/>
        <div className="search-list__other-list">
          {
            [0,1,2,3,4].map(item => (
              <div className="search-list__item" key={item}>
                <div className="search-list__item-side">
                  <div className="search-list__item-link mod_visible"/>
                  <div className="mod_visible">
                    <p/>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </div>
);

export default SearchListPlaceholder;
