const container = document.getElementById('mainContainer');

const device = () => {
  const style = window.getComputedStyle(container);
  const minWidth = style ? style.minWidth : 0;

  return {
    mobile: minWidth === '1px', 
    tablet: minWidth === '2px',
  };
};

export default device;
