import history from '../../history';
import device from '../../device';
import { createPageCache, queryToObject } from './helpers';

const initialState = {
  uri: '',
  device: device(),
  parents: [],
};

const pagesCache = createPageCache();

export function page(state = initialState, action){
  switch (action.type){

    case 'CHANGE_PATH_PAGE': {
      const { pathname, search } = action.payload.location;
      const uri = pathname + search;

      if (state.uri === uri)
        return state;

      const cache = pagesCache.get(pathname);

      if (history.action === 'POP' && cache) {

        return {
          ...state,
          ...cache,
          uri,
          historyAction: action.payload.action,
          path: pathname,
          query_string: search,
          query: queryToObject(search),
          status: 'loaded',
          isCached: true,
        };
      }

      return {
        ...state,
        uri,
        historyAction: action.payload.action,
        path: pathname,
        query_string: search,
        query: queryToObject(search),
        status: 'changed',
        isCached: false,
      };
    }

    case 'PAGE_LOADED': {
      const parents = action.payload.parents || null;

      const result = {
        ...state,
        ...action.payload,
        parents,
        status: 'loaded',
      };

      pagesCache.add(state.path, action.payload);

      return result;
    }

    case 'PAGE_LOAD_FAILURE': {
      return {
        ...state,
        module: 'content',
        method: 'notfound',
        status: 'loaded',
      };
    }

    case 'WINDOW_RESIZED':
      return {
        ...state,
        device: device(),
      };

    default:
      return state;
  }
}
