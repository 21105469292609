import React, { Component } from 'react';

class SocialIcon extends Component {

  constructor ( props ) {
    super(props);

    this.state = {
      is_hover: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({
      is_hover: true,
    });
  }

  handleMouseLeave = () => { 
    this.setState({
      is_hover: false,
    });
  }

  render () {

    const { children } = this.props;
    const { is_hover: isHover } = this.state;

    return (
      <div
        className={`auth-container__socials-icon${  !isHover ? ' mod_filled' : ''}`}
        onTouchStart={this.handleMouseEnter}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </div>
    );
  }
}

export default SocialIcon;
