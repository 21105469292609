import React from 'react';
import { Link } from 'react-router-dom';
import PlaceCard from '../../PlaceCard';
import {
  CombinedDate,
  SquarePicture,
  PhoneNumber,
  PlaceName,
} from '../../../common';
import {
  IconPlace,
  IconPhone,
  IconClock,
} from '../../../common/images';
import './styles.sass';

const PartiesListItem = props => {

  const { page, party } = props;
  const { place } = party;
  const startDate = party['date-start']['unix-timestamp'];
  const endDate = party['date-end']['unix-timestamp'];

  return (
    <div className="parties-list-item">
      <Link to={party.link}>
        <SquarePicture
          image={party?.image}
          alt={party.name}
          mobileSimple 
        />
      </Link>

      <div className="parties-list-item__title">
        <Link to={party.link}>
          {party.name}
        </Link>
      </div>

      <div className="parties-list-item__properties">
        <div className="parties-list-item__property parties-list-item__property_date">
          <i className="parties-list-item__icon mod_time">
            <IconClock/>
          </i>
          <CombinedDate
            startDate={startDate}
            endDate={endDate}
            useWordInTime
            allwaysShowDates
            isWeekly={party.isWeekly}
          />
        </div>

        {
          place
            ? (
              <div className="parties-list-item__property">
                <i className="parties-list-item__icon mod_place">
                  <IconPlace/>
                </i>
                <div className="parties-list-item__place">
                  {
                    !page.device.mobile
                      ? (
                        <PlaceCard place={place}/>
                      )
                      : (
                        <Link to={place.link} title={place.name}>
                          <PlaceName name={place.name}/>
                        </Link>
                      )
                  }
                </div>
              </div>
            )
            : null
        }

        {
          place
            ?
            <div className="parties-list-item__property parties-list-item__property_phone">
              <i className="parties-list-item__icon">
                <IconPhone/>
              </i>
              <div>
                <PhoneNumber phone={place.phone}/>
              </div>
            </div>
            : null
        }
      </div>

    </div>
  );
};

export default PartiesListItem;
