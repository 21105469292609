import React from 'react';
import './styles.sass';
import NavBar from "../../../common/NavBar";

export const BlogsSide = ({ items }) => {
  return (
    <>
      <h2>Темы блога</h2>
      <div className="blogs-side__list">
        <NavBar
          links={items}
          role="navigation"
          aria-label="Навигация по темам блога"
        />
      </div>
    </>
  );
}

export const BlogsSidePlaceholder = () => (
  <div className="content-section-preloader">
    <div className="content-section-preloader__title mod_visible"/>
    <div className="content-section__area">
      <div className="content-section-preloader__image-area">
        <div className="content-section-preloader__image mod_visible"/>
        <div>
          {[...(new Array(5))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
        </div>
      </div>
      {[...(new Array(15))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
    </div>
  </div>
);
