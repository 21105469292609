import {
  loadListRequest,
  loadTypesRequest,
} from '../api/blog';

export const loadList = ({ path, page }) => ({
  type: 'PROMISE',
  actions: ['BLOG_LIST_LOADING', 'BLOG_LIST_LOADED', 'BLOG_LIST_LOAD_FAILURE'],
  promise: loadListRequest({ path, page }),
});

export const loadPartial = ({ path, page }) => ({
  type: 'PROMISE',
  actions: ['BLOG_LIST_PARTIAL_LOADING', 'BLOG_LIST_PARTIAL_LOADED', 'BLOG_LIST_PARTIAL_LOAD_FAILURE'],
  promise: loadListRequest({ path, page }),
});

export const loadTypes = () => ({
  type: 'PROMISE',
  actions: ['BLOG_TYPES_LOADING', 'BLOG_TYPES_LOADED', 'BLOG_TYPES_LOAD_FAILURE'],
  promise: loadTypesRequest(),
});
