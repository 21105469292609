import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { PageContext } from '../../contexts';
import {
  BlogsItem,
  BlogsItemPlaceholder,
} from '../../components/blogs/item/Item';

const BlogItem = ({ blogsPost: { post, status } }) => {
  const { page } = useContext(PageContext);

  useEffect(() => {
    if(!page.isCached){
      const element = document.querySelector('.page-container');

      scrollIntoView(element, {
        time: 0,
        align: {
          top: 0,
        },
      });
    }
  }, [page.isCached]);

  if ( status === 'loaded' ){
    return <BlogsItem post={post}/>;
  }

  return <BlogsItemPlaceholder/>;
};

export default connect(
  (state) => ({
    blogsPost: state.blogsPost,
  }),
)(BlogItem);
