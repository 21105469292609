import React from 'react';
import { Link } from 'react-router-dom';

const PartyArtists = ({ artists, isSpecial }) => (
  <div className="party-artists">
    {
      artists.doArray().map(( item, key ) => (
        <div className={`party-item__artists-item${  isSpecial ? ' mod_special' : ''}`} key={key}>
          {
            false && item.isActive
              ? <Link to={item.link}>{item.name}</Link>
              : item.name 
          }
        </div>
      ))
    }
  </div>
);

export default PartyArtists;
