import React, { Component } from 'react';
import Input from '../../../../common/Input';
import { ReactComponent as EditIcon } from './images/edit.icon.svg';

class NameEditor extends Component {
  constructor() {
    super();

    this.state = {
      valid: true,
      active: false,
      newValue: '',
    };
  }

  componentDidUpdate (prevProps) {
    const { user: { fnameLoading } } = this.props;
    const prevFnameLoading = prevProps.user.fnameLoading;

    if ( prevFnameLoading && fnameLoading !== prevFnameLoading )
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        active: false,
      });
  }

  handleIconClick = () => {
    this.setState({
      active: true,
    });
  }

  handleSubmit = () => {
    const { user, changeName } = this.props;
    const { newValue, valid } = this.state;

    if (!valid)
      return false;

    if (newValue && user.fname !== newValue)
      changeName(newValue);
    else if (!newValue || user.fname === newValue) {
      this.setState({
        active: false,
      });
    }

    return null;
  }

  handleBlur = () => {
    this.handleSubmit();
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleChange = (valid, value) => {
    const newState = {
      valid,
    };

    if ( valid )
      newState.newValue = value;

    this.setState(newState);
  }

  render () {

    const { user } = this.props;
    const { active } = this.state;

    return (
      <div className="my-profile-fast-settings__name">
        <div className="my-profile-fast-settings__name-area">
          {
            active
              ? (
                <Input
                  className="my-profile-fast-settings__name-input"
                  placeholder="Имя"
                  value={user.fname}
                  validate
                  required
                  minlength={3}
                  maxlength={11}
                  fixMaxLength
                  customValidate={value => /^[\wа-яёА-ЯЁ]+$/.test(value)}
                  isFocused
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              )
              : (
                <div className="my-profile-fast-settings__name-value">
                  {user.fname}
                </div>
              )
          }
        </div>
        {
          !active
            ? (
              <div className={`my-profile-fast-settings__name-icon${  active ? ' mod_save' : ''}`}>
                <EditIcon onClick={this.handleIconClick}/>
              </div>
            )
            : null
        }
      </div>
    );
  }
}

export default NameEditor;
