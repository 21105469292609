import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import {
  IconAffiche,
  IconPlaces,
  IconGeo,
  IconBlog,
} from './images';
import {
  IconSearch,
} from '../images';

const MenuItem = ({ caption, icon, isActive, to, label }) => (
  <NavLink
    className="main-menu-mobile__item"
    activeClassName="mod_active"
    isActive={isActive}
    to={to}
    aria-label={label}
  >
    {icon}
    <div className="main-menu-mobile__item-caption">{caption}</div>
  </NavLink>
);

const MainMenuMobile = () => (
  <div className="main-menu-mobile"> 
    <MenuItem
      isActive={(match, location) => location.pathname === '/' || /^\/affiche/.test(location.pathname)}
      to="/"
      label="Афиша. Иконка календаря"
      icon={<IconAffiche/>}
      caption="Афиша"
    />
    <MenuItem
      to="/place/"
      label="Места. Иконка бара"
      icon={<IconPlaces/>}
      caption="Места"
    />
    <MenuItem
      to="/blog/"
      label="Блог. Иконка записи в блокноте"
      icon={<IconBlog/>}
      caption="Блог"
    />
    <MenuItem
      to="/map/"
      label="Карта. Иконка геопозиции"
      icon={<IconGeo/>}
      caption="Карта"
    />
    <MenuItem
      to="/search/"
      label="Поиск. Иконка лупы"
      icon={<IconSearch width={22} height={22}/>}
      caption="Поиск"
    />
  </div>
);

const getProps = (state) => ({
  menu: state.mainMenu,
});
const setActions = () => ({});

export default connect(getProps, setActions)(MainMenuMobile);
