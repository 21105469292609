import React from 'react';
import { Redirect } from 'react-router-dom';
import List from './List';
// import PartiesMap from '../Map/'

export const PartiesListContainer = props => {

  const { page, page: { pageId, query_string: queryString /* device */ } } = props;

  return (
    <>
      {
        page.path === '/affiche/all/'
          ? (
            <Redirect to="/"/>
          )
          : (
            <List
              page={page}
              pageId={pageId}
              query_string={queryString}
            />
          )
      }

      {/* { 
        !device.mobile
          ? (
            <PartiesMap device={device}/>
          )
          : null
      } */}
    </>
  );
};
