import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './history';
import store from './store';
import saga from './sagas/index';
import App from './containers/App';
import { theme } from './theme';
import './polyfill';
import { ThemeProvider } from "@mui/material";

store.runSaga(saga, history, store.dispatch);

let
  pathname = null;
let search = null;
const changePath = ({ location, action = null }) => {
  if(pathname !== location.pathname || search !== location.search) {
    store.dispatch({
      type: 'CHANGE_PATH_PAGE',
      payload: {
        location,
        action,
      },
    });
    pathname = location.pathname;
    search = location.search;
  }
};

changePath({ location: window.location });

history.listen((location, action) => {
  changePath({ location, action });
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <App store={store}/>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('mainContainer'),
);
