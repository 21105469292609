/* eslint-disable */
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

if (!Object.prototype.doArray) {
  // eslint-disable-next-line
  Object.defineProperty(Object.prototype, 'doArray', {
    value(){
      const o = Object(this);

      return Object.keys(o || {})
        .map(key => o[key]);
    },
  });
} else {
  console.error('Method `doArray` is already exists in Object type');
}

// Element.matches
(function(e) {
  const matches = e.matches || e.matchesSelector || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector || e.oMatchesSelector;

  !matches ? (e.matches = e.matchesSelector = function matches(selector) {
    const matches = document.querySelectorAll(selector);
    const th = this;

    return Array.prototype.some.call(matches, (e) => e === th);
  }) : (e.matches = e.matchesSelector = matches);
})(Element.prototype); 
