import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import scrollIntoView from 'scroll-into-view';
import { PageContext } from '../../contexts';
import { setLiked, setWillGo } from '../../actions/parties';
import Party from '../../components/parties/item/Item/Party';
import PartyPlaceholder from '../../components/parties/item/Placeholder/Placeholder';

const PartyContainer = ({ partyItem: { party, status }, setLiked: setLikedAction, setWillGo: setWillGoAction }) => {
  const { page } = useContext(PageContext);

  useEffect(() => {
    if(!page.isCached){
      const element = document.querySelector('.scroll-position');

      scrollIntoView(element, { 
        time: 0,
        align: {
          top: 0,
        },
      });
    }
  }, [page.isCached]);

  return (
    <>
      <div className="scroll-position party-item__scroll-position"/>
      {
        status !== 'loaded'
          ? <PartyPlaceholder/>
          : <Party {...party} setLiked={setLikedAction} setWillGo={setWillGoAction}/>
      }
    </>
  );
};

export default connect(
  (state) => ({
    partyItem: state.partyItem,
  }),
  (dispatch) => bindActionCreators({
    setLiked,
    setWillGo,
  }, dispatch),
)(PartyContainer);
