import React, { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import scrollIntoView from 'scroll-into-view';
import PageContext from '../../../../contexts/page';
import ListItem from '../ListItem';
import Placeholder from '../Placeholder';
import { ButtonLoader } from '../../../common';
import './styles.scss';

const PlacesList = ({ title, description, items, status, total, pageNum, loadPartial }) => {
  const { page: { path, domain, isCached } } = useContext(PageContext);
  const [pageNumber, setPageNumber] = useState(pageNum);
  const isLoading = status === 'loading';
  const isMoreLoading = status === 'partial-loading';
  const isAllLoaded = items.length >= total;

  useEffect(() => {
    if ( !isCached ) {
      const element = document.querySelector('.places__item-scroll-position');

      scrollIntoView(element, { 
        time: 0,
        align: {
          top: 0,
          topOffset: 0,
        },
      });
    }
  }, [isCached]);

  const handleMore = () => {
    setPageNumber(pageNumber+1);
    loadPartial({ path, pageNumber });
  };

  const sortItems = (a, b) => {
    const aParties = a.parties || [];
    const bParties = b.parties || [];

    // debugger

    if(aParties.length === 0 && bParties.length === 0)
      return 0;
    if(aParties.length === 0)
      return 1;
    if(bParties.length === 0)
      return -1;

    const getDate = item => item?.['date-start'].timestamp;

    if(getDate(aParties[0]) > getDate(bParties[0]))
      return 1;

    if(getDate(aParties[0]) < getDate(bParties[0])){
      return -1;
    }

    return 0;
  };

  return (
    <>
      <h1 className="title">{title}</h1>
      <div className="places-list">
        {
          isLoading
            ? <Placeholder/>
            : (
              items.sort(sortItems).map(place =>
                <ListItem
                  key={place.id}
                  place={place}
                  domain={domain}
                />,
              )
            )
        }
        {
          !isAllLoaded &&
          <div className="places-list__button-row">
            <ButtonLoader
              isLoading={isMoreLoading}
              text="Загрузить ещё"
              onClick={handleMore}
            />
          </div>
        }
      </div>
      {
        description &&
        <div className="places-list__description rich-text">
          {ReactHtmlParser(description)}
        </div>
      }
    </>
  );
};

export default PlacesList;
