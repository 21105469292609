import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router'; 
import PromoList from '../../components/promo/list/List';
import { loadList } from '../../actions/promo';

const Promo = ({ page, promo, loadList: loadListAction }) => (
  <Switch>
    <Route exact path="/promotions/">
      <PromoList
        page={page}
        promo={promo}
        loadList={loadListAction}
        loadPartial={() => {}}
      />
    </Route>
    <Route exact path="/promotions/:item/">
      <div>
        Promo item container
      </div>
    </Route>
  </Switch>
);

const getProps = (state) => ({
  promo: state.promo,
});
const setActions = (dispatch) => bindActionCreators({
  loadList,
}, dispatch);

export default connect(getProps, setActions)(Promo);
