import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SearchList from '../../components/search/list/List';
import { loadList, loadPartial } from '../../actions/search';

class Search extends Component { 

  constructor (props) {
    super(props);

    const { page: { query: { search_string: searchString } }, loadList: loadListAction } = props;

    if(searchString) {
      loadListAction(searchString);
    }
  }

  componentDidUpdate (prevProps) {
    const { page: { query: { search_string: searchString } }, loadList: loadListAction } = this.props;

    if ( searchString !== prevProps.page.query.search_string ) {
      loadListAction(searchString);
    }
  }

  handleMore = () => {
    const { page: { query: { search_string: searchString } }, loadPartial: loadPartialAction, search } = this.props;

    loadPartialAction(searchString, search.cur_page + 1);
  }

  render () {

    const { page: { query }, search } = this.props;

    return (
      <SearchList
        query={query}
        search={search}
        handleMore={this.handleMore}
      />
    );
  }
}

const getProps = (state) => ({
  search: state.search,
});
const setActions = (dispatch) => bindActionCreators({
  loadList,
  loadPartial,
}, dispatch);

export default connect(getProps, setActions)(Search);
