import React, { useEffect } from 'react';
import scrollIntoView from 'scroll-into-view';
import ContentComponent from '../../components/content/content';
import { ContentNotfound } from '../../components/content/notfound/ContentNotfound';

const Content = ({ page }) => {
  const { module, method, status, isCached } = page; 
  const isLoaded = status === 'loaded';

  useEffect(() => {
    if ( !isCached ) {
      const element = document.querySelector('.page-container');

      scrollIntoView(element, {
        time: 0,
        align: {
          top: 0,
          topOffset: 0,
        },
      });
    }
  }, [isCached]);

  return isLoaded
    ? (() => {
      switch ( `${module  }-${  method}` ) {
        case 'content-content':
          return <ContentComponent page={page}/>;
        default:
          return (
            <ContentNotfound/>
          );
      }
    })()
    : (
      <div className="content-section-preloader__area">
        <div className="content-section-preloader">
          <div className="content-section-preloader__title mod_visible"/>
          <div className="content-section__area">
            <div className="content-section-preloader__image-area">
              <div className="content-section-preloader__image mod_visible"/>
              <div>
                {[...(new Array(5))].map((item, i) =>
                  <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
              </div>
            </div>
            {[...(new Array(15))].map((item, i) =>
              <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
          </div>
        </div>
      </div>
    );
};

export default Content;
