import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { default as BlogList} from './BlogList.jsx'
import { default as BlogItem} from './BlogItem.jsx'
import { default as BlogSide} from './BlogSide.jsx'
import { Breadcrumbs } from "../../components/common";
import { PageContext } from "../../contexts";

export const Blog = () => {
  const { page } = useContext(PageContext);

  return (
    <div className="blogs">
      <div className="blogs__side">
        <BlogSide/>
      </div>
      <div className="blogs__area">

        <div style={{margin: '1em 0'}}>
          <Breadcrumbs parents={page.parents} jsonOnly={false}/>
        </div>

        <Switch>
          <Route exact path="/blog/:type?" component={BlogList}/>
          <Route exact path="/blog/:type/:item/" component={BlogItem}/>
        </Switch>
      </div>
    </div>
  );
};
