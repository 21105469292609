import React from 'react';
import moment from 'moment';

import './styles.scss';

const getDay = num => {
  switch ( num ) {
    case 0:
      return 'ое воскресенье';
    case 1:
      return 'ый понедельник';
    case 2:
      return 'ый вторник';
    case 3:
      return 'ую среду';
    case 4:
      return 'ый четверг';
    case 5:
      return 'ую пятницу';
    case 6:
      return 'ую субботу';
    default:
      return '';
  }
};

const CombinedDate = props => {

  let { startDate, endDate } = props;
  const { useWords = true, useWordInTime, allwaysShowDates, isWeekly } = props;
  const { hideTime } = props;
  const { hideDays } = props;

  startDate = typeof startDate !== 'object' ? moment(startDate * 1000) : startDate;
  endDate = typeof endDate !== 'object' ? moment(endDate * 1000) : endDate;

  if(isWeekly){
    startDate = moment().day(startDate.day() || 7).hour(startDate.hour()).minute(startDate.minute());
    endDate = moment().day(startDate.day() || 7).hour(endDate.hour()).minute(endDate.minute());
  }

  const currentDate = moment().set({ hour: 23, minute: 59 });
  const tomorrowDate = moment().set({ hour: 23, minute: 59 }).add(1, 'days');
  const startDateDay = startDate.date();
  const endDateDay = endDate.date();
  const endDateHour = endDate.hour();
  const endDateMinute = endDate.minute();
  const isOneDay = !(endDateDay - startDateDay) || (endDateDay - startDateDay === 1 && (endDateHour < 9 || (endDateHour === 9 && endDateMinute === 0)));
  const endDateMax = moment(endDate.valueOf()).add(1, 'days').set({ hour: 9, minute: 0 });
  const startTime = startDate.format('HH:mm');
  const endTime = endDate.format('HH:mm');
  const isOneTime = startTime === endTime;
  const isToday = currentDate.isBetween(startDate, endDateMax);
  const isTomorrow = !isToday ? tomorrowDate.isBetween(startDate, endDateMax) : false;

  if(endDateHour < 9)
    endDate.subtract(1, 'days');

  return (
    <div className="combined-date">
      {!hideTime
        ?
        <div className="combined-date__time">
          {
            useWords && useWordInTime && (isToday || isTomorrow)
              ? (
                <span className={`combined-date__time-day-word${  isToday ? ' mod_today' : ''}`}>
                  {isToday ? 'сегодня' : 'завтра'}
                </span>
              )
              : (
                null
              )
          }
          {' '}
          {
            isOneTime
              ? startDate.format('в HH:mm')
              : `${startDate.format('c HH:mm')  } до ${  endDate.format('HH:mm')}`
          }
        </div>
        : null
      }
      {!hideDays
        ?
        <div className="combined-date__dates">
          {
            // eslint-disable-next-line no-nested-ternary
            useWords && !useWordInTime && (isToday || isTomorrow)
              ? (
                <span className={`combined-date__time-day-word${  isToday ? ' mod_today' : ''}`}>
                  {isToday ? 'сегодня' : 'завтра'}
                </span>
              )
              : (
                // eslint-disable-next-line no-nested-ternary
                !isWeekly
                  ? (
                    // eslint-disable-next-line no-nested-ternary
                    isOneDay
                      ? allwaysShowDates || !useWordInTime ? startDate.format('DD.MM') : ''
                      : `c ${  startDate.format('DD.MM')  } по ${  endDate.format('DD.MM')}`
                  ) :
                  (
                    `кажд${getDay(startDate.day())}`
                  )
              )

          }
        </div>
        : null
      }
    </div>
  );
};

export default CombinedDate;
