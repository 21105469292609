import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import promisesMiddleware from './middlewares/promises';
import rootReducer from './reducers';
import { isDevelopment } from './environment';

const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware =
  (
    isDevelopment
      ? applyMiddleware(promisesMiddleware, sagaMiddleware, createLogger({ collapsed: true }))
      : applyMiddleware(promisesMiddleware, sagaMiddleware)
  )(createStore);

const store = createStoreWithMiddleware(rootReducer);

store.runSaga = sagaMiddleware.run;

export default store;
