import React from 'react';
import { Link } from 'react-router-dom';
import {
  SquarePicture,
  CombinedDate,
} from '../../../common';
import './styles.scss';

const Parties = ({ parties }) => {

  if(!parties || !parties.length){
    return null;
  }

  return ( 
    <>
      <div className="places-list-item__parties-caption">
        Ближайшие мероприятия:
      </div>
      <div className="places-list-item__parties-list">
        {
          parties.map((party) => (
            <div className="places-list-item__party" key={party.link}>
              <Link to={party.link}>
                <SquarePicture image={party.image} alt={party.name}/>
              </Link>
              <div className="places-list-item__party-caption">
                <CombinedDate
                  startDate={party['date-start'].timestamp}
                  endDate={party['date-end'].timestamp}
                  hideTime
                />
              </div>
            </div>
          ),
          )
        }
      </div>
    </>
  );
};

export default Parties;
