import React, { Component } from 'react';
import { ReactComponent as ShareIcon } from './images/share.icon.svg';
import { ReactComponent as VKIcon } from './images/vk.icon.svg';
import { ReactComponent as FBIcon } from './images/fb.icon.svg';

export default class Share extends Component {
  handleClick = provider => { 

    const url = window.location.href;

    switch ( provider ) {
      case 'vk':
        this.vkontakte({
          url,
        });
        break;
      case 'fb':
        this.facebook({
          url,
        });
        break;
      default:
        break;
    }
  }

  vkontakte = props => {
    let url = 'http://vkontakte.ru/share.php?';

    url += `url=${  encodeURIComponent(props.url)}`;
    this.popup(url);
  }

  facebook = props => {
    const url = `${'https://www.facebook.com/dialog/feed?' +
      'app_id=406395396938385' +
      '&display=popup' +
      '&link='}${  encodeURIComponent(props.url)}`;

    // url += '&p[url]=' + encodeURIComponent(props.url)
    this.popup(url);
  }

  popup = url => {
    const
      dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    let width;

    if (window.innerWidth) {
      width = window.innerWidth;
    } else {
      width = document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    }

    let height;

    if (window.innerHeight) {
      height = window.innerHeight;
    } else {
      height = document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
    }

    const w = 626;
    const h = 436;
    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;

    window.open(url, '', `toolbar=0,status=0,width=${  w  },height=${  h  },top=${  top  },left=${  left}`);
  }

  render () {

    return (
      <div className="party-item__control mod_share">
        <i className="party-item__control-icon" title="Поделиться">
          <ShareIcon/>
        </i>
        <div className="party-item__control-label">
          <div className="party-item__share">
            {/* eslint-disable-next-line */}
            <div className="party-item__share-item" role="button" onClick={() => {
              this.handleClick('vk');
            }}>
              <VKIcon/>
            </div>
            {/*<div className="party-item__share-item mod_fb">
              <FBIcon onClick={() => {
                this.handleClick('fb');
              }}/>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
