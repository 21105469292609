import { isProductionDomain } from './environment';

export const trackPageview = ({ title }) => {
  const path =  `${location.pathname}${location.search}`;

  if (!isProductionDomain) {
    return;
  }

  window.gtag('event', 'page_view');

  window.ym(48965996, 'hit', path, {
    title,
  });
};

export const trackEvent = ({ name }) => {
  if (!isProductionDomain) {
    return;
  }

  window.gtag('event', name);
};
