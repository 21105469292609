import React from 'react';
import { LogoStartSvg, LogoEndSvg } from '../images';
import './styles.sass';

const Logo = ({ mod = '' }) => (
  <div className={`logo ${mod}`} aria-label="Логотип сайта: надпись Week&">
    <span>
      <LogoStartSvg/>
    </span>
    <span>
      <LogoEndSvg/>
    </span>
  </div>
);

export default Logo;
