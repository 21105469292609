import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlaceName } from '../../common';

import './styles.scss';

const PlaceCard = ({ place }) => {

  const [active, setActive] = useState(); 

  if ( !place ) {
    return null;
  }

  return (
    <div className="place-card" onMouseEnter={() => setActive(1)} onMouseLeave={() => setActive()}>
      <div className="place-card__link">
        <Link to={place.link}>
          <PlaceName name={place.name}/>
        </Link>
      </div>
      <div className={`place-card__body${  active ? ' place-card__body_active' : ''}`}>
        <div className="place-card__body-image">
          <Link to={place.link}>
            {
              active
                ? <img src={place.img?.src} alt={place.name} width="100"/>
                : null
            }
          </Link>
        </div>
        <div>
          <div className="party-item__place-caption">
            <PlaceName name={place.name}/>
          </div>
          <div className="party-item__place-type">
            {place.type}
          </div>
          <div className="party-item__place-address">
            {place.address}
          </div>
        </div>
        <div className="place-card__body-after"/>
      </div>
    </div>
  );
};

export default PlaceCard;
