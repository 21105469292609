const blogInitialState = {
  items: [],
  page: 0,
  maxPage: 0,
};

export const blog = (state = blogInitialState, action) => {
  switch ( action.type ) {

    case 'BLOG_LIST_LOADING':
      return {
        ...state,
        status: 'loading', 
      };

    case 'BLOG_LIST_LOADED': {
      const { items, page, maxPage } = action.payload
      return {
        ...state,
        items: items || [],
        page,
        maxPage,
        status: 'loaded',
      };
    }

    case 'BLOG_LIST_PARTIAL_LOADING':
      return {
        ...state,
        status: 'partial-loading',
      };

    case 'BLOG_LIST_PARTIAL_LOADED': {
      const { items, page, maxPage } = action.payload
      return {
        ...state,
        ...action.payload,
        items: [...state.items, ...items],
        page,
        maxPage,
        status: 'loaded',
      };
    }

    default:
      return state;
  }
};

const blogTypesInitialState = {
  status: null,
  items: [],
};

export const blogTypes = (state = blogTypesInitialState, action) => {
  switch ( action.type ) {

    case 'BLOG_TYPES_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'BLOG_TYPES_LOADED': {
      return {
        ...state,
        status: 'loaded',
        items: action.payload || [],
      };
    }

    default:
      return state;
  }
};
