import React from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import MainMenu from '../MainMenu'
import UserInfo from '../UserInfo'
import Search from '../Search'
import Logo from '../Logo'
import './styles.sass'

const Header = ({ path, query, user }) => (
  <header className="header" role="region" aria-label="Шапка сайта">
    <div className="header__top">
      <div className="header__top-inner">
        <div className="header__top-side">
          <Switch>
            <Route exact path="/">
              <span className="header__logo">
                <Logo />
              </span>
            </Route>
            <Route>
              <Link className="header__logo" to="/">
                <Logo />
              </Link>
            </Route>
          </Switch>
          <MainMenu path={path} />
        </div>

        <div className="header__top-side">
          <div className="header__search">
            <Search path={path} query={query} />
          </div>

          <UserInfo user={user} />
        </div>
      </div>
    </div>
  </header>
)

export default Header
