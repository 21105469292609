const initialState = {
  items: [],
  page: 0,
};

export default function myaffiche(state = initialState, action){
  switch (action.type){

    case 'MY_AFFICHE_LIST_LOADING':
      return {
        ...state,
        items: [],
        status: 'loading',
      };

    case 'MY_AFFICHE_LIST_LOADED':
      return {
        ...state,
        ...action.payload,
        items: action.payload.items?.item.doArray() || [],
        status: 'loaded',
      };

    case 'MY_AFFICHE_PARTIAL_LOADING':
      return {
        ...state,
        status: 'partial-loading',
      };

    case 'MY_AFFICHE_PARTIAL_LOADED':
      return {
        ...state,
        ...action.payload,
        items: [...state.items, ...action.payload.items?.item.doArray() || []],
        status: 'loaded',
      };

    default:
      return state;
  }
}
