import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.scss';
import { loadComments, loadPartial } from '../../../../actions/comments';
import CommentsItem from '../Item/CommentsItem';
import CommentsForm from '../Form';
import {
  AuthConfirm,
  ButtonLoader,
} from '../../../common';

class CommentsList extends Component {

  static #defaultProps = {
    caption: 'Комментарии',
    formCaption: 'Напиши комментарий',
  }

  constructor (props) {
    super(props);

    const {
      pageId,
      loadComments: loadCommentsAction,
    } = props;

    window.addEventListener('scroll', () => {
      loadCommentsAction(pageId);
    }, { once: true });
  }

  handleMore = () => {
    const {
      pageId,
      comments: { page },
      loadPartial: loadPartialAction,
    } = this.props;

    loadPartialAction(pageId, parseInt(page, 10) + 1);
  }

  render () {

    const { caption = 'Коментарии', pageId, user, comments, loadComments: loadCommentsAction, formCaption } = this.props;
    const { items, total, status } = comments;

    return (
      <div className="comments-list">
        <h2 className="comments-list__caption">
          {caption} <span className="comments-list__caption-count">{total}</span>
        </h2>

        <AuthConfirm user={user} mod="left-side">
          <CommentsForm pageId={pageId} user={user} loadComments={loadCommentsAction} formCaption={formCaption}/>
        </AuthConfirm>

        {
          // eslint-disable-next-line no-nested-ternary
          status === 'loading'
            ? (
              <div className="comments-list__items comments-list__items-placeholder">
                {
                  [0, 1, 2, 3, 4, 5, 6, 7].map(item => (
                    <div className="comments-item" key={item}>
                      <div className="comments-item__image mod_visible"/>
                      <div className="comments-item__area">
                        <div className="comments-item__bar mod_visible"/>
                        <div className="comments-item__message mod_visible"/>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
            : (
              items.length ? <div className="comments-list__items">
                {
                  items.map((item, key) => (
                    <CommentsItem comment={item} user={user} key={key}/>
                  ))
                }
              </div> : null
            )
        }

        {
          items.length < total
            ? (
              <div className="comments-list__button-row">
                <ButtonLoader
                  isLoading={status === 'partial-loading'}
                  text="Загрузить ещё"
                  onClick={this.handleMore}
                />
              </div>
            )
            : null
        }
      </div>
    );
  }
}

const getProps = (state) => ({
  comments: state.comments,
});
const setActions = (dispatch) => bindActionCreators({
  loadComments,
  loadPartial,
}, dispatch);

export default connect(getProps, setActions)(CommentsList);
