const initialState = {
  type: 'guest',
  isGuest: true,
};

export default function user( state = initialState, action ) {
  switch ( action.type ) {
    case 'PAGE_LOADED':
      return {
        ...state,
        ...action.payload.user,
        isGuest: action.payload.user.type === 'guest',
      };
    case 'USER_LOADING':
      return { ...state, ...action.payload, loading: true };
    case 'USER_LOADED':
      return { ...state, ...action.payload, loading: false };
    case 'USER_AVATAR_LOADING':
      return { ...state, ...action.payload, avatarLoading: true };
    case 'USER_AVATAR_LOADED':
      return { ...state, ...action.payload, avatarLoading: false };
    case 'USER_FNAME_LOADING':
      return { ...state, ...action.payload, fnameLoading: true };
    case 'USER_FNAME_LOADED':
      return { ...state, ...action.payload, fnameLoading: false };
    case 'USER_RADIO_LOADING':
      return { ...state, ...action.payload, radiostationLoading: true };
    case 'USER_RADIO_LOADED':
      return { ...state, ...action.payload, radiostationLoading: false };
    case 'USER_PASS_LOADING':
      return { ...state, ...action.payload, passwordLoading: true };
    case 'USER_PASS_LOADED':
      return { ...state, ...action.payload, passwordLoading: false };
    case 'USER_LOGIN_CHANGED':
      return { ...state, login: action.payload };
    default:
      return state;
  }
}
