import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadMyAffiche as loadMyAfficheAction,
  loadMyAffichePartial as loadMyAffichePartialAction } from '../../../../../actions/user';
import './styles.scss';
import Placeholder from '../Placeholder';
import List from '../List';
import {
  ButtonLoader,
  NavBar,
} from '../../../../common'; 

class Affiche extends Component {

  constructor (props) {
    super(props);

    const { loadMyAffiche, type } = props;

    loadMyAffiche(type);
  }

  componentDidUpdate (prevProps) {
    const { loadMyAffiche, type } = this.props;

    if ( prevProps.type !== type )
      loadMyAffiche(type);
  }

  handleMore = () => {
    const { loadMyAffichePartial, type, parties: { page } } = this.props;

    loadMyAffichePartial(type, page + 1);
  }

  render () {

    const {
      device,
      parties: {
        items,
        status,
        total,
      },
    } = this.props;

    const links = [
      {
        link: '/users/me/affiche/',
        name: 'Пойду',
      },
      {
        link: '/users/me/affiche/liked/',
        name: 'Понравилось',
      },
      {
        link: '/users/me/affiche/ended/',
        name: 'Завершённые',
      },
    ];

    return (
      <div>
        <div className="my-profile-affiche__filter">
          <NavBar
            links={links}
          />
        </div>
        {
          status === 'loading'
            ? (
              <Placeholder/>
            )
            : (
              <List device={device} parties={items}/>
            )
        }

        {
          items.length && items.length < total
            ? (
              <div className="my-profile-affiche__button-row">
                <ButtonLoader
                  isLoading={status === 'partial-loading'}
                  text="Загрузить ещё"
                  onClick={this.handleMore}
                />
              </div>
            )
            : null
        }
      </div>
    );
  }
}

const getProps = (state) => ({
  parties: state.myaffiche,
});
const setActions = (dispatch) => bindActionCreators({
  loadMyAffiche: loadMyAfficheAction,
  loadMyAffichePartial: loadMyAffichePartialAction,
}, dispatch);

export default connect(getProps, setActions)(Affiche);
