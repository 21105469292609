import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { loadList } from '../../../../actions/parties';
import ListDay from '../ListDay';
import Placeholder from '../Placeholder';
import './styles.sass';
import ReactHtmlParser from "react-html-parser";

const Infographic = ({ query_string: queryString }) => (
  <div className={`parties-list__infographic ${queryString ? 'mod_date' : ''}`}>
    <div className="parties-list__infographic-caption">
      {queryString ? 'В этот день нет событий' : 'Не найдено таких событий'}
    </div>
    <div className="parties-list__infographic-desc">
      {queryString ? 'выбери другую дату' : 'измени фильтр'}
    </div>
  </div>
);

class PartiesList extends Component { 

  componentDidMount () {
    this.update();

    const { page } = this.props;

    if(!page.isCached){
      const element = document.querySelector('.page-container');

      scrollIntoView(element, {
        time: 0,
        align: {
          top: 0,
          topOffset: 0,
        },
      });
    }
  }

  componentDidUpdate (prev) {
    this.update(prev);
  }

  update = (prev) => {
    const { page, query_string: queryString, loadList: loadListAction } = this.props;

    if ( page.isCached ) {
      return;
    }

    if ( !prev || page.uri !== prev.page.uri ) {
      loadListAction(page.path, queryString);
    }
  }

  render () {

    const { page, parties } = this.props;
    const { days, status, description } = parties;
    const isLoad = status === 'loading';

    if (!isLoad) {
      return (
        <>
          <div className="parties-list">
            {
              days.length ?
                days.map(item => (
                  <ListDay
                    page={page}
                    day={item}
                    key={item.date['unix-timestamp']}
                  />
                ))
                : (
                  <Infographic query_string={page.query_string}/>
                )
            }
          </div>
          {
            description &&
            <div className="parties-list__description rich-text">
              {ReactHtmlParser(description)}
            </div>
          }
        </>
      );
    }
 
    return (
      <div className="parties-list">
        <Placeholder/>
      </div>
    );
    
  }
}

const getProps = (state) => ({ parties: state.parties });
const setActions = (dispatch) => bindActionCreators({ loadList }, dispatch);

export default connect(getProps, setActions)(PartiesList);
