import { take, put } from 'redux-saga/effects';
import { loadPage } from '../actions/page';
// import { loadYmaps } from '../actions/ymaps'

export function* pageSaga () {
  while ( true ) {
    const { payload: { location } } = yield take('CHANGE_PATH_PAGE');

    if(window.serverSidePage){
      const actionType = !window.serverSidePage.error ? 'PAGE_LOADED' : 'PAGE_LOAD_FAILURE';
      yield put({ type: actionType, payload: window.serverSidePage });
      delete window.serverSidePage;
    } else {
      const { pathname, search } = location;

      yield put(loadPage({ pathname, search }));
    }
  }
}

export function* ymapsSaga () {
  // if(document.readyState === 'complete')
  //   yield put(loadYmaps())
  // else
  //    document.onload = yield put(loadYmaps())
}
