const initialState = {
  items: [],
  page: 0,
};

export default function comments( state = initialState, action ) {
  switch ( action.type ) {
    case 'COMMENTS_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'COMMENTS_LOADED': {
      const items = action.payload.items?.item || [];
      const map = Object.keys(items).map(i => items[i]);

      return {
        ...state,
        ...action.payload,
        ...{
          items: map,
        },
        status: 'loaded',
      };
    }

    case 'COMMENTS_PARTIAL_LOADING':
      return {
        ...state,
        status: 'partial-loading',
      };
    case 'COMMENTS_PARTIAL_LOADED':
      return (() => {
        const items = action.payload.items?.item || [];
        const map = Object.keys(items).map(i => items[i]);

        return {
          ...state,
          ...action.payload,
          ...{
            items: [...state.items, ...map],
          },
          status: 'loaded',
        };
      })();
    default:
      return state;
  }
}
