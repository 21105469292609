import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import BlogsList from './BlogsListContainer';
import BlogsItem from './BlogsItemContainer';
import { PuidView } from '../../components/common/PuidView';

import './styles.sass';

const Blogs = ({ authors: { users } }) => (
  <div className="blogs">
    <div className="blogs__side">
      {users.map(item => ( 
        <div className="blogs__author" key={item.user_id}>
          {
            item.avatar
              ? (
                <div className="blogs__author-image">
                  <img src={item.avatar.src} alt={item.fname}/>
                </div>
              )
              : null
          }
          <div>
            <h3 className="blogs__author-name">{item.fname}</h3>
            <div className="blogs__author-puid">
              <PuidView puid={item.puid}/>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="blogs__area">
      <Switch>
        <Route exact path="/blogs/">
          <div>Список блогов</div>
        </Route>
        <Route exact path="/blogs/:user/" component={BlogsList}/>
        <Route exact path="/blogs/:user/:item/" component={BlogsItem}/>
      </Switch>
    </div>
  </div>
);

export default connect(
  (state) => ({
    authors: state.blogsAuthor,
  }),
)(Blogs);
