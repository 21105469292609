import { loadCommentsRequest } from '../api/comments';

export const loadComments = (pageId) => ({
  type: 'PROMISE',
  actions: ['COMMENTS_LOADING', 'COMMENTS_LOADED', 'COMMENTS_LOAD_FAILURE'],
  promise: loadCommentsRequest(pageId),
});

export const loadPartial = (pageId, page) => ({
  type: 'PROMISE',
  actions: ['COMMENTS_PARTIAL_LOADING', 'COMMENTS_PARTIAL_LOADED', 'COMMENTS_PARTIAL_LOAD_FAILURE'],
  promise: loadCommentsRequest(pageId, page),
});
