import React, { Component } from 'react';
import './styles.scss';
import FooterSpoiler from '../FooterSpoiler';
import Navigation from '../Navigation';
import { getProperties } from '../../../../containers/Page';
import PhoneNumber from '../../PhoneNumber';
import {
  IconInstagram,
  IconTelegram,
  IconWhatsApp,
} from '../../images';

const Property = ({ value, children }) => (
  value
    ? children
    : null
);

class Footer extends Component {
  constructor() {
    super();

    this.state = {
      data: {
        name: '',
        email: '',
        defacto_address: '',
        phone_number: '',
        copyright: '2019',
        vkgroup: '#',
        instagram: '#',
        whatsapp: '#',
      },
    };
  }

  componentDidMount () {
    if(window.aboutServerSide){
      this.setState({
        data: window.aboutServerSide,
      });
    } else {
      fetch('/about/.json')
        .then(response => response.json())
        .then(json => {
          this.setState({
            data: getProperties(json),
          });
        })
        .catch();
    }
  }

  render () {

    const { domain, mod } = this.props;
    const { data } = this.state;

    return (
      <footer className={`footer${  mod ? `-${  mod}` : ''}`}>
        <div className="footer-top">
          <div className="footer-top__section">
            <FooterSpoiler
              opened
              caption="Week&amp;"
            >
              <Navigation
                id="footer-main"
              />
            </FooterSpoiler>
          </div>

          <div className="footer-top__section">
            <FooterSpoiler
              caption="Помощь"
            >
              <Navigation
                id="footer-additional"
              />
            </FooterSpoiler>
          </div>

          <div className="footer-top__section">
            <FooterSpoiler
              caption="Контакты"
              aria-label="Наши контакты"
            >
              <div>
                {data.name ? <>{data.name}<br/></> : null}
                {data.defacto_address ? <>{data.defacto_address}<br/></> : null}

                E-mail: <a href={`mailto:${  data.email}`}>{data.email}</a><br/>
                {domain}
              </div>
            </FooterSpoiler>
            <div className="footer-social"
              aria-label="Наши соцсети">
              <div className="footer-social__icons">
                <Property value={data.instagram}>
                  <a className="footer-social__icon mod_in" href={data.instagram} target="_blank" rel="noreferrer noopener nofollow"
                    aria-label="Наш инстаграм">
                    <IconInstagram/>
                  </a>
                </Property>
                <Property value={data.telegram}>
                  <a className="footer-social__icon mod_vk" href={data.telegram} target="_blank" rel="noreferrer noopener nofollow"
                    aria-label="Наш телеграм">
                    <IconTelegram/>
                  </a>
                </Property>
                <Property value={data.whatsapp}>
                  <a className="footer-social__icon mod_wa" href={data.whatsapp} target="_blank" rel="noreferrer noopener nofollow"
                    aria-label="Наш ватсап">
                    <IconWhatsApp/>
                  </a>
                </Property>
              </div>
            </div>
          </div>
          <div className="footer-top__section">
            <div className="footer-top__phone"
              aria-label="Наш телефон">
              <PhoneNumber phone={data.phone_number}/>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div>
            &copy; {data.copyright}
          </div>
          <div className="footer-copyright__developer">
            <span>Сайт разработан</span>
            <a className="footer-copyright__developer-logo" href={`https://q-pixel.ru/?from=${domain}`} target="_blank" rel="noopener noreferrer">
              Разработка сайта - Quarter Pixel. Digital Agency.
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
