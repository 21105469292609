import React, { Component } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { changeLogin } from '../../../../actions/user';
import { ErrorsList } from '../../../common';
import SocialAuth from '../SocialAuth';
import AuthForm from '../AuthForm';
import ForgetForm from '../ForgetForm';
import RegisterForm from '../RegisterForm';
import './styles.scss';

class AuthContainer extends Component {

  componentDidMount () {
    const element = document.querySelector('.auth-container');

    scrollIntoView(element, {
      time: 0,
      align:{
        top: 0,
        topOffset: 80,
      },
    });
  }

  render () {

    const { page, user, changeLogin: changeLoginAction } = this.props;
    const { device, path } = page;
    const isGuest = user.type === 'guest' && path !== '/';
    const isActivate = path.indexOf('activate') >= 0;

    return (
      <div className="auth-container">

        {!isGuest && !isActivate ? <Redirect to="/"/> : null}

        {
          !device.mobile
            ? <ErrorsList page={page}/>
            : null
        }

        <div className="auth-container__inner">
          <div className="auth-container__section">

            <div className="auth-container__captions">
              <div className="auth-container__caption">
                {
                  path !== '/users/auth/'
                    ? <Link to="/users/auth/">Вход</Link>
                    : <span>Вход</span>
                }
              </div>
              <Switch>
                <Route path="/users/(auth|registrate)/">
                  <div className="auth-container__caption">
                    {
                      path !== '/users/registrate/'
                        ? <Link to="/users/registrate/">Регистрация</Link>
                        : <span>Регистрация</span>
                    }
                  </div>
                </Route>
                <Route path="/users/(forget|restore)/">
                  <div className="auth-container__caption">
                    <span>Сброс пароля</span>
                  </div>
                </Route>
                <Route path="/users/activate/">
                  <div className="auth-container__caption">
                    <span>Активация</span>
                  </div>
                </Route>
              </Switch>
            </div>

            <Switch>
              <Route path="/users/(auth|registrate)/">
                <div>
                  <SocialAuth/>
                  <div className="auth-container__or">
                    <div className="auth-container__or-text">или</div>
                  </div>
                </div>
              </Route>
            </Switch>

            <div className="auth-container__area">

              {
                device.mobile
                  ? <ErrorsList page={page}/>
                  : null
              }

              <Switch>
                <Route path="/users/auth/">
                  <AuthForm page={page} user={user} changeLogin={changeLoginAction}/>
                </Route>
                <Route path="/users/forget/">
                  <ForgetForm page={page} user={user} changeLogin={changeLoginAction}/>
                </Route>
                <Route path="/users/registrate/">
                  <RegisterForm page={page} user={user} changeLogin={changeLoginAction}/>
                </Route>
                <Route path="/users/activate/">
                  <div className="auth-container__message">
                    <h3>Аккаунт активирован</h3>
                    <div>
                      Твой аккаунт успешно активирован. Теперь ты можешь <Link to="/">использовать</Link> все возможности.
                    </div>
                  </div>
                </Route>
                <Route path="/users/restore/">
                  <div className="auth-container__message">
                    <h3>Пароль сброшен</h3>
                    <div>
                      На&nbsp;твой почтовый ящик, в&nbsp;течении нескольких минут, придет <strong>письмо</strong> с&nbsp;<strong>новым
                      паролем</strong>.
                    </div>
                  </div>
                </Route>
              </Switch>

            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionsToProps = ( dispatch ) => bindActionCreators({
  changeLogin,
}, dispatch);

export default connect(mapStateToProps, mapActionsToProps)(AuthContainer);
