import React, { FC, FocusEventHandler, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import SocialAuth from '../../users/auth/SocialAuth';
import { IAuthConfirm } from './types';

export const AuthConfirm: FC<IAuthConfirm> = (
  {
    children,
    user,
    mod,
    onClick = () => {},
  },
) => {
  const
    [popupIsVisible, setPopupIsVisible] = useState(false);
  const userIsGuest = user.type === 'guest';

  const handleFocus = () => {
    if (userIsGuest)
      setPopupIsVisible(true);
  };

  const handleBlur: FocusEventHandler<HTMLDivElement> = (event) => {
    const { currentTarget } = event;

    setTimeout(() => {
      if ( !currentTarget?.contains(document.activeElement) ) {
        setPopupIsVisible(false);
      }
    }, 0);
  };

  let modArr: string[] = [];

  if(mod){
    modArr = Array.isArray(mod) ? mod : [mod];
  }

  const modClass = modArr.map(item => `mod_${item}`).join(' ');

  const newChildren = children && userIsGuest
    ? React.cloneElement(children, { onClick })
    : children;

  return (
    <div
      className={`auth-confirm ${modClass}`}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
    >
      {newChildren}
      {
        popupIsVisible &&
        <>
          <div className="auth-confirm__body">
            Необходимо&nbsp;<Link to="/users/auth/">войти</Link>

            <SocialAuth/>
          </div>
          <span className="auth-confirm__after"/>
        </>
      }
    </div>
  );
};
