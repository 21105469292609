import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './styles.scss'; 

class PartiesDays extends Component {

  constructor (props) {
    super(props);

    const currentDate = moment();
    const startDate = moment().startOf('week');

    this.state = {
      currentDate,
      days: [...Array(7)].map((_, i) => moment(startDate).date(startDate.date() + i)),
    };
  }

  render () {

    const { page: { path, query } } = this.props;
    const { days, currentDate } = this.state;
    const link = /\/.+\/.+\/.+\//.test(path) ? '/' : path;

    return (
      <div className="parties-days">

        {
          days.map((item, i) => {

            const isSelected = query.date === item.format('DD.MM.YYYY') ? ' mod_selected' : '';
            const isCurrent = item.day() === currentDate.day() ? ' mod_current' : '';
            const isWeekend = item.day() === 0 || item.day() > 5 ? ' mod_weekend' : '';

            return (
              <Link
                to={`${link}?date=${item.format('DD.MM.YYYY')}`}
                className={`parties-days__item${  isWeekend  }${isCurrent  }${isSelected}`}
                key={i}
              >
                <div className="parties-days__item-day" data-testid={item.format('DD.MM.YYYY')}>
                  {item.format('ddd')}
                </div>
                <div className="parties-days__item-number">
                  {item.format('DD')}
                </div>
              </Link>
            );
          })
        }

      </div>
    );
  }
}

export default PartiesDays;
