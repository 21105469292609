/**
 * Reducer for parties
 *
 * @param state
 * @param action
 * @returns {*}
 */

export default function artists(state = {}, action){
  switch (action.type){

    case 'ARTISTS_LIST_LOADING': 
      return { status: 'loading' };

    case 'ARTISTS_LIST_LOADED': {
      const newState = { ...state, status: 'loaded', loaded_time: (new Date()).getTime() };

      return action.payload.items ? { ...newState, ...action.payload } : { ...newState, items: {} };
    }

    default:
      return state;
  }
}
