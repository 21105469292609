import {
  loadUserRequest,
  uploadAvatarRequest,
  changeNameRequest,
  loadMyAfficheRequest,
  loadMyAffichePartialRequest,
  changeRadioRequest,
  changePasswordRequest,
} from '../api/user';

export const loadUser = (id) => ({
  type: 'PROMISE',
  actions: ['USER_LOADING', 'USER_LOADED', 'USER_LOAD_FAILURE'],
  promise: loadUserRequest(id),
});

export const changeLogin = (login) => ({
  type: 'USER_LOGIN_CHANGED',
  payload: login,
});

export const changeRadio = (params) => ({
  type: 'PROMISE',
  actions: ['USER_RADIO_LOADING', 'USER_RADIO_LOADED', 'USER_RADIO_LOAD_FAILURE'],
  promise: changeRadioRequest(params),
});

export const changePassword = (params) => ({
  type: 'PROMISE',
  actions: ['USER_PASS_LOADING', 'USER_PASS_LOADED', 'USER_PASS_LOAD_FAILURE'],
  promise: changePasswordRequest(params),
});

export const uploadAvatar = (file, fileName) => ({
  type: 'PROMISE',
  actions: ['USER_AVATAR_LOADING', 'USER_AVATAR_LOADED', 'USER_AVATAR_LOAD_FAILURE'],
  promise: uploadAvatarRequest(file, fileName),
});

export const changeName = (fname) => ({
  type: 'PROMISE',
  actions: ['USER_FNAME_LOADING', 'USER_FNAME_LOADED', 'USER_FNAME_LOAD_FAILURE'],
  promise: changeNameRequest(fname),
});

export const loadMyAffiche = (type) => ({
  type: 'PROMISE',
  actions: ['MY_AFFICHE_LIST_LOADING', 'MY_AFFICHE_LIST_LOADED', 'MY_AFFICHE_LIST_LOAD_FAILURE'],
  promise: loadMyAfficheRequest(type),
});

export const loadMyAffichePartial = ( type, page ) => ({
  type: 'PROMISE',
  actions: ['MY_AFFICHE_PARTIAL_LOADING', 'MY_AFFICHE_PARTIAL_LOADED', 'MY_AFFICHE_PARTIAL_LOAD_FAILURE'],
  promise: loadMyAffichePartialRequest(type, page),
});
