import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../../common/Input';

import './styles.scss';

class RegisterForm extends Component {
  constructor (props) {
    super(props);

    this.state = {
      nameIsValid: false,
      emailIsValid: false,
      emailValue: '',
      passwordIsValid: false,
      confirmationIsValid: false,
    };
  }

  handleNameChange = valid => {
    this.setState({
      nameIsValid: valid,
    });
  }

  handleEmailChange = (valid, value) => {
    this.setState({
      emailIsValid: valid,
      emailValue: value,
    });
  }

  handlePasswordChange = (valid, value) => {
    this.setState({
      passwordIsValid: valid,
    }, () => {
      this.confirmPassword.value = value;
    });
  }

  handleConfirmationChange = event => {
    this.setState({
      confirmationIsValid: event.target.checked,
    });
  }

  render () {

    const { user, page: { query } } = this.props;
    const { nameIsValid, emailIsValid, emailValue, passwordIsValid, confirmationIsValid } = this.state;
    const buttonDisabled = !nameIsValid || !emailIsValid || !passwordIsValid || !confirmationIsValid ? { disabled: true } : {};

    return (
      <div className="register-form">

        {
          query.result === 'success'
            ? (
              <div className="auth-container__message">
                <h3>Регистрация успешна</h3>
                На&nbsp;твой почтовый ящик, в&nbsp;течении нескольких минут, придет <strong>письмо</strong> для&nbsp;<strong>подтверждения</strong> регистрации.
              </div>
            )
            : (
              <form action="/users/registrate_do/" method="post">
                <div className="register-form__row">
                  <Input
                    className="register-form__input"
                    placeholder="Имя"
                    type="text"
                    name="data[new][fname]"
                    validate
                    required
                    minlength={3}
                    maxlength={11}
                    customValidate={value => /^[\wа-яёА-ЯЁ]+$/.test(value)}
                    onChange={this.handleNameChange}
                  />
                </div>
                <div className="register-form__row">
                  <Input
                    className="register-form__input"
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    value={user.login}
                    validate
                    required
                    email
                    onChange={this.handleEmailChange}
                  />
                  <input type="hidden" name="login" value={emailValue}/>
                </div>
                <div className="register-form__row">
                  <Input
                    className="register-form__input"
                    placeholder="Пароль"
                    type="password"
                    name="password"
                    validate
                    required
                    minlength={8}
                    onChange={this.handlePasswordChange}
                    autoComplete="new-password"
                  />
                  <div className="register-form__hidden">
                    <input ref={element => { this.confirmPassword = element; }} type="password" name="password_confirm"/>
                  </div>
                </div>
                <div className="register-form__confirm-row">
                  <div className="register-form__confirm-checkbox">
                    <input
                      id="confirm"
                      type="checkbox"
                      onClick={this.handleConfirmationChange}
                    />
                    <div className="register-form__confirm-checkbox-flag"/>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="confirm">
                    Я согласен с условиями <Link to="/privacy-policy/">Политики конфиденциальности</Link>
                  </label>
                </div>
                <div className="register-form__row mod_button">
                  <div className="register-form__button">
                    <input type="submit" value="Создать аккаунт" {...buttonDisabled}/>
                  </div>
                </div>
              </form>
            )
        }

      </div>
    );
  }
}

export default RegisterForm;
