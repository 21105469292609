import { fork, all, take, put, select } from 'redux-saga/effects';
import { routesSaga } from './routesSaga';
import {
  pageSaga,
  ymapsSaga,
} from './page';
import {
  partiesSaga,
  partiesItemSaga,
} from './parties';
import {
  placesSaga,
  placesListSaga,
  placeSaga,
} from './places';
import {
  blogSaga,
  blogTypesSaga,
} from './blog';
import {
  blogsSaga,
  blogsListSaga,
  blogsItemSaga,
} from './blogs';
import {
  userSaga,
} from './user';
import { loadMenu } from '../actions/page';
import { loadListForMap } from '../actions/places';

const routes = {
  '/|/affiche/:type?/:item?': function* () {
    yield fork(partiesSaga);
  },
  '/affiche/:type/:item': function* ({ path }) {
    yield fork(partiesItemSaga, { path });
  },
  '/place/:type?/:item?': function* () {
    yield fork(placesSaga);
  },
  '/place/:type?': function* ({ path }) {
    yield fork(placesListSaga, { path });
  },
  '/place/:type/:item': function* ({ path }) {
    yield fork(placeSaga, { path });
  },

  '/blog/:type?/:post?': function* ({ path }) {
    yield fork(blogTypesSaga);
  },
  '/blog/:type?': function* ({ path }) {
    yield fork(blogSaga, { path });
  },
  '/blog/:type/:post': function* ({ path }) {
    yield fork(blogsItemSaga, { path });
  },

  '/blogs/:type/:post?': function* ({ path }) {
    yield fork(blogsSaga, { path });
  },
  '/blogs/:type': function* ({ path }) {
    yield fork(blogsListSaga, { path });
  },
  '/blogs/:type/:post': function* ({ path }) {
    yield fork(blogsItemSaga, { path });
  },
  '/map/:id?': function* () { // '/|/affiche/:type?|/map/:id?'
    const { items: { length } } = yield select(state => state.mapPlaces);

    if ( !length )
      yield put(loadListForMap());
  },
};

const createRoutesSaga = ({ history/* , dispatch */ }) => (
  function* routesWatcherSaga () {
    while ( true ) {
      yield take('CHANGE_PATH_PAGE');

      const path = history.location.pathname;

      yield fork(routesSaga, { path, routes });
    }
  }
);

function* mainMenuSaga ({ dispatch }) {
  if(window.mainNavigationServerSide){
    dispatch({ type: 'MENU_LOADED', payload: window.mainNavigationServerSide });
  } else {
    yield put(loadMenu());
  }
}

export default function* rootSaga (history, dispatch) {
  yield all([
    fork(ymapsSaga),
    fork(mainMenuSaga, { dispatch }),
    fork(pageSaga),
    fork(userSaga),
    fork(createRoutesSaga({ history, dispatch })),
  ]);
}
