import React from 'react';
import { Link } from 'react-router-dom';
import { parseWithoutLinks } from '../../../../helpers';
import './styles.sass';
import { ButtonLoader } from "../../../common";

export const BlogsList = ({ items, status, page, maxPage, loadPartial, path }) => {
  const newPage = page + 1;
  const isMoreExists = newPage <= maxPage;

  const moreClickHandler = () => loadPartial({ path, page: newPage })

  if(page > maxPage){
    return (
      <div className="blogs-list">
        <h1>Страница не найдена</h1>
      </div>
    )
  }

  return (
    <div className="blogs-list">
      <h1>
        {items.length > 0 && items[0].blog_name}
      </h1>
      <div className="blogs-list__items">
        {
          items.map(item => (
            <div className="blogs-list__item" key={item.id}>
              <h2 className="blogs-list__item-caption">
                <Link to={item.post_link}>
                  {item.name}
                </Link>
              </h2>
              <div className="blogs-list__item-text rich-text">
                {parseWithoutLinks(item.cut)}
              </div>
            </div>
          ))
        }
      </div>
      {
        isMoreExists &&
        <div className="blogs-list__more-area">
          <span className="button">
            <ButtonLoader
              onClick={moreClickHandler}
              isLoading={status === 'partial-loading'}
              text="Показать еще"
            />
          </span>
        </div>
      }
    </div>
  );
}

export const BlogsListPlaceholder = () => (
  <div className="content-section-preloader">
    <div className="content-section-preloader__title mod_visible"/>
    <div className="content-section__area">
      <div className="content-section-preloader__image-area">
        <div className="content-section-preloader__image mod_visible"/>
        <div>
          {[...(new Array(5))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
        </div>
      </div>
      {[...(new Array(15))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
    </div>
  </div>
);
