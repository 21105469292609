import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../../common/Input';

import './styles.scss';

class AuthForm extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loginIsValid: false,
      passwordIsValid: false,
    };
  }

  handleLoginChange = (valid, value) => {
    const { changeLogin } = this.props;
 
    this.setState({
      loginIsValid: valid,
    });
    if(valid)
      changeLogin(value);
  }

  handlePasswordChange = valid => {
    this.setState({
      passwordIsValid: valid,
    });
  }

  render () {

    const { user } = this.props;
    const { loginIsValid, passwordIsValid } = this.state;
    const buttonDisabled = !loginIsValid || !passwordIsValid ? { disabled: true } : {};

    return (
      <div className="auth-form">

        <form action="/users/login_do/">
          <div className="auth-form__row">
            <Input
              className="auth-form__input"
              placeholder="E-mail"
              type="text"
              name="login"
              value={user.login}
              validate
              required={{ message: 'Введите логин.' }}
              email
              onChange={this.handleLoginChange}
            />
          </div>
          <div className="auth-form__row">
            <Input
              className="auth-form__input"
              placeholder="Пароль"
              type="password"
              name="password"
              validate
              required
              minlength={8}
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className="auth-form__row">
            <div className="auth-form__button">
              <input type="submit" value="Войти" {...buttonDisabled}/>
            </div>
          </div>
          <div className="auth-form__links">
            <Link to="/users/forget/">
              Забыли пароль?
            </Link>
            <Link to="/users/registrate/">
              Зарегистрироваться
            </Link>
          </div>
        </form>

      </div>
    );
  }
}

export default AuthForm;
