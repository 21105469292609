import React, { useContext } from 'react';
import { PageContext } from '../../../contexts';
import { Picture } from '..';
import './styles.sass';

const SquarePicture = ({ image, alt = '', mobileSimple = false }) => {
  const { device } = useContext(PageContext);

  if(!image)
    return null;

  const isSimple = device.mobile && mobileSimple;
  const className = isSimple ? 'mod_simple' : '';
  const decors = !isSimple
    ? (
      <>
        <Picture className="square-image__back mod_left" image={image} alt={alt}/>
        <Picture className="square-image__back mod_right" image={image} alt={alt}/>
      </>
    ) : null; 

  return (
    <div className={`square-image ${className}`}>
      <div className="square-image__container">
        {decors}
        <Picture className="square-image__picture" image={image} alt={alt}/>
      </div>
    </div>
  );
};

export default SquarePicture;
