import {
  loadPageRequest,
  loadMenuRequest,
} from '../api/page';

export const loadPage = ({ pathname, search = '' }) => ({
  type: 'PROMISE',
  actions: ['PAGE_LOADING', 'PAGE_LOADED', 'PAGE_LOAD_FAILURE'],
  promise: loadPageRequest({ pathname, search }),
});

export const loadMenu = () => ({
  type: 'PROMISE',
  actions: ['MENU_LOADING', 'MENU_LOADED', 'MENU_LOAD_FAILURE'],
  promise: loadMenuRequest(),
});
