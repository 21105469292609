import React from 'react';
import { connect } from 'react-redux';
import { BlogsItemPlaceholder } from "../../components/blogs/item/Item";
import { BlogsSide } from "../../components/blogs/side/Side/BlogsSide";


const BlogSide = ({ blogTypes: { status, items } }) => {

  if ( status === 'loading' ){
    return <BlogsItemPlaceholder/>;
  }

  return (
    <BlogsSide items={items} />
  );
};

export default connect(
  ({ blogTypes }) => ({ blogTypes }),
)(BlogSide);
