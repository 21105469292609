import React, { Component } from 'react';
import './styles.scss';
import { loadPlacesRequest } from '../../../../../api/user';
import { List, Placeholder } from './List';

class Places extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      places: [],
    };
  }

  componentDidMount () { 
    this.load();
  }

  load = () => {
    this.setState({
      loading: true,
    });

    loadPlacesRequest()
      .then(json => {
        this.setState({
          places: json.items?.item.doArray() || [],
          loading: false,
        });
      });
  }

  render () {

    const { places, loading } = this.state;

    return (
      <div className="my-places-list">
        {
          loading
            ? <Placeholder/>
            : <List places={places} load={this.load}/>
        }
      </div>
    );
  }
}

export default Places;
