import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import PageContext from '../../../../contexts/page'

const FooterNavigation = ({ id }) => {
  const [links, setLinks] = useState([])
  const { page: { path } } = useContext(PageContext)

  useEffect(() => {
    if(window.menuServerSide && window.menuServerSide[id]){
      setLinks(window.menuServerSide[id])
    }
    else {
      fetch(`/udata/menu/draw/${id}/.json`, { credentials: "same-origin" })
        .then(r => r.json())
        .then(json => {
          setLinks(json.item.doArray())
        })
        .catch()
    }
  }, [id,  setLinks])

  return (
    <ul className="footer-nav">
      {
        links.map(item => (
          <li key={item.link}>
            {
              path === item.link
                ? (
                  <span>
                    {item.name}
                  </span>
                )
                : (
                  <Link to={item.link}>
                    {item.name}
                  </Link>
                )
            }
          </li>
        ))
      }
    </ul>
  )
}

export default FooterNavigation
