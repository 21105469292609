import React, { useEffect, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { PageContext } from '../../contexts';
import { setSubscribers } from '../../actions/places';
import {
  Place,
  Placeholder,
} from '../../components/places/item/Place';

const PlaceContainer = ({ placeItem: { place, status }, setSubscribers: setSubscribersAction }) => {

  const { page, user } = useContext(PageContext);

  useEffect(() => {
    if ( !page.isCached ) {
      const element = document.querySelector('.page-container');

      scrollIntoView(element, { 
        time: 0,
        align: {
          top: 0,
        },
      });
    }
  }, [page.isCached]);

  if ( status === 'loaded' )
    return (
      <Place
        {...place}
        user={user}
        domain={page.domain}
        device={page.device}
        setSubscribers={setSubscribersAction}
      />
    );
  return <Placeholder/>;
};

export default connect(
  (state) => ({
    placeItem: state.placeItem,
  }),
  ( dispatch ) =>  bindActionCreators({ setSubscribers }, dispatch),
)(PlaceContainer);
