import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Breadcrumbs } from '../../common';
import PartiesMenu from '../Menu';
import PartiesFilter from '../Filter';
// import { IconGeo } from '../../common/images'
import './styles.sass';

/* const MapLink = ({ device }) => (
  !device.mobile
    ? (
      <a href="#map" className="parties-top__map-link">
        <span className="parties-top__map-link-icon">
          <IconGeo/>
        </span>
        <span className="parties-top__map-link-caption">
          На карте
        </span>
      </a> 
    )
    : null
) */

const PartiesNavBar = ({ device, uri, parents, query }) => (
  <div className="parties-top__nav-bar">
    <div className="parties-top__nav">
      <PartiesMenu/>
    </div>
    <PartiesFilter query={query} uri={uri}/>
    {/* <Switch>
      <Route exact path="/affiche/:type/:item/">
        <></>
      </Route>
      <Route>
        <MapLink device={device}/>
      </Route>
    </Switch> */}
    {
      !device.mobile
        ? (
          <Switch>
            <Route exact path="/affiche/:type/:item/">
              <Breadcrumbs parents={parents}/>
            </Route>
          </Switch>
        )
        : null
    }
  </div>
);

export default PartiesNavBar;
