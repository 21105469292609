import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

export const loadScript = (url) => (
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = url;
    document.head.append(script);
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Ошибка загрузки скрипта ${url}`)); 
  })
);

const replaceLinks = (str, call) => {
  if(!str){
    return null;
  }

  const parsed = ReactHtmlParser(str);

  const replaceLinksRun = (nodes) => nodes.map(item => {
    if(item.type === 'a'){
      return call(item);
    }

    const children = item.props?.children;

    if(children){
      return {
        ...item,
        props: {
          ...item.props,
          children: replaceLinksRun(children),
        },
      };
    }

    return item;
  });

  return replaceLinksRun(parsed);
};

export const parseWithLinks = (str) => replaceLinks(str, item => {
  const { props, key } = item;
  const originWithoutPort = location.origin.replace(`:${location.port}`, '');
  const href = props?.href.replace(originWithoutPort, '');
  const isTarget = props?.target;
  const isExternal = /^http/.test(href);

  if(!isExternal && !isTarget && href) {
    return <Link className="react-link" to={href} key={key} {...props}/>;
  }

  if(isExternal){
    return {
      ...item,
      props: {
        ...item.props,
        rel: 'nofollow norefferer noopener',
      },
    };
  }

  return item;
});

export const parseWithoutLinks = (str) => replaceLinks(str, () => null);
