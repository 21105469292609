import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#a5b8fd',
    },
    secondary: {
      main: '#889396',
    },
    info: {
      main: '#ebebeb',
    },
    error: {
      main: 'rgba(255,0,0)',
    },
  },
  typography: {
    "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
  }
  /*components: {
    // Name of the component
    MuiInput: {
      /!*defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },*!/
      styleOverrides: {
        root: {
          color: '#e2e8fe',
          '& ::before': {
            color: '#e2e8fe',
          },
        },
        input: {
          color: '#e2e8fe',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Some CSS
          color: '#e2e8fe',
        },
      }
    },
  },*/
});
