export const PuidView = ({ puid }) => {
  if(!puid) {
    return null;
  }

  const
    number = parseInt(puid, 10);
  const isNumeric = number.toString().length === puid.toString().length;
  const prefix = isNumeric ? 'user-' : '';

  return `@${prefix}${puid}`;
};
