import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from 'react';
import { loadScript } from '../../../helpers';
import { PageContext } from '../../../contexts';
import './styles.sass';

const Map = ({ center = [45.035566, 38.974711], zoom = 12, items = [], showMe = true, isLazy }) => {
  const mapContainer = useRef();
  const { device } = useContext(PageContext);
  const [canBeInited, setCanBeInited] = useState(!isLazy); 
  const [apiRequested, setApiRequested] = useState();
  const [apiIsReady, setApiIsReady] = useState();
  const [map, setMap] = useState();

  useEffect(() => {
    if(isLazy){
      window.addEventListener('scroll', () => setCanBeInited(1), { once: true });
    }
  }, [isLazy]);

  useEffect(() => {
    if(canBeInited) {
      if(!window.ymaps && !apiRequested){
        setApiRequested(1);
        loadScript('//api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=a71bf1b0-c9c0-4cea-995f-353949191fa7')
          .then(() => {
            window.ymaps.ready(() => setApiIsReady(1));
          });
      }

      if(window.ymaps){
        window.ymaps.ready(() => setApiIsReady(1));
      }
    }
  }, [canBeInited, apiRequested]);

  const createMap = useCallback(() => {
    const { ymaps } = window;

    const mapObject = new ymaps.Map(mapContainer.current, {
      center,
      zoom,
    }, {
      yandexMapDisablePoiInteractivity: true,
    });

    mapContainer.current.parentNode.classList.add('initialized');

    if (showMe && ( device.mobile || device.tablet ))
      ymaps.geolocation.get().then((result) => {
        mapObject.geoObjects.add(result.geoObjects);

        const userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();

        mapObject.setCenter(userCoodinates, 16);
      }, (err)=> {
        console.log(err);
      });

    return mapObject;
  }, [device, center, showMe, zoom]);

  useLayoutEffect(() => {
    if(apiIsReady && !map){
      setMap(createMap());
    }
  }, [apiIsReady, map, createMap]);

  const placePoints = useCallback(() => {
    const { ymaps } = window;

    map.geoObjects.removeAll();

    items.forEach(item => {
      if ( !item.coords ) {
        return;
      }

      const active = item.isActive ? '-active' : '';
      const placemark = new ymaps.Placemark(item.coords.split(','), {
        hintContent: item.name,
        balloonContent: `<h4>${item.name}</h4><div>${item.address}</div>`,
      }, {
        preset: active ? 'islands#blueCircleDotIconWithCaption' : 'islands#greyCircleDotIconWithCaption',
      });

      map.geoObjects.add(placemark);
    });

    return items;
  }, [items, map]);

  useEffect(() => {
    if(map && items.length){
      placePoints();
    }
  }, [map, items, placePoints]);

  return (
    <div className="map-container__outer">
      <div
        className="map-container"
        ref={mapContainer}
      />
    </div>
  );
};

export default Map;
