import {
  loadListRequest,
  loadPartialRequest,
  loadTypesRequest,
  loadPlaceRequest,
  getIsSubscriberRequest,
  setSubscribersRequest,
  loadListForMapRequest,
} from '../api/places';

export const loadList = ({ path }) => ({
  type: 'PROMISE',
  actions: ['PLACES_LIST_LOADING', 'PLACES_LIST_LOADED', 'PLACES_LIST_LOAD_FAILURE'],
  promise: loadListRequest({ path }),
});

export const loadPartial = ({ path, pageNum }) => ({
  type: 'PROMISE',
  actions: ['PLACES_PARTIAL_LOADING', 'PLACES_PARTIAL_LOADED', 'PLACES_PARTIAL_LOAD_FAILURE'],
  promise: loadPartialRequest({ path, pageNum }),
});

export const loadTypes = () => ({
  type: 'PROMISE',
  actions: ['PLACES_TYPES_LOADING', 'PLACES_TYPES_LOADED', 'PLACES_TYPES_FAILURE'],
  promise: loadTypesRequest(),
});

export const loadPlace = ({ path }) => ({
  type: 'PROMISE',
  actions: ['PLACE_ITEM_LOADING', 'PLACE_ITEM_LOADED', 'PLACE_ITEM_LOAD_FAILURE'],
  promise: loadPlaceRequest({ path }),
});

export const getIsSubscriber = ({ path }) => ({
  type: 'PROMISE',
  actions: ['PLACE_USER_SUBSCRIBER_LOADING', 'PLACE_USER_SUBSCRIBER_LOADED', 'PLACE_USER_SUBSCRIBER_LOAD_FAILURE'],
  promise: getIsSubscriberRequest({ path }),
});

export const setSubscribers = ({ id, type }) => ({
  type: 'PROMISE',
  actions: ['PLACE_SUBSCRIBER_LOADING', 'PLACE_SUBSCRIBER_LOADED', 'PLACE_SUBSCRIBER_LOAD_FAILURE'],
  promise: setSubscribersRequest({ id, type }),
});

export const loadListForMap = () => ({
  type: 'PROMISE',
  actions: ['MAP_PLACES_LIST_LOADING', 'MAP_PLACES_LIST_LOADED', 'MAP_PLACES_LIST_LOAD_FAILURE'],
  promise: loadListForMapRequest(),
});

export const saveCoords = (id, coords) => ({
  type: 'PROMISE',
  actions: ['MAP_PLACES_SAVE_LOADING', 'MAP_PLACES_SAVE_LOADED', 'MAP_PLACES_SAVE_LOAD_FAILURE'],
  promise: fetch(`/udata/places/saveCoords/${id}/${coords.join(',')}/.json`, { credentials: 'same-origin' }).then((r) => r.json()),
});
