import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import './styles.sass';
import {
  prepareContent,
} from '../../../../containers/Page';
import {
  Picture,
  SquarePicture,
  PhoneNumber,
  PlaceName,
} from '../../../common'; 
import {
  IconPlace,
  IconPhone,
} from '../../../common/images';

class PromoList extends Component {

  componentDidMount () {
    this.update();
  }

  componentDidUpdate (prev) {
    this.update(prev);
  }

  update = (prev) => {
    const { page, loadList } = this.props;

    if ( !prev ) {
      if ( page.isCached )
        return false;

      loadList(page.path);
    } else if ( page.path !== prev.page.path ) {
      loadList(page.path);
    }

    return null;
  }

  render () {

    const { promo: { items } } = this.props;

    return (
      <div className="promo-list">
        <h2>Промо и акции</h2>
        {
          items.map(item => {
            const image = item.image || item.affiche?.image;
            const caption = item.name.replace('%partyName%', item.affiche?.name || '');
            const startDate = /* item.affiche?.dateStart['unix-timestamp'] || */ item.dateStart?.['unix-timestamp'];
            const endDate = /* item.affiche?.dateEnd['unix-timestamp'] || */ item.dateEnd?.['unix-timestamp'];
            const momentStart = moment(startDate * 1000);
            const momentEnd = moment(endDate * 1000);
            const anons = prepareContent({ content: item.anons });

            return (
              <div className="promo-list__item" key={item.id}>
                <div className="promo-list__side">
                  {
                    image
                      ? (
                        <div className="promo-list__image">
                          <SquarePicture
                            image={image}
                            alt={caption}
                            mobileSimple
                          />
                        </div>
                      )
                      : null
                  }
                  <div className="promo-list__body">
                    {
                      startDate || endDate
                        ? (
                          <div className="promo-list__date">
                            {
                              startDate ?
                                <>c&nbsp;{momentStart.format('DD.MM')}</>
                                : null
                            }
                            {
                              endDate ?
                                <>{startDate ? ' по' : 'до'}&nbsp;{momentEnd.format('DD.MM')}</>
                                : null
                            }
                          </div>
                        )
                        : null
                    }
                    <div className="promo-list__caption">
                      {/* <Link to={item.link}> */}
                      {caption}
                      {/* </Link> */}
                    </div>
                    <div className="promo-list__text">
                      {ReactHtmlParser(anons)}
                    </div>
                  </div>
                </div>
                {
                  item.place
                    ? (
                      <div className="promo-list__place">
                        <Link to={item.place.link} className="promo-list__place-image">
                          <Picture image={item.place.image} alt=""/>
                        </Link>
                        <div>
                          <div className="promo-list__place-property mod_caption">
                            <div className="promo-list__place-icon">
                              <IconPlace/>
                            </div>
                            <Link to={item.place.link}>
                              <PlaceName name={item.place.name}/>
                            </Link>
                          </div>
                          <div className="promo-list__place-property mod_type">
                            {item.place.type}
                          </div>
                          <div className="promo-list__place-property mod_phone">
                            <div className="promo-list__place-icon">
                              <IconPhone/>
                            </div>
                            <PhoneNumber phone={item.place.phone}/>
                          </div>
                        </div>
                      </div>
                    )
                    : null
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default PromoList;
