import React, { PureComponent } from 'react';
import moment from 'moment';
import ListItem from '../ListItem';

import './styles.scss';

class PartiesListDay extends PureComponent {

  componentDidMount () {
    this.calculateTop();
    document.addEventListener('scroll', this.calculateTop);
    this.windowOnResize();
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.calculateTop);
    window.removeEventListener('resize', this.resize); 
  }

  windowOnResize = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.calculateTop, 10);
  }

  calculateTop = () => {
    const { page } = this.props;
    const isMobile = page.device.mobile;
    const { datePanel, innerElement, dateElement } = this;
    const rect = datePanel.getBoundingClientRect();
    const fixedTop = isMobile ? 74 : 100;

    if ( rect.top < fixedTop ) {
      datePanel.classList.add('mod_fixed');

      const dateHeight = dateElement.clientHeight + 100;
      const max = rect.height - dateHeight;
      const marginTop = rect.top <= 0 ? rect.top * -1 : 0;
      const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      const fixedBottom = (rect.top + rect.height - viewportHeight) * -1;
      const fixedLeft = rect.left;

      const disabled = max - marginTop < 0;

      if(!isMobile) {
        innerElement.style.bottom = disabled ? 0 : `${fixedBottom.toFixed(0)  }px`;
        innerElement.style.left = `${fixedLeft  }px`;
        innerElement.style.opacity = Math.max(0, Math.min(max - marginTop, 300) / 3 / 100).toFixed(2);
        innerElement.style.visibility = disabled ? 'hidden' : 'visible';
      }
    } else {
      datePanel.classList.remove('mod_fixed');

      innerElement.style.opacity = 1;
      innerElement.style.visibility = 'visible';
    }
  }

  render () {

    const { page, day } = this.props;
    const parties = day.items.item?.doArray() || [];
    const date = moment(day.date['unix-timestamp'] * 1000);
    const isWeekend = date.day() > 5 || date.day() === 0;

    return (
      <div className="parties-list-day">
        <div
          className={
            `parties-list-day__date${ 
              isWeekend ? ' mod_weekend' : ''}`
          }
          ref={element => {this.datePanel = element;}}
        >
          <div
            className="parties-list-day__date-inner"
            ref={element => {this.innerElement = element;}}
          >
            <div
              className="parties-list-day__date-value"
              ref={element => {this.dateElement = element;}}
            >
              <div className="parties-list-day__date-digits">
                {date.format('DD')}
              </div>
              <div className="parties-list-day__date-word">
                {
                  !page.device.mobile
                    ? date.format('ddd')
                    : date.format('DD MMMM, dddd').substring(3)
                }
              </div>
            </div>
            <div className="parties-list-day__date-decor"/>
          </div>
        </div>

        <div className="parties-list-day__items">
          {
            parties.map(item => (
              <ListItem
                page={page}
                party={item}
                key={item.id}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

export default PartiesListDay;
