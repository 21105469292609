import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Days from '../../components/parties/Days';
import Top from '../../components/common/Top';
import NavBar from '../../components/parties/NavBar';
import { PartiesListContainer } from '../../components/parties/list/container';
import PartyContainer from './PartyContainer';

import './styles.sass';

const Parties = ({ page }) => {
  const { device, uri, parents, query } = page;

  return (
    <>
      <Top/>

      <div className="parties-top">
        <Days page={page}/> 

        <NavBar device={device} uri={uri} parents={parents} query={query}/>
      </div>

      <Switch>
        <Route exact path="/">
          <PartiesListContainer page={page}/>
        </Route>
        <Route exact path="/affiche/:type/">
          <PartiesListContainer page={page}/>
        </Route>
        <Route exact path="/affiche/:type/:item/">
          <PartyContainer/>
        </Route>
      </Switch>
    </>
  );
};

export default Parties;
