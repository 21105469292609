import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const Filter = props => {

  const { query, uri } = props; 
  const parameters = Object.keys(query);

  return parameters.length ? (
    <div className="parties-filter">
      {
        parameters.map(key => {

          if ( key !== 'date' )
            return '';

          const decodedUri = decodeURI(uri);
          const value = query[key];
          const string = `${key  }=${  value}`;
          const link = decodedUri.replace(string, '');

          return (
            <Link to={link} className="parties-filter__item" key={key}>
              {value}
            </Link>
          );
        })
      }
    </div>
  )
    : null;
};

export default Filter;
