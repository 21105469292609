import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const MainMenu = ({ path, menu: { items } }) => (
  <div className="main-menu">
    {
      items.map(item => {
        const isCurrentPageLink = path === item.link;
        const Node = isCurrentPageLink ? 'span' : NavLink;
        const settings = isCurrentPageLink 
          ? {
            className: 'main-menu__item mod_active',
          } 
          : {
            exact: item.link === '/' && path.indexOf('affiche') !== 1,
            to: item.link,
            className: 'main-menu__item',
            activeClassName: 'mod_active', 
          };

        return (
          <Node
            key={item.id}
            {...settings}
          >
            {item.name}
          </Node>
        );
      },
      ) 
    }
  </div>
);

export default connect(
  state => ({
    menu: state.mainMenu,
  }),
)(MainMenu);
