import React, { Component } from 'react';
import Input from '../../../common/Input';

import './styles.scss';

class ForgetForm extends Component {
  constructor ( props ) {
    super(props);

    this.state = {
      loginIsValid: false,
    };
  }

  handleLoginChange = valid => {
    this.setState({
      loginIsValid: valid,
    });
  }

  render () {
    const { user, page: { query } } = this.props;
    const { loginIsValid } = this.state;
    const buttonDisabled = !loginIsValid ? { disabled: true } : {};

    return (
      <div className="forget-form">

        {
          query.result === 'success'
            ? (
              <div className="auth-container__message">
                <h3>Письмо отправлено</h3>
                На&nbsp;твой почтовый ящик, в&nbsp;течении нескольких минут, придет <strong>письмо</strong> для&nbsp;<strong>сброса</strong> пароля.
              </div>
            )
            : (
              <form action="/users/forget_do/">
                <div className="forget-form__row">
                  <Input
                    className="forget-form__input"
                    placeholder="E-mail"
                    type="text"
                    name="forget_email"
                    value={user.login}
                    validate
                    required={{ message: 'Введите логин.' }}
                    email
                    onChange={this.handleLoginChange}
                  />
                </div>
                <div className="forget-form__row">
                  <div className="forget-form__button">
                    <input type="submit" value="Сбросить" {...buttonDisabled}/>
                  </div>
                </div>
              </form>
            )
        }

      </div>
    );
  }
}

export default ForgetForm;
