import React, { PureComponent } from 'react'

import './styles.scss'

class Placeholder extends PureComponent {

  render () {

    return (
      <div className="party-item">
        <div className="party-item__placeholder">
          <div className="party-item__title">
            <div className="party-item__date">
              <div className="party-item__date-digits mod_visible"/>
              <div className="party-item__date-word">
                <div className="party-item__date-word-value mod_visible"/>
              </div>
            </div>
            <div className="party-item__title-name">
              <div className="mod_visible"/>
            </div>
          </div>
          <div className="party-item__area">
            <div className="party-item__left-side">
              <div className="party-item__image mod_visible"/>
              <div className="party-item__controls">
                <div className="party-item__control">
                  <i className="party-item__control-icon mod_visible"/>
                </div>
                <div className="party-item__control">
                  <i className="party-item__control-icon mod_visible"/>
                </div>
                <div className="party-item__control">
                  <i className="party-item__control-icon mod_visible"/>
                </div>
                <div className="party-item__will-go mod_visible"/>
              </div>
            </div>
            <div className="party-item__enter">
              <div className="party-item__min-age mod_visible"/>
              <div className="party-item__fc-dc mod_visible"/>
            </div>
            <div className="party-item__properties">
              <div className="party-item__property">
                <div className="party-item__property-caption mod_visible"/>
                <div className="party-item__property-value mod_visible"/>
              </div>
              <div className="party-item__property">
                <div className="party-item__property-caption mod_visible"/>
                <div className="party-item__property-value mod_visible"/>
              </div>
              <div className="party-item__property">
                <div className="party-item__property-caption mod_visible"/>
                <div className="party-item__property-value mod_visible"/>
              </div>
              <div className="party-item__artists">
                <div className="party-item__property party-item__property_artists">
                  <div className="party-item__property-caption mod_visible"/>
                  <div className="party-item__property-value">
                    <div className="party-item__artists-list-item mod_visible"/>
                    <div className="party-item__artists-list-item mod_visible"/>
                  </div>
                </div>
                <div className="party-item__property party-item__property_artists">
                  <div className="party-item__property-caption mod_visible"/>
                  <div className="party-item__property-value">
                    <div className="party-item__artists-list-item mod_visible"/>
                    <div className="party-item__artists-list-item mod_visible"/>
                  </div>
                </div>
                <div className="party-item__property party-item__property_artists">
                  <div className="party-item__property-caption mod_visible"/>
                  <div className="party-item__property-value">
                    <div className="party-item__artists-list-item mod_visible"/>
                    <div className="party-item__artists-list-item mod_visible"/>
                  </div>
                </div>
              </div>
              <div className="party-item__property party-item__property_strong">
                <div className="party-item__property-caption mod_visible"/>
                <div className="party-item__property-value party-item__property-value_phone mod_visible"/>
              </div>
              <div className="party-item__property party-item__property_strong">
                <div className="party-item__property-caption mod_visible"/>
                <div className="party-item__property-value mod_visible"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Placeholder