import React  from 'react';

import './styles.scss';

const Placeholder = () => (
  <div className="my-parties-list__placeholder">
    {
      [0,1,2,3,4,5].map((i) => (
        <div className="parties-list-item" key={i}>
          <div className="parties-list-item__image mod_visible"/>
          <div className="parties-list-item__title mod_visible"/>
          <div className="parties-list-item__property mod_visible"/>
          <div className="parties-list-item__property mod_visible"/>
          <div className="parties-list-item__property mod_visible"/>
        </div>
      ))
    }
  </div>
);

export default Placeholder;
