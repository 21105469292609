import React, { Component } from 'react';
import ReactSwipeEvents from 'react-swipe-events';
import { Link } from 'react-router-dom';
import ProfileBar from '../../users/myprofile/ProfileBar';
import { ReactComponent as NoAvatar } from '../images/no-avatar.icon.svg';
import MainMenuMobile from '../MainMenuMobile';
import Footer from '../footer/Footer';
import { Logo } from '..';
import './styles.scss';

class HeaderMobile extends Component {
  constructor() {
    super();

    this.state = {
      isTop: false,
      leftSideIsHidden: true,
      rightSideIsHidden: true,
    };
  }

  componentDidMount () {
    document.addEventListener('scroll', this.setTopPosition);
    this.setTopPosition();
  }

  componentDidUpdate (prev) {
    const { path } = this.props;
    const { is_hidden: isHidden } = this.state;

    this.setTopPosition();

    if ( !isHidden && path !== prev.path ) {
      document.body.classList.remove('mod_fixed');
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        leftSideIsHidden: true,
        rightSideIsHidden: true,
      });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.setTopPosition);
    document.body.classList.remove('mod_fixed');
  }

  setTopPosition = () => {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const { path, module } = this.props;
    const isParties = (path === '/' || /affiche/.test(path)) && module === 'parties';
    const isTop = !isParties || scroll >= 60;

    const { isTop: isTopState } = this.state;

    if ( isTopState === isTop )
      return;

    this.setState({
      isTop: isTop || scroll >= 60,
      isParties,
    });
  }

  handleOpenLeft = () => {
    document.body.classList.add('mod_fixed');
    this.setState({
      leftSideIsHidden: false,
    });
  }

  handleCloseLeft = () => {
    document.body.classList.remove('mod_fixed');
    this.setState({
      leftSideIsHidden: true,
    });
  }

  handleOpenRight = () => {
    document.body.classList.add('mod_fixed');
    this.setState({
      rightSideIsHidden: false,
    });
  }

  handleCloseRight = () => {
    document.body.classList.remove('mod_fixed');
    this.setState({
      rightSideIsHidden: true,
    });
  }

  handleSwipedLeft = (e, originalX, originalY, endX, endY, deltaX, deltaY) => {
    if(Math.round(deltaX / 100) >= 0)
      return;

    if(Math.abs(Math.round(deltaY / 100)))
      return;

    this.handleCloseLeft();
  }

  handleSwipedRight = (e, originalX, originalY, endX, endY, deltaX, deltaY) => {
    if(Math.round(deltaX / 100) <= 0)
      return;

    if(Math.abs(Math.round(deltaY / 100)))
      return;

    this.handleCloseRight();
  }

  render () {

    const { path, user, domain } = this.props;
    const { isTop, isParties, leftSideIsHidden, rightSideIsHidden } = this.state;
    const isAuthContainerPage = /^\/users\/(auth|forget|registrate|activate|restore)\//.test(path) ? 'mod_auth' : '';
    const logoClassName = `header-mobile__logo${  isTop ? ' mod_fixed' : ''  }${isParties ? ' mod_animated' : ''}`;

    return (
      <header className={`header-mobile ${isAuthContainerPage}`} role="region" aria-label="Шапка сайта">
        <div className={logoClassName}>
          <Logo mod="mod_animate"/>
        </div>

        {/* top bar */}
        <div className="header-mobile__inner">
          {/* eslint-disable-next-line */}
          <div className="header-mobile__hamburger"
            onClick={this.handleOpenLeft}
            aria-label="Кнопка открытия панели с дополнительной навигацией"
            aria-hidden={leftSideIsHidden}
          />
          <div>
            {
              user.type === 'guest'
                ? (
                  <Link to="/users/auth/" aria-label="Авторизация">
                    <i className="user-info__signin-icon"/>
                  </Link>
                )
                : (
                  // eslint-disable-next-line
                  <div className="user-info__signin-avatar"
                    onClick={this.handleOpenRight}
                    aria-label="Кнопка открытия панели личного кабинета"
                    aria-hidden={rightSideIsHidden}
                  >
                    {
                      user.avatar
                        ? <img src={user.avatar.src} alt=""/>
                        : <NoAvatar/>
                    }
                  </div>
                )
            }
          </div>
        </div>

        {/* bottom bar */}
        <div className="header-mobile__navigation" role="navigation" aria-label="Основная навигация">
          <div className="header-mobile__navigation-inner">
            <MainMenuMobile path={path}/>
          </div>
        </div>

        {/* left bar */}
        <ReactSwipeEvents
          onSwiped={this.handleSwipedLeft}
        >
          <div className={`header-mobile__bar${  !leftSideIsHidden ? ' mod_opened' : ''}`}
            role="region"
            aria-label="Панель с дополнительной навигацией"
            aria-hidden={leftSideIsHidden}>
            <div className="header-mobile__bar-inner">
              <div className="header-mobile__bar-panel">
                <Footer domain={domain} mod="panel"/>
              </div>
            </div>
            {/* eslint-disable-next-line */}
            <div className="header-mobile__bar-closer" onClick={this.handleCloseLeft}
              aria-label="Кнопка закрытия дополнительной навигации"/>
          </div>
        </ReactSwipeEvents>

        {/* right bar */}
        {
          user.type !== 'guest' &&
          <ReactSwipeEvents
            onSwiped={this.handleSwipedRight}
          >
            <div className={`header-mobile__bar mod_right${  !rightSideIsHidden ? ' mod_opened' : ''}`}
              role="region"
              aria-label="Панель навигации по личному кабинету"
              aria-hidden={rightSideIsHidden}>
              <div className="header-mobile__bar-inner">
                <div className="header-mobile__bar-panel">
                  <ProfileBar user={user}/>
                </div>
              </div>
              {/* eslint-disable-next-line */}
              <div className="header-mobile__bar-closer" onClick={this.handleCloseRight}
                aria-label="Кнопка закрытия навигации по личному кабинету"/>
            </div>
          </ReactSwipeEvents>
        }
      </header>
    );
  }
}

export default HeaderMobile;
