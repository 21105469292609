import React from 'react'
import './styles.scss'

const PlacesListPlaceholder = () => (
  <>
    <div className="title places-list__placeholder">&nbsp;</div>
    <div className="places-list places-list__placeholder">
      {[...Array(4)].map((item, key) => (
        <div className="places-list-item" key={key}>
          <div className="places-list-item__body">
            <div className="places-list-item__image mod_visible"/>
            <div className="places-list-item__info">
              <div className="places-list-item__caption mod_visible"/>
              <div className="places-list-item__type mod_visible"/>
              <div className="places-list-item__properties">
                <div className="place-item__property mod_strong mod_nodecor">
                  <div className="place-item__property-caption mod_visible"/>
                  <div className="place-item__property-value mod_visible"/>
                </div>
                <div className="place-item__property mod_strong mod_nodecor">
                  <div className="place-item__property-caption mod_visible"/>
                  <div className="place-item__property-value mod_visible"/>
                </div>
                <div className="place-item__property mod_strong mod_nodecor">
                  <div className="place-item__property-caption mod_visible"/>
                  <div className="place-item__property-value mod_visible"/>
                </div>
              </div>
            </div>
          </div>
          <div className="places-list-item__parties">
            <div className="places-list-item__parties-caption mod_visible"/>
            <div className="places-list-item__parties-list">
              <div className="places-list-item__party">
                <div className="places-list-item__party-image mod_visible"/>
                <div className="places-list-item__party-caption mod_visible"/>
              </div>
              <div className="places-list-item__party">
                <div className="places-list-item__party-image mod_visible"/>
                <div className="places-list-item__party-caption mod_visible"/>
              </div>
              <div className="places-list-item__party">
                <div className="places-list-item__party-image mod_visible"/>
                <div className="places-list-item__party-caption mod_visible"/>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
)

export default PlacesListPlaceholder
