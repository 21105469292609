import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUpcomingRequest } from '../../../../api/places';
import './styles.sass';
import {
  SquarePicture,
  CombinedDate,
} from '../../../common';
import {
  IconClock,
} from '../../../common/images';

class UpcomingParties extends Component {

  constructor (props) {
    super(props);

    this.state = {
      items: [],
      weekly: [], 
    };

    window.addEventListener('scroll', () => {
      getUpcomingRequest(props.pageId)
        .then(json => {
          this.setState({
            items: json.items.item?.doArray() || [],
            weekly: json?.weekly.item?.doArray() || [],
          });
        });
    }, { once: true });
  }

  render () {

    const { items, weekly } = this.state;
    const { h1 } = this.props;

    return (
      <div className="place-upcoming-parties">
        <div className="place-upcoming-parties__type">
          <div className="place-upcoming-parties__type">
            <h2 className="place-upcoming-parties__caption">
              Ближайшие вечеринки в &quot;{h1}&quot;:
            </h2>
            {
              items.length ? (
                <div className="place-upcoming-parties__list">
                  {
                    items.map((party, key) => {
                      const startDate = party['date-start']['unix-timestamp'];
                      const endDate = party['date-end']['unix-timestamp'];

                      return (
                        <div className="parties-list-item" key={key}>
                          <Link to={party.link}> <SquarePicture image={party?.image} alt={party.name}/> </Link>
                          <div className="parties-list-item__title">
                            <Link to={party.link}>
                              {party.name}
                            </Link>
                          </div>

                          <div className="parties-list-item__properties">
                            <div className="parties-list-item__property parties-list-item__property_date">
                              <i className="parties-list-item__icon mod_time">
                                <IconClock/>
                              </i>
                              <CombinedDate
                                startDate={startDate}
                                endDate={endDate}
                                useWords
                                isWeekly={party.isWeekly}
                              />
                            </div>
                          </div>

                        </div>
                      );
                    },
                    )
                  }
                </div>
              ) : (
                <div className="place-upcoming-parties__message">
                  Все вечеринки в &quot;{h1}&quot; еще впереди. Мы следим за анонсами, чтобы ты был в курсе всех событий
                  в &quot;{h1}&quot; и не пропустил ни одной вечеринки. Ты можешь посмотреть всю <Link to="/">Афишу</Link> на ближайшие 7 дней или посмотреть
                  список <Link to="/place/">всех заведений</Link>, отсортированный по приближающимся ивентам.
                </div>
              )}
          </div>

          {
            weekly.length ? (
              <div className="place-upcoming-parties__type">
                <div className="place-upcoming-parties__caption">
                  Постоянные вечеринки в &quot;{h1}&quot;:
                </div>
                <div className="place-upcoming-parties__list">
                  {
                    weekly.map((party, key) => {
                      const startDate = party['date-start']['unix-timestamp'];
                      const endDate = party['date-end']['unix-timestamp'];

                      return (
                        <div className="parties-list-item" key={key}>
                          <Link to={party.link}> <SquarePicture image={party?.image} alt={party.name}/> </Link>
                          <div className="parties-list-item__title">
                            <Link to={party.link}>
                              {party.name}
                            </Link>
                          </div>

                          <div className="parties-list-item__properties">
                            <div className="parties-list-item__property parties-list-item__property_date">
                              <i className="parties-list-item__icon mod_time">
                                <IconClock/>
                              </i>
                              <CombinedDate
                                startDate={startDate}
                                endDate={endDate}
                                useWords
                                isWeekly={party.isWeekly}
                              />
                            </div>
                          </div>

                        </div>
                      );
                    },
                    )
                  }
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default UpcomingParties;
