import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setSubscribersRequest } from '../../../../../api/places';
import {
  ButtonLoader,
  Picture,
  PlaceName,
} from '../../../../common'; 

class Item extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
    };
  }

  unsubscribe = () => {
    const { place: { id }, load } = this.props;

    setSubscribersRequest({ id, type:  'remove' })
      .then(load);
  }

  handleClick = () => {
    this.setState({
      isLoading: true,
    });

    this.unsubscribe();
  }

  render () {
    const { place } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="my-places-list-item">
        <div className="my-places-list-item__image">
          <Link to={place.link}>
            <Picture image={place.image} alt={place.name}/>
          </Link>
        </div>
        <div className="my-places-list-item__info">
          <div className="my-places-list-item__caption">
            <Link to={place.link}>
              <PlaceName name={place.name}/>
            </Link>
          </div>
          <div className="my-places-list-item__type">
            {place.type}
          </div>
          <div className="my-places-list-item__button-row">
            <ButtonLoader
              isLoading={isLoading}
              text="Отписаться"
              onClick={this.handleClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Item;
