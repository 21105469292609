import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import './styles.scss';
import Picture from '../../../common/Picture';
import { trackEvent } from '../../../../analytics';

SwiperCore.use([Navigation, Pagination]);

const PlaceSlider = ({ images, h1 }) => (
  !!images.length &&
  <div className="place-slider">
    <h2 className="place-slider__title">
      Фотографии
    </h2>
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: false }}
      onSlideChange={() => trackEvent({ name: 'interior_images_swipe' })}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {
        images.map((item, i) => (
          <SwiperSlide key={i}>
            <Picture
              image={item}
              width="605"
              alt={`Интерьер "${h1}"`}
              loading="lazy"
              itemProp="photo"
            />
          </SwiperSlide>
        ))
      }
    </Swiper>
  </div>
);

export default PlaceSlider;

/* class PlaceSlider extends Component {

  render () {

    const { images, h1 } = this.props
    const settings = {
      variableWidth: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true
    }
    const imagesList = images.doArray()

    return (
      <div className="place-slider">
        {
          imagesList.length > 1
            ? (
              <Slick {...settings}>
                {
                  imagesList.map((item, i) => (
                    <Picture
                      image={item}
                      width="605"
                      key={i}
                      alt={`Интерьер "${h1}"`}
                      loading="lazy"
                    />
                  ))
                }
              </Slick>
            )
            : (
              <Picture
                className="place-slider__single-image"
                image={imagesList[0]}
                width="605"
                alt={`Интерьер "${h1}"`}
              />
            )
        }
      </div>
    )
  }
}

export default PlaceSlider */
