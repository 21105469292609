import React from 'react';
import './styles.sass';

const PlaceName = ({ name = '' }) => {
  const indexString = name.match(/\[[^\]]+\]/);
  const cleanName = name.replace(/\[[^\]]+\]/, '').replace('amp;', '');
  const index = indexString ? <span className="place-name__index">{indexString[0]}</span> : null;
  
  return (
    <>
      {cleanName}
      {index}
    </> 
  );
};

export default PlaceName;
