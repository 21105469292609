import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { trackPageview } from '../../analytics';
import './styles.scss';
import Module from '../Module';
import { PageContext } from '../../contexts';
import 'moment/locale/ru';
import {
  generateSeoTitle,
  generateSeoDescription,
  generateSeoKeywords,
  generateSeoCanonical,
} from '../../helpers/meta';

moment.locale('ru');

const windowOnResize = (f) => {
  let resizeTimer;

  window.onresize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      f();
    }, 50);
  };
};

const Page = ({ store, user, page, ymaps }) => {
  const { status, device, path } = page;
  const [oldStatus, setOldStatus] = useState();

  useEffect(() => {
    if ( oldStatus !== 'loaded' && status === 'loaded' ) {
      const title = generateSeoTitle({ page });

      if(page) {
        if(page.description){
          generateSeoDescription({ page });
        }
        if(page.keywords) {
          generateSeoKeywords({ page });
        }
        generateSeoCanonical({ page, path });
      }

      if(page.method !== 'notfound'){
        trackPageview({ path, title });
      }
    }

    setOldStatus(status);
  }, [oldStatus, status, page, path]);

  windowOnResize(() => store.dispatch({
    type: 'WINDOW_RESIZED',
  }));

  return (
    <PageContext.Provider value={{ page, device, user, ymaps }}>
      <Module store={store} page={page} user={user}/>
    </PageContext.Provider>
  );
};

const getProps = (state) => ({
  page: state.page,
  user: state.user,
  ymaps: state.ymaps,
});

export const prepareContent = ({ content }) => {
  let result = content;

  result = result.replace(/(@([^\s<&,:;!?]+))/g, '<a href="https://www.instagram.com/$2/" target="_blank" rel="noopener noreferrer">$1</a>');

  return result;
};

export default connect(getProps)(Page);

export const getProperties = (page) => {
  if ( !page )
    return {};

  const subject = page.page || page.object;

  if ( !subject )
    return {};

  const groups = subject.properties.group;
  let result = false;

  Object.keys(groups).forEach((key) => {
    const group = groups[key];

    Object.keys(group.property).forEach((prop) => {
      const property = group.property[prop]; let newValue;
      const { name, type, value } = property;

      if ( !result )
        result = {};

      switch ( type ) {
        case 'int':
          newValue = parseInt(value.value, 10);
          break;
        case 'string':
          newValue = value.value;
          break;

        case 'wysiwyg': {
          const preparedValue = prepareContent({ content: value.value });

          newValue = ReactHtmlParser(preparedValue);
          break;
        }

        case 'date':
          newValue = moment(value['unix-timestamp'] * 1000);
          break;
        case 'boolean':
          newValue = !!value.value;
          break;
        case 'img_file':
          newValue = { ...value, src: value.value, isOriginal: true };
          break;
        default:
          newValue = value;
      }

      result[name] = newValue;
    });
  });

  return result;

};
