import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePassword, changeRadio, uploadAvatar } from '../../../../../actions/user';
import Input from '../../../../common/Input';
import { ButtonLoader } from '../../../../common';

import './styles.sass';

class Settings extends Component {
  constructor() {
    super();

    this.state = {
      password: null,
      password_confirm: null,
      radiostation: null,
      isRadioChanged: false,
    };
  }

  handleRadioChange = value => {
    this.setState({
      radiostation: value,
      isRadioChanged: true,
    });
  }

  handleSubmit = () => {
    this.setState({
      isRadioChanged: false,
    });

    const { changeRadio: changeRadioAction } = this.props;

    changeRadioAction(this.state);
  }

  handlePasswordChange = field => (valid, value) => {
    this.setState({
      [field]: valid ? value : null,
    });
  }

  handleChangePasswordSubmit = () => {
    const { changePassword: changePasswordAction } = this.props;

    changePasswordAction(this.state);
  }

  render () {

    const { user: { passwordLoading } } = this.props;
    const { password, password_confirm: passwordConfirm } = this.state;
    const btnDisabled = password && password === passwordConfirm ? null : { disabled: true };

    return (
      <div className="my-settings">

        <div className="my-settings__section">
          <div className="my-settings__section-title">
            Изменить пароль
          </div>

          <div className="my-settings__row mod_text">
            <Input
              className="register-form__input"
              placeholder="Новый пароль"
              type="password"
              validate
              required
              minlength={8}
              onChange={this.handlePasswordChange('password')}
              autoComplete="new-password"
            />
          </div>

          <div className="my-settings__row mod_text">
            <Input
              className="register-form__input"
              placeholder="Повторите пароль"
              type="password"
              validate
              required
              minlength={8}
              hideHelp
              onChange={this.handlePasswordChange('password_confirm')}
              password={password}
              customValidate={value => value === password}
              autoComplete="new-password"
            />
          </div>

          <div className="my-settings__row mod_button">
            <div className="my-settings__button mod_password">
              <ButtonLoader
                mod="mod_pink"
                isResponsive
                isLoading={passwordLoading}
                isDisabled={btnDisabled}
                text="Изменить"
                onClick={this.handleChangePasswordSubmit}
              />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const getProps = () => ({});
const setActions = (dispatch) => bindActionCreators(
  {
    uploadAvatar,
    changeRadio,
    changePassword,
  },
  dispatch,
);

export default connect(getProps, setActions)(Settings);
