import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Breadcrumbs } from '../../components/common';
import PlacesMenu from '../../components/places/Menu';
import PlacesListContainer from './PlacesListContainer';
import PlaceContainer from './PlaceContainer';
import './styles.sass';

const Places = ({ page: { device, path, parents } }) => (
  <>
    <div className="scroll-position places__item-scroll-position"/>
    <div className="places-top">
      <PlacesMenu path={path}/>
      <Breadcrumbs parents={parents} jsonOnly={device.mobile}/>
    </div>

    <Switch>
      <Route exact path="/place/:type?">
        <PlacesListContainer/>
      </Route>
      <Route exact path="/place/:type/:item/">
        <PlaceContainer/>
      </Route>
    </Switch>
  </>
);

export default Places; 
