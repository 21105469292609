import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Picture } from '..';
import './styles.scss';
import { ReactComponent as NoAvatar } from '../images/no-avatar.icon.svg';

class UserInfo extends Component {

  render () {

    const { user } = this.props; 

    return (
      <div className="user-info">
        {user.type === 'guest' ?
          <div className="user-info__signin">
            <div className="user-info__signin-item">
              <i className="user-info__signin-icon"/>
              <Link to="/users/auth/">Войти</Link>
            </div>
          </div>
          :
          <div>
            <div className="user-info__signin">
              <div className="user-info__signin-item">
                <Link to="/users/me/affiche/">
                  <div className="user-info__signin-avatar">
                    {
                      user.avatar
                        ? <Picture image={user.avatar} width={50}/>
                        : <NoAvatar/>
                    }
                  </div>
                </Link>
                <div className="user-info__nickname">
                  <Link to="/users/me/affiche/">
                    {user.fname || user.login}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default UserInfo;
