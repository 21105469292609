import React from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Map from '../../components/common/Map';
import { saveCoords } from '../../actions/places';
import './styles.scss';

const MapContainer = ({ match: { params: { id } }, page: { device }, places: { items } /* saveCoords */ }) => {

  const points = !id ? items : items.filter(item => item.id === parseInt(id, 10));
  const center = id && points.length ? { center: points[0].coords.split(',') } : null;
  const zoom = id && points.length ? { zoom: 16 } : null; 

  return (
    <div className="map">
      <Map
        device={device}
        items={points}
        showMe={false} /*! id */
        {...center}
        {...zoom}
        // saveCoords={saveCoords}
      />
    </div>
  );
};

const getProps = (state) => ({
  places: state.mapPlaces,
});
const setActions = (dispatch) => bindActionCreators({
  saveCoords,
}, dispatch);

export default withRouter(connect(getProps, setActions)(MapContainer));
