import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

export const ErrorsList = ({ page  }) => {
  const [errors, setErrors] = useState(null);
  const { query: { _err: errorsId }, uri } = page;

  useEffect(() => {
    if(errorsId) {
      fetch(`/udata/system/listErrorMessages.json?_err=${errorsId}`, { credentials: 'same-origin' })
        .then(r => r.json())
        .then(json => setErrors(json.items?.item.doArray()));
    } 
  }, []);

  useEffect(() => {
    setErrors(null);
  }, [uri]);

  return (
    errors &&
    <div className="errors-list">
      {errors.map(( item, key ) => (
        <div key={key}>{ReactHtmlParser(item.message) || item}</div>
      ))}
    </div>
  );

};
