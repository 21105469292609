import { IButton } from './types';
import './styles.scss';

export const Button: IButton = (
  {
    caption,
    className = '',
    mode,
    onClick,
    type = 'button',
  },
) => {
  const modeClassName = mode ? `mod_${mode}` : '';
  const props = onClick ? { onClick } : null;

  return (
    <div className={`button ${className} ${modeClassName}`} {...props}>
      {/* eslint-disable-next-line react/button-has-type */}
      <button type={type}>
        {caption}
      </button>
    </div>
  );
};
