import { FComponent } from 'types';
import React, { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

const Booking = React.lazy(() => import('../Booking'));

export const BookingContainer: FComponent<{isShown: boolean, placeId: string}> = ({ isShown, placeId }) => {
  if (!isShown) {
    return null;
  }

  return (
    <div className="place-item__booking">
      <h2>Бронирование</h2>
      <div className="place-item__booking-inner">
        <Suspense fallback={<CircularProgress className="place-item__booking-spinner"/>}>
          <Booking placeId={placeId}/>
        </Suspense>
      </div>
    </div>
  );
};
