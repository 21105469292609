import React from 'react';

const WebsiteLink = props => {

  const { domain, addFrom } = props;
  let { link, caption } = props;
  const fromTag = addFrom ? `?from=${domain}` : '';

  if(!link)
    return null;

  link = link.replace(/https?:\/\//g, '');
  caption = caption || link.replace(/\/.*/, '');

  return (
    // eslint-disable-next-line
    <a href={`http://${link}${fromTag}`} target="_blank" rel="noopener noreferrer nofollow">
      {caption} 
    </a>
  );
};

export default WebsiteLink;
