import React, { useState } from 'react';
import HtmlParser from 'react-html-parser';
import './styles.scss';
import './placeholder.scss';
import {
  AuthConfirm,
  Picture,
  PhoneNumber,
  ButtonLoader,
  WebsiteLink,
  Map,
} from '../../../common';
import {
  IconInstagram,
  IconVkontakte,
  IconTelegram,
  IconFacebook,
  IconPlus,
  IconGeo,
} from '../../../common/images';
import Slider from '../Slider';
import UpcomingParties from '../UpcomingParties';
import CommentsList from '../../../comments/list/List';
import { trackEvent } from '../../../../analytics';
import { ControlPanel } from './components';
import { Button } from "../../../common/Button";
import { BookingContainer } from "../BookingContainer";


export const Place = (props) => {
  const {
    user,
    domain,
    device,
    id,
    h1,
    type,
    logo,
    content,
    region,
    kitchen,
    avgCheck,
    prices,
    workTime,
    phone,
    addPhones,
    website,
    notFromInLink,
    address,
    interiorImages,
    instagram,
    vkontakte,
    coords,
    facebook,
    telegram,
    ceasedToExist,
    setSubscribers,
    isSubscribeLoading,
    currentUserIsSubscriber,
    withoutComments,
    customers,
    bookingIsEnabled,
  } = props;

  const [isBookingShown, setIsBookingShown] = useState(false);

  const subscribe = (actionType) => {
    setSubscribers({ id, type: actionType });
  };

  const addSubscriber = () => {
    subscribe('add');
  };

  const removeSubscriber = () => {
    subscribe('remove');
  };

  /* const loadScript = (url) => (
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      document.head.append(script);
      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Ошибка загрузки скрипта ${url}`));
    })
  )

  const taxiRef = createRef()
  const [scriptLoading, setScriptLoading] = useState()
  const [apiReady, setApiReady] = useState()

  useEffect(() => {
    if(window.YaTaxiWidget){
      setApiReady(1)
    }
    else if (!scriptLoading) {
      setScriptLoading(1)
      loadScript('//yastatic.net/taxi-widget/ya-taxi-widget.js')
        .then(() => {
          setApiReady(1)
        })
    }
  }, [scriptLoading, setApiReady])

  useLayoutEffect(() => {
    try {
      if ( apiReady && taxiRef.current ) {
        window.YaTaxiWidget.init(taxiRef.current)
      }
    } catch ( e ) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [apiReady]) */

  const isCustomer = customers?.includes?.(user.id?.toString());

  return (
    <>
      <div className="place-item"
        aria-label="Информация о заведении"
        itemScope itemType="https://schema.org/Restaurant"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        <meta itemProp="url" content={ website ? (/^http/.test(website) ? website : `http://${website}`) : window.location.href}/>

        {
          isCustomer && <ControlPanel/>
        }

        <div className="place-item__top">
          <div className="place-item__logo">
            <Picture image={logo} alt={h1} itemProp="logo"/>
          </div>
          <div className="place-item__name">
            <h1 className="place-item__caption" itemProp="name">
              {h1}
            </h1>
            <h2 className="place-item__type">
              {type}
            </h2>
          </div>
          <div className="place-item__subscribe">
            {
              bookingIsEnabled &&
              <AuthConfirm user={user} mod={['right', 'left-mobile']}>
                <Button
                  caption="Забронировать"
                  onClick={() => setIsBookingShown(true)}
                />
              </AuthConfirm>
            }
            {
              !ceasedToExist &&
              <AuthConfirm user={user} mod={['right', 'left-mobile']} onClick={() => trackEvent({ name: 'place_subscribe_button' })}>
                {
                  !currentUserIsSubscriber
                    ? (
                      <ButtonLoader
                        mod="mod_pink"
                        isLoading={isSubscribeLoading}
                        text="Подписаться"
                        icon={<IconPlus/>}
                        onClick={addSubscriber}
                      />
                    )
                    : (
                      <ButtonLoader
                        mod="mod_blue"
                        isLoading={isSubscribeLoading}
                        text="Отписаться"
                        onClick={removeSubscriber}
                      />
                    )
                }
              </AuthConfirm>
            }

            {/*<div className="place-item__map-button">
              <Link className="place-item__map-button__button" to={`/map/${id}/`} onClick={() => trackEvent({ name: 'place_map_button' })}>
                <div className="place-item__map-button-icon">
                  <IconGeo/>
                </div>
                <div className="place-item__map-button-caption">
                  На карте
                </div>
              </Link>
            </div>*/}
          </div>
        </div>

        <BookingContainer isShown={isBookingShown} placeId={id} />

        <div className="place-item__inner">
          <div className="place-item__info">
            {
              ceasedToExist
                ? 'это заведение больше не работает'
                : (
                  <div className="place-item__properties">
                    {
                      phone &&
                      <div className="place-item__property mod_strong mod_colored">
                        <div className="place-item__property-caption">Телефон</div>
                        <div className="place-item__property-value">
                          <div onClick={() => window.ym(48965996, 'reachGoal', 'telephone-link-click', { placeId: id })}>
                            <PhoneNumber phone={phone} itemProp="telephone"/>
                          </div>
                          {
                            addPhones?.trim() &&
                            addPhones.split(',').map((phoneItem, i) => (
                              <div onClick={() => window.ym(48965996, 'reachGoal', 'telephone-link-click', { placeId: id })} key={i}>
                                <PhoneNumber phone={phoneItem} itemProp="telephone"/>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    }
                    <div className="place-item__property" itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                      <div className="place-item__property-caption">Адрес</div>
                      <div className="place-item__property-value" itemProp="streetAddress">
                        {address}
                      </div>
                      <meta itemProp="addressLocality" content="Красноадр"/>
                    </div>
                    {
                      region &&
                      <div className="place-item__property">
                        <div className="place-item__property-caption">Район</div>
                        <div className="place-item__property-value">
                          {region}
                        </div>
                      </div>
                    } {
                      workTime &&
                      <div className="place-item__property">
                        <div className="place-item__property-caption">Работает</div>
                        <div className="place-item__property-value">
                          {HtmlParser(workTime)}
                        </div>
                      </div>
                    }
                    {
                      kitchen && !!kitchen.length &&
                      <div className="place-item__property">
                        <div className="place-item__property-caption">Кухня</div>
                        <div className="place-item__property-value">
                          {
                            kitchen.map((item, key) => (
                              <span key={key} itemProp={item}>
                                {item}{key < kitchen.length - 1 ? ', ' : null}
                              </span>
                            ))
                          }
                        </div>
                      </div>
                    }
                    {
                      avgCheck &&
                      <div className="place-item__property">
                        <div className="place-item__property-caption">Средний чек</div>
                        <div className="place-item__property-value">
                          {avgCheck}
                        </div>
                      </div>
                    }
                    {
                      prices && !!prices.length &&
                      <div className="place-item__property">
                        <div className="place-item__property-caption">Цены</div>
                        <div className="place-item__property-value">
                          {prices.map(price =>
                            <div key={price.label}>
                              {price.label} <strong>{`${price.value} руб.`}</strong>
                            </div>,
                          )}
                        </div>
                      </div>
                    }
                    {
                      website &&
                      <div className="place-item__property mod_decor">
                        <div className="place-item__property-caption">Сайт</div>
                        <div className="place-item__property-value">
                          <WebsiteLink link={website} domain={domain} addFrom={!notFromInLink}/>
                        </div>
                      </div>
                    }
                    {
                      (/*instagram || facebook || */vkontakte || telegram) &&
                      <div className="place-item__property mod_vertical-top">
                        <div className="place-item__property-caption">Соц. сети</div>
                        <div className="place-item__property-value">
                          {/*{
                            instagram &&
                            <a className="place-item__social"
                              href={`https://instagram.com/${instagram}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <IconInstagram/>
                            </a>
                          }*/}
                          {
                            vkontakte &&
                            <a className="place-item__social"
                              href={`https://vk.com/${vkontakte}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <IconVkontakte/>
                            </a>
                          }
                          {
                            telegram &&
                            <a className="place-item__social"
                              href={`tg://resolve?domain=${telegram}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <IconTelegram/>
                            </a>
                          }
                          {/*{
                            facebook &&
                            <a className="place-item__social"
                              href={`https://facebook.com/${facebook}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <IconFacebook/>
                            </a>
                          }*/}
                        </div>
                      </div>
                    }
                    {/* <div className="place-item__taxi">
                      <div ref={taxiRef}
                           className="ya-taxi-widget"
                           data-use-location="false"
                           data-size="s"
                           data-theme="normal"
                           data-title="Вызвать такси"
                           data-point-b={coords?.split(',').reverse().join(',').trim()}
                           data-ref="2416054"
                           data-clid="weekends"
                           data-apikey="0e18365be43642bebe1e93ea13bdd4e5"
                           data-description=""
                           data-proxy-url="https://3.redirect.appmetrica.yandex.com/route?start-lat={start-lat}&amp;start-lon={start-lon}&amp;end-lat={end-lat}&amp;end-lon={end-lon}&amp;ref={ref}&amp;appmetrica_tracking_id=1178268795219780156"
                      />
                    </div> */}
                  </div>
                )
            }
          </div>
          <div className="place-item__content">
            {
              !!interiorImages?.length &&
              <div className="place-item__images">
                <Slider images={interiorImages} h1={h1}/>
              </div>
            }
            <div className="place-item__description rich-text">
              {HtmlParser(content)}
            </div>
          </div>
        </div>

        {
          !ceasedToExist &&
          <UpcomingParties pageId={id} h1={h1}/>
        }

        {
          !withoutComments &&
          <CommentsList pageId={id} user={user} caption="Отзывы" formCaption="Напиши отзыв"/>
        }
      </div>

      {
        !device.mobile && !device.tablet && coords
          ? (
            <div className="place-item__map wide-section">
              <Map
                isLazy
                zoom={15}
                center={coords.split(',')}
                items={[
                  {
                    name: h1,
                    address,
                    coords,
                  },
                ]}
                device={device}
              />
            </div>
          )
          : null
      }
    </>
  );
};

export const Placeholder = () => (
  <div className="place-item__placeholder">
    <div className="place-item">
      <div className="place-item__top">
        <div className="place-item__logo mod_visible"/>
        <div className="place-item__name">
          <div className="place-item__caption mod_visible"/>
          <div className="place-item__type mod_visible"/>
        </div>
        <div className="place-item__subscribe"/>
      </div>
      <div className="place-item__inner">
        <div className="place-item__info">
          <div className="place-item__properties">
            {[...Array(5)].map((item, index) =>
              <div className="place-item__property" key={index}>
                <div className="place-item__property-caption mod_visible"/>
                <div className="place-item__property-value mod_visible"/>
              </div>,
            )}
          </div>
        </div>
        <div className="place-item__content">
          <div className="place-item__images mod_visible"/>
          <div className="place-item__description">
            <p className="mod_visible"/>
            <p className="mod_visible"/>
            <p className="mod_visible"/>
          </div>
        </div>
      </div>
    </div>
  </div>
);
