import React, {useContext}  from 'react'
import SocialIcon from '../SocialIcon'
import { ReactComponent as IconVK } from './images/vk.icon.svg'
// import { ReactComponent as IconFB } from './images/fb.icon.svg'
import { ReactComponent as IconYa } from './images/ya.icon.svg'
import { ReactComponent as IconMail } from './images/mail.icon.svg'
import { ReactComponent as IconGoogle } from './images/google.icon.svg'
import { PageContext } from '../../../../contexts'

const SocialAuth = () => {
  const { page } = useContext(PageContext)
  return (
    <div className="auth-container__socials">
      <a className="auth-container__socials-item" href={`/users/oauth/google/?from_page=${page.path}`}>
        <SocialIcon>
          <IconGoogle/>
        </SocialIcon>
      </a>
      {/*<a className="auth-container__socials-item" href={`/users/oauth/fb/?from_page=${page.path}`}>
        <SocialIcon>
          <IconFB/>
        </SocialIcon>
      </a>*/}
      <a className="auth-container__socials-item" href={`/users/oauth/vk/?from_page=${page.path}`}>
        <SocialIcon>
          <IconVK/>
        </SocialIcon>
      </a>
      <a className="auth-container__socials-item" href={`/users/oauth/yandex/?from_page=${page.path}`}>
        <SocialIcon>
          <IconYa/>
        </SocialIcon>
      </a>
      <a className="auth-container__socials-item" href={`/users/oauth/mail/?from_page=${page.path}`}>
        <SocialIcon>
          <IconMail/>
        </SocialIcon>
      </a>
    </div>
  )
}

export default SocialAuth
