import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { IBreadcrumbs } from './types';

const Breadcrumbs: FC<IBreadcrumbs> = ({ parents, jsonOnly }) => (
  <>
    {
      parents && !!parents.length &&
      <script type="application/ld+json">
        {`
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${parents.map((item, index) => `
                {
                  "@type": "ListItem",
                  "position": ${index + 1},
                  "item": {
                    "@id": "${item.link}",
                    "name": "${item.name}"
                  }
                }
              `).join(',')}]
            }
            `}
      </script>
    }
    {
      !jsonOnly &&
      <div className="breadcrumbs" itemProp="breadcrumb"  itemScope itemType="https://schema.org/BreadcrumbList">
        {parents && parents.map((item, index) => (
          <span key={item.link} itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link to={item.link} itemProp="item">{item.name}</Link>
            <meta itemProp="name" content={item.name}/>
            <meta itemProp="position" content={(index + 1).toString()}/>
          </span>
        ))}
      </div>
    }
  </>
);

export default Breadcrumbs;
