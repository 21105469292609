const initialState = {
  items: [],
};

export default function mapPlaces(state = initialState, action){
  switch (action.type){

    case 'MAP_PLACES_LIST_LOADING':
      return {
        ...state,
        status: 'loading',
        items: [],
      };

    case 'MAP_PLACES_LIST_LOADED':
      return {
        ...state,
        status: 'loaded',
        items: action.payload.items?.item.doArray() || [],
      };

    default:
      return state;
  }
}
