import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import './styles.scss';
import { ReactComponent as NoAvatar } from '../../../../common/images/no-avatar.icon.svg';

class Avatar extends Component {
  constructor() {
    super();

    this.state = {
      src: null,
      uploading: false,
      rotate: 0,
    };
  }

  /* componentDidUpdate (prevProps) {
    const { user: { avatarLoading } } = this.props
    const prevAvatarLoading = prevProps.user.avatarLoading

    if(prevAvatarLoading && avatarLoading !== prevAvatarLoading)
      this.setState({
        src: null 
      })
  } */

  handleImageOnLoad = () => {
    this.setState({
      src: null,
      uploading: false,
      rotate: 0,
    });
  }

  handleAvatarChange = event => {
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.addEventListener('load', () =>
      this.setState({
        fileName: file.name,
        src: reader.result,
      }),
    );
    reader.readAsDataURL(file);
  }

  handleAccept = () => {
    const { uploadAvatar } = this.props;
    const { uploading, fileName } = this.state;

    if ( this.editor && !uploading ) {
      const img = this.editor.getImageScaledToCanvas();// .toDataURL('image/png')

      this.setState({
        uploading: true,
      });

      img.toBlob((blob) => {
        uploadAvatar(blob, fileName);
      });
    }
  }

  handleCancel = () => {
    this.setState({
      src: null,
      rotate: 0,
    });
  }

  handleRotate = () => {
    const { rotate } = this.state;

    this.setState({
      rotate: rotate + 90,
    });
  }

  setEditorRef = (editor) => {
    this.editor = editor;
  }

  render () {

    const { user } = this.props;
    const { src, uploading, rotate } = this.state;
    const avatar = user.avatar?.src;
    const isActive = !!src;

    return (
      <div className="my-profile-avatar">

        <div className="my-profile-avatar__image" onTouchStart={event => event.stopPropagation()}>
          <div className={`my-profile-avatar__image-area${  isActive ? ' mod_hided' : ''}`}>
            {
              avatar
                ? (
                  <div className="my-profile-avatar__image-item">
                    <img src={avatar} alt="" onLoad={this.handleImageOnLoad}/>
                  </div>
                )
                : <NoAvatar/>
            }
          </div>

          {
            isActive
              ? (
                <AvatarEditor
                  ref={this.setEditorRef}
                  image={src}
                  width={150}
                  height={150}
                  border={5}
                  borderRadius={100}
                  color={[0, 0, 0, 0.8]} // RGBA
                  scale={1.2}
                  rotate={rotate}
                  accept="image/jpg,image/png"
                />
              )
              : null
          }

          {
            isActive
              // eslint-disable-next-line react/button-has-type
              ? <button className="my-profile-avatar__rotate" onClick={this.handleRotate}/>
              : null
          }
        </div>

        <div className="my-profile-avatar__input">
          {
            !isActive
              ? (
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.handleAvatarChange}
                />
              )
              : null
          }

          <div className={`my-profile-avatar__input-links${  uploading ? ' mod_loading' : ''}`}>
            {/* eslint-disable-next-line */}
            <div className="my-profile-avatar__input-link" onClick={this.handleAccept} role="button">
              {
                !isActive
                  ? 'Изменить фото'
                  : 'Сохранить'
              }
            </div>
            {
              isActive
                ? (
                  // eslint-disable-next-line
                  <div className="my-profile-avatar__input-link" onClick={this.handleCancel}>
                    Отмена
                  </div>
                )
                : null
            }
          </div>
        </div>

      </div>
    );
  }
}

export default Avatar;
