import React from 'react';
import './styles.sass';

const Property = ({ caption = 'Caption', value = '', mod = '' }) => {
  const modClassName = mod ? `mod_${mod}` : '';

  return (
    <div className={`party-properties__item ${modClassName}`}>
      <div className="party-properties__item-caption">
        {caption}
      </div>
      <div className="party-properties__item-value">
        {value} 
      </div>
    </div>
  );
};

const Properties = ({ list }) => (
  <div className="party-properties">
    {
      list?.map((item, key) => {
        if ( !item.value )
          return null;

        return (
          <Property
            caption={item.caption}
            mod={item.mod}
            value={item.value}
            key={key}
          />
        );
      })
    }
  </div>
);

export default Properties;
