import React from 'react';
import { Switch, Route } from 'react-router';

const Item = ({ icon, label, caption, desc, isLiked }) => (
  <>
    <div className={`my-profile-affiche__empty-infographic ${isLiked ? 'mod_liked' : ''}`}/>
    <div className="my-profile-affiche__empty-legend">
      <div className={`my-profile-affiche__empty-button ${isLiked ? 'mod_liked' : ''}`}>
        <div className="party-item__control mod_check ">
          <div className="party-item__control-icon-area">
            <i className="party-item__control-icon mod_active" style={{ cursor: 'default' }}>
              {icon}
            </i>
          </div>
          <div className="party-item__control-label" style={{ maxWidth: 'none' }}>{label}</div>
        </div>
      </div>
      <div className="my-profile-affiche__empty-text">
        <div className="my-profile-affiche__empty-caption">
          {caption}
        </div>
        <div className="my-profile-affiche__empty-desc">
          {desc}
        </div>
      </div>
    </div>
  </>
);

const Infographic = () => (
  <div className="my-profile-affiche__empty">
    <Switch>
      <Route exact path="/users/me/affiche/">
        <Item
          icon={ <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.247 5.926L.87 7.376 8.47 14.59 18.24 1.604 16.642.402 8.219 11.596z" fillRule="nonzero" fill="#E2E8FE" opacity=".9"/>
          </svg>}
          label="я иду"
          caption="Добавь событие"
          desc="нажми кнопку «я&nbsp;пойду» на&nbsp;детальной странице афиши"
        />
      </Route>
      <Route path="/users/me/affiche/liked">
        <Item
          icon={ <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.51 15.657S.37 11.852.37 5.84C.37 2.341 2.58.44 4.917.44v1.522c-.748.003-1.46.32-1.961.877a4.337 4.337 0 0 0-.998 3.006c0 2.472 2.125 4.585 3.907 5.922a23.022 23.022 0 0 0 3.646 2.206 22.877 22.877 0 0 0 3.634-2.206c1.78-1.341 3.9-3.463 3.9-5.956a4.165 4.165 0 0 0-.984-2.93 2.681 2.681 0 0 0-1.965-.847c-1.714 0-4.594 3.868-4.594 3.868S6.61 1.96 4.916 1.96V.439c1.75 0 3.567 1.065 4.579 3.326a5.093 5.093 0 0 1 4.6-3.256c2.324 0 4.536 1.818 4.535 5.298 0 6.045-9.12 9.85-9.12 9.85z"
              fill="#E2E8FE" fillRule="evenodd" opacity=".9"/>
          </svg>}
          label="мне нравится"
          caption="Добавь событие в&nbsp;понравившиеся"
          desc="нажми кнопку «мне&nbsp;нравится» на&nbsp;детальной странице афиши"
          isLiked
        />
      </Route>
      <Route path="/users/me/affiche/ended">
        <Item
          icon={<svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.247 5.926L.87 7.376 8.47 14.59 18.24 1.604 16.642.402 8.219 11.596z" fillRule="nonzero" fill="#E2E8FE" opacity=".9"/>
          </svg>}
          label="я иду"
          caption="Завершенные события"
          desc="после завершения, афиша с&nbsp;«я&nbsp;пойду»&nbsp;появится здесь"
        />
      </Route>
    </Switch>
  </div>
);

export default Infographic;
