const initialState = {
  items: [],
  count: 0,
};

export default function notifications(state = initialState, action){
  switch (action.type){

    case 'NOTIFICATIONS_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'NOTIFICATIONS_LOADED':
      return {
        ...state,
        status: 'loaded',
        ...action.payload,
      };

    default:
      return state;
  }
}
