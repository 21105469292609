import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.scss';

class Search extends Component {

  constructor ( props ) {
    super(props);

    this.state = {
      placeholder: 'Поиск по сайту',
      active: false,
    };

    const { query: { search_string: searchString } } = this.props;
    const { placeholder } = this.state;

    this.state.text = searchString || placeholder;
  }

  componentDidMount () {
    const { autoFocus } = this.props;

    if(autoFocus){
      this.input.focus();
      setTimeout(() => {
        this.input.setSelectionRange(
          this.input.value * 2,
          this.input.value * 2,
        );
      }, 0);
    }
  }

  componentDidUpdate (prevProps) {
    const { query: { search_string: searchString } } = this.props;
    const prevSearchString = prevProps.query.search_string;

    if(searchString !== prevSearchString)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        text: searchString,
      });
  }

  handleSubmit = () => {
    const { text } = this.state;

    if(!text.trim()) {
      return;
    }

    const { history } = this.props;

    history.push(`/search/search_do/?search_string=${encodeURIComponent(text.trim())}`);
    this.input.blur();
  }

  handleChange = event => {
    this.setState({
      text: event.target.value,
    });
  }

  handleFocus = event => {
    const { value } = event.target;
    const { placeholder } = this.state;

    this.setState({
      text: value === placeholder ? '' : value,
      active: true,
    });
  }

  handleBlur = event => {
    const { value } = event.target;
    const { placeholder } = this.state;

    this.setState({
      text: value === '' ? placeholder : value,
      active: false,
    });
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }

  render () {

    const { text, active } = this.state;

    return (
      <div className={`search${  active ? ' search_active' : ''}`}>
        {/* eslint-disable-next-line */}
        <i className="search__icon" onClick={this.handleSubmit}/>
        <input
          ref={element => { this.input = element; }}
          className="search__input"
          name="search_string"
          type="text"
          value={text}
          autoComplete="off"
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}/>
      </div>
    );
  }
}

export default withRouter(Search);
