import React, { Component } from 'react';
import './styles.scss';
import {
  ButtonLoader,
} from '../../../common';

const maxLengthDefault= 482;
const enterLengthDefault= 80;

class CommentsForm extends Component {

  constructor (props) {
    super(props);

    const defaultValue = '';

    this.state = {
      status: '',
      comment: defaultValue,
      commentLength: defaultValue.length,
    };
  }

  handleChange = event => {
    const { field, hiddenField } = this;
    const { value } = event.target;

    this.setState({
      comment: value,
      commentLength: this.getValueLength(value),
    });

    hiddenField.value = value;
    field.style.height = `${hiddenField.scrollHeight  }px`;
  }

  setHeight = (value) => {
    const { field, hiddenField } = this;

    hiddenField.value = value;
    field.style.height = `${hiddenField.scrollHeight  }px`;
  }

  getValueLength = value => {
    const { enterLength = enterLengthDefault } = this.props;

    return value
      .replace(/(?:\r\n|\r|\n)/g, Array(enterLength + 1).join(' '))
      .length;
  }

  isValid = () => {
    const { maxLength = maxLengthDefault } = this.props;
    const { commentLength } = this.state;

    return (
      commentLength > 2 &&
      commentLength <= maxLength
    );
  }

  handleSend = event => {
    event.preventDefault();

    const {
      pageId,
      loadComments,
    } = this.props;

    const { comment } = this.state;
    const isValid = this.isValid();

    if ( !isValid )
      return;

    const uri = `/udata/comments/post/${pageId}.json`;
    const data = new FormData();

    data.append('comment', comment);

    this.setState({
      status: 'loading',
    });

    fetch(
      uri,
      {
        credentials: 'same-origin',
        method: 'POST',
        body: data,
      },
    )
      .then(response => response.json())
      .then(() => {
        this.field.value = '';
        this.setHeight('');
        this.setState({
          comment: '',
          commentLength: 0,
          status: '',
        }, () => loadComments(pageId));
      });
  }

  render () {

    const { user, maxLength = maxLengthDefault, formCaption } = this.props;
    const { commentLength, status } = this.state;
    const isFilled = commentLength;
    const isValid = this.isValid();
    const visibleInvalid = commentLength && !isValid;
    const contentEditable = user.isGuest
      ? (
        <div className="comments-form-field__field mod_stub"/>
      )
      : (
        <>
          <textarea
            ref={ref => {this.field = ref;}}
            className="comments-form-field__textarea"
            onChange={this.handleChange}
          />
          <div className="comments-form-field__textarea-hidden">
            <textarea
              ref={ref => {this.hiddenField = ref;}}
              className="comments-form-field__textarea"
            />
          </div>
        </>
      );

    const
      isFillingClass = isFilled ? 'mod_filling' : '';
    const isFilledClass = !visibleInvalid && isFilled ? 'mod_filled' : '';
    const isInvalidClass = visibleInvalid ? 'mod_invalid' : '';

    return (
      <div className="comments-form">
        <div className={`comments-form-field ${isFillingClass} ${isFilledClass} ${isInvalidClass}`}>
          <div className="comments-form-field__placeholder">
            {formCaption}
          </div>
          {contentEditable}
          <div className="comments-form-field__indicator">
            <div className="comments-form-field__indicator-filled"/>
            <div className="comments-form-field__indicator-invalid"/>
            <div className="comments-form-field__indicator-focused"/>
            <div className="comments-form-field__indicator-valid"/>
          </div>
        </div>
        <div className={`comments-form__length${  maxLength - commentLength < 0 ? ' mod_negative' : ''}`}>
          {
            isFilled
              ? maxLength - commentLength
              : <span>&nbsp;</span>
          }
        </div>
        <div className="comments-form__button-row">
          <ButtonLoader
            isLoading={status === 'loading'}
            isDisabled={!isValid}
            text="Отправить"
            onClick={this.handleSend}
          />
        </div>
      </div>
    );
  }
}

export default CommentsForm;
