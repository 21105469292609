import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { NavBar } from '../../common'; 

const PartiesMenu = ({ types = [] }) => (
  <NavBar
    links={types}
    isActive={link => (match, location) => location.pathname === link}
  />
);

export default connect(
  (state) => ({
    types: state.partyTypes.types,
  }),
)(PartiesMenu);
