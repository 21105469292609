import React, { PureComponent } from 'react'

import './styles.scss'

class Placeholder extends PureComponent {

  render () {

    return (
      <div className="parties-list__placeholder">
        <div className="parties-list-day">
          <div className="parties-list-day__date">
            <div className="parties-list-day__date-inner">
              <div className="parties-list-day__date-value">
                <div className="parties-list-day__date-digits mod_visible"/>
                <div className="parties-list-day__date-word mod_visible"/>
              </div>
            </div>
          </div>
          <div className="parties-list-day__items">
            {
              [0,1,2,3,4,5].map((i) => (
                <div className="parties-list-item" key={i}>
                  <div className="parties-list-item__image mod_visible"/>
                  <div className="parties-list-item__title mod_visible"/>
                  <div className="parties-list-item__property mod_visible"/>
                  <div className="parties-list-item__property mod_visible"/>
                  <div className="parties-list-item__property mod_visible"/>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Placeholder