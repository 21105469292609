import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadPartial } from '../../actions/places';
import List from '../../components/places/list/List';

const PlacesListContainer = ({ places, loadPartial: loadPartialAction }) => (
  <List {...places} loadPartial={loadPartialAction}/>
);

export default connect(
  (state) => ({
    places: state.places,
  }),
  ( dispatch ) => bindActionCreators({
    loadPartial,
  }, dispatch),
)(PlacesListContainer); 
