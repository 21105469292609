import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import Parties from '../Parties';
import {
  Picture,
  PhoneNumber,
  WebsiteLink,
  PlaceName,
} from '../../../common';

const PlacesListItem = ({ place, domain }) =>  {

  const {
    id,
    link,
    image,
    name,
    type,
    address,
    website,
    phone,
    parties,
    notFromInLink,
  } = place;

  return (
    <div className="places-list-item">
      <div className="places-list-item__body">
        <div className="places-list-item__image">
          <Link to={link}>
            <Picture image={image} alt={name}/>
          </Link>
        </div>
        <div className="places-list-item__info">

          <div className="places-list-item__caption-area">
            <div className="places-list-item__caption">
              <Link to={link} title={name}>
                <PlaceName name={name}/>
              </Link>
            </div>
            <div className="places-list-item__type">
              {type}
            </div>
          </div>

          <div className="places-list-item__properties">
            <div className="place-item__property">
              <div className="place-item__property-caption">Адрес</div>
              <div className="place-item__property-value">
                {address}
              </div>
            </div>
            {
              website
                ?(
                  <div className="place-item__property mod_decor">
                    <div className="place-item__property-caption">Сайт</div>
                    <div className="place-item__property-value">
                      <WebsiteLink link={website} domain={domain} addFrom={!notFromInLink} />
                    </div>
                  </div>
                )
                : null
            }
            {
              phone
                ?(
                  <div className="place-item__property mod_strong mod_colored">
                    <div className="place-item__property-caption">Телефон</div>
                    <div className="place-item__property-value">
                      <PhoneNumber phone={phone}/>
                    </div>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </div>
      <div className="places-list-item__parties">
        <Parties parties={parties}/>
      </div>
    </div>
  );
};

export default PlacesListItem;
