import React, {Component} from 'react';
import './styles.scss';

export class ContentNotfound extends Component {

  render() {
    return(
      <div className="not-found">
        <h1>
          Страница не найдена
        </h1>
        <p>
          404
        </p>
      </div>
    )
  }
}
