import { take, select, put } from 'redux-saga/effects';
import { loadUser } from '../actions/user';

export function* userSaga () {
  while ( true ) {
    yield take('PAGE_LOADED');

    const { id, isGuest, fname } = yield select(state => state.user);

    if ( !isGuest && !fname )
      yield put(loadUser(id));
  } 
}
