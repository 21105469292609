import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { uploadAvatar, changeName } from '../../../../../actions/user';
import Avatar from '../Avatar';
import NameEditor from '../NameEditor';
import { PuidView } from '../../../../common';

class FastSettings extends Component {

  render () {

    const { user, uploadAvatar: uploadAvatarAction, changeName: changeNameAction } = this.props;

    return (
      <div className="my-profile-fast-settings">
        <Avatar user={user} uploadAvatar={uploadAvatarAction}/>

        <NameEditor user={user} changeName={changeNameAction}/>
        <div className="my-profile-fast-settings__puid">
          <PuidView puid={user.puid}/>
        </div>
      </div>
    );
  }
}

const getProps = () => ({});
const setActions = (dispatch) => bindActionCreators(
  {
    uploadAvatar,
    changeName,
  },
  dispatch,
);

export default connect(getProps, setActions)(FastSettings);
