import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { reportErrorRequest } from '../../api/page';
import Header from '../../components/common/Header';
import HeaderMobile from '../../components/common/HeaderMobile';
import Footer from '../../components/common/footer/Footer';
import Parties from '../Parties';
import Places from '../Places';
import Blogs from '../Blogs';
import Users from '../Users';
import Content from '../Content';
import Search from '../Search';
import Promo from '../Promo';
import Map from '../Map';
import { isProduction } from '../../environment';
import { Blog } from "../Blog";

// import Artists from '../Artists'

class Module extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate (prevProps) {
    const { page } = this.props;

    if ( prevProps.page.uri !== page.uri )
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      });
  }

  componentDidCatch (error, info) {
    if(isProduction){
      const { store: { getState } } = this.props;

      this.setState(
        { hasError: true },
        () => reportErrorRequest(error, info, getState()),
      );
    }
  }

  render () {

    const { page, user } = this.props;
    const {
      device,
      module,
      method,
      path,
      query,
      domain,
    } = page;
    const { hasError } = this.state;
    const isAuthContainerPage =
      /^\/users\/(auth|forget|registrate|activate|restore)\//.test(path)
        ? 'mod_flex' : '';

    return (
      <div className="page-container">
        {
          !device.mobile
            ? (
              <Header
                path={path}
                query={query}
                user={user}
              />
            )
            : (
              <HeaderMobile
                path={path}
                query={query}
                module={module}
                user={user}
                domain={domain}
              />
            )
        }

        <main className={`main ${isAuthContainerPage}`}
          aria-label="Основной контент">
          {
            !hasError
              ? (() => {
                switch ( true ) {
                  case /\/map\//.test(path):
                    return (
                      <Route path="/map/:id?">
                        <Map page={page}/>
                      </Route>
                    );
                  case /\/search\//.test(path):
                    return (
                      <Route path="/search/">
                        <Search page={page} user={user}/>
                      </Route>
                    );
                  case module === 'users' && method === 'login':
                    return <Users page={page} user={user}/>;
                  case module === 'content' && method === 'notfound':
                    return <Content page={page} user={user}/>;
                  default:
                    return (
                      <Switch>
                        <Route exact path="/">
                          <Parties page={page} user={user}/>
                        </Route>
                        <Route path="/affiche/">
                          <Parties page={page} user={user}/>
                        </Route>
                        <Route path="/place/">
                          <Places page={page} user={user}/>
                        </Route>
                        <Route path="/blog/" component={Blog}/>
                        {/*<Route path="/blogs/" component={Blogs}/>*/}
                        {/* <Route path="/all-artists/">
                            <Artists page={page} user={user}/>
                          </Route> */} 
                        <Route path="/users/">
                          <Users page={page} user={user}/>
                        </Route>
                        <Route path="/promotions/">
                          <Promo page={page} user={user}/>
                        </Route>

                        <Route>
                          <Content page={page}/>
                        </Route>
                      </Switch>
                    );
                }
              })()
              : (
                <div>Произошла ошибка</div>
              )
          }
        </main>

        {
          !device.mobile
            ? (
              <Footer domain={page.domain}/>
            )
            : null
        }
      </div>
    );
  }
}

export default Module;
