import React from 'react';
import { connect } from 'react-redux';
import NavBar from '../../common/NavBar';

const PlacesMenu = ({ types = [] }) => (
  <NavBar
    links={types}
    role="navigation"
    aria-label="Навигация по типам заведений"
  />
);

export default connect(
  (state) => ({
    types: state.placeTypes.types, 
  }),
)(PlacesMenu);
