import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { PageContext } from '../../contexts';
import {
  BlogsList,
  BlogsListPlaceholder,
} from '../../components/blogs/list/List';

const BlogsListContainer = ({ blogs: { items, status } }) => {

  const { page } = useContext(PageContext);

  useEffect(() => {
    if(!page.isCached){
      const element = document.querySelector('.page-container');

      scrollIntoView(element, { 
        time: 0,
        align: {
          top: 0,
        },
      });
    }
  }, [page.isCached]);

  if ( status === 'loaded' ) {
    return <BlogsList items={items}/>;
  }

  return <BlogsListPlaceholder/>;
};

export default connect(
  (state) => ({
    blogs: state.blogs,
  }),
)(BlogsListContainer);
