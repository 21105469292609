import React from 'react';
import { parseWithLinks } from '../../../../helpers';
import './styles.sass';

export const BlogsItem = ({ post }) => {
  const {
    name,
    content,
  } = post;

  return (
    <div className="blogs-item">
      <h1 className="blogs-item__caption">
        {name}
      </h1>
      <div className="blogs-item__text rich-text">
        {parseWithLinks(content)}
      </div>
    </div>
  );
};

export const BlogsItemPlaceholder = () => (
  <div className="content-section-preloader">
    <div className="content-section-preloader__title mod_visible"/>
    <div className="content-section__area">
      <div className="content-section-preloader__image-area">
        <div className="content-section-preloader__image mod_visible"/>
        <div>
          {[...(new Array(5))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
        </div>
      </div>
      {[...(new Array(15))].map((item, i) => <div key={i} className="content-section-preloader__paragraph mod_visible"/>)}
    </div>
  </div>
);
