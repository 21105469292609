// eslint-disable-next-line consistent-return
const middleware = store => next => action => {
  if(action.type !== 'PROMISE'){
    return next(action);
  }

  const [startAction, successAction, failureAction] = action.actions;

  store.dispatch({
    type: startAction,
    meta: action.meta,
  });
  action.promise
    .then(data => {
      if(data.status && data.status !== 200){
        store.dispatch({
          type: failureAction,
          meta: action.meta,
          reason: data.status,
        });
      } else {
        data.json().then(json => {
          store.dispatch({
            type: successAction,
            payload: json,
            meta: action.meta,
          });
        })
      }
    })
    .catch(reason => store.dispatch({
      type: failureAction,
      meta: action.meta,
      reason,
    }));
};

export default middleware;
