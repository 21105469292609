import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import scrollIntoView from "scroll-into-view";
import { BlogsList, BlogsListPlaceholder } from "../../components/blogs/list/List";
import { PageContext } from "../../contexts";
import { bindActionCreators } from "redux";
import { loadPartial } from "../../actions/blog";

const BlogList = ({ blog, loadPartialAction }) => {
  const { page } = useContext(PageContext);

  useEffect(() => {
    if(!page.isCached){
      const element = document.querySelector('.page-container');

      scrollIntoView(element, {
        time: 0,
        align: {
          top: 0,
        },
      });
    }
  }, [page.path, page.isCached]);

  if ( blog.status === 'loading' ) {
    return <BlogsListPlaceholder/>;
  }

  return <BlogsList loadPartial={loadPartialAction} {...blog} path={page.path}/>;
};

export default connect(
  ({ blog }) => ({ blog }),
  ( dispatch ) => bindActionCreators({
    loadPartialAction: loadPartial,
  }, dispatch),
)(BlogList);
