import React from 'react';
import { AuthConfirm } from '../../../common';
import './styles.scss';

const Control = ({ user, is_active: isActive, isBlocked, /* isMobile, noactive_text, */ mod, icon, activeText, onChange }) => {

  const handleClick = is_active => () => onChange(is_active);
  const blockedClass = isBlocked ? 'mod_blocked' : '';

  return (
    <div className={`party-item__control mod_${mod} ${blockedClass}`}>
      <div className="party-item__control-icon-area">
        <AuthConfirm user={user} mod="left-out">
          {/* eslint-disable-next-line */}
          <i className={`party-item__control-icon${  isActive ? ' mod_active' : ''}`}
            tabIndex="0"
            onClick={handleClick(isActive)}>
            {icon}
          </i>
        </AuthConfirm>
      </div>
      <div className="party-item__control-label">
        {/* {(!is_active && !isMobile) || (is_active && isMobile) ? noactive_text : active_text} */}
        {activeText}
      </div>
    </div>
  );
};

export default Control;
