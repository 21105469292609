const placesInitialState = {
  title: '%%Title%%',
  items: [],
  pageNum: 0,
};

export const places = (state = placesInitialState, action) => {
  switch ( action.type ) {

    case 'PLACES_LIST_LOADING':
      return {
        ...state,
        title: '',
        status: 'loading',
      }; 

    case 'PLACES_LIST_LOADED': {
      return {
        ...state,
        ...action.payload,
        status: 'loaded',
      };
    }

    case 'PLACES_PARTIAL_LOADING':
      return {
        ...state,
        status: 'partial-loading',
      };

    case 'PLACES_PARTIAL_LOADED': {
      return {
        ...state,
        ...action.payload,
        items: [...state.items, ...action.payload.items],
        status: 'loaded',
      };
    }

    default:
      return state;
  }
};

const typesInitialState = {
  types: [],
};

export const placeTypes = (state = typesInitialState, action) => {
  switch (action.type){

    case 'PLACES_TYPES_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'PLACES_TYPES_LOADED':
      return {
        ...state,
        ...action.payload,
        status: 'loaded',
      };

    default:
      return state;
  }
};

const itemInitialState = {
  place: {},
};

export const placeItem = (state = itemInitialState, action) => {
  switch (action.type){

    case 'PLACE_ITEM_LOADING':
      return {
        place: {},
        status: 'loading',
      };

    case 'PLACE_ITEM_LOADED':
      return {
        ...state,
        status: 'loaded',
        place: {
          ...state.place,
          ...action.payload,
        },
      };

    case 'PLACE_USER_SUBSCRIBER_LOADED':
      return {
        ...state,
        place: {
          ...state.place,
          ...action.payload,
        },
      };

    case 'PLACE_SUBSCRIBER_LOADING':
      return {
        ...state,
        place: {
          ...state.place,
          isSubscribeLoading: true,
        },
      };

    case 'PLACE_SUBSCRIBER_LOADED':
      return {
        ...state,
        place: {
          ...state.place,
          ...action.payload,
          isSubscribeLoading: false,
        },
      };

    default:
      return state;
  }
};
