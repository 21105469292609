import { isDevelopment } from '../environment';

export const loadPageRequest = ({ pathname, search = '' }) => fetch(`/content/api/page/(${pathname})/`); // `${pathname}.json${search}`

export const loadMenuRequest = () => fetch(`/udata/content/menu/.json`);

export const reportErrorRequest = (error, info, state) => {
  if ( isDevelopment ){
    console.log(error);
    console.log(info);
    console.log(state);

    return null;
  }
 
  const data = new FormData();

  data.append('error', error);
  data.append('info', JSON.stringify(info));

  try {
    data.append('state', JSON.stringify(state));
  } catch (err) {
    console.log(err);
  }

  return fetch(
    `/udata/content/reportError/.json`,
    {
      method: 'POST',
      body: data,
    },
  );

};
