import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import './styles.scss';
import Search from '../../../common/Search';
import Placeholder from '../Placeholder';
import {
  ButtonLoader,
  Picture,
  PlaceName,
} from '../../../common';
import { ReactComponent as PlaceIcon } from './images/place.icon.svg';

class SearchList extends Component {

  render () {

    const {
      query,
      search: {
        status,
        places,
        items,
        total,
      },
      handleMore,
    } = this.props;

    if (status === 'loading') {
      return (
        <div className="search-list">
          <Search query={query} autoFocus/>
          <Placeholder/>
        </div>
      );
    }
 
    return (
      <div className="search-list">

        <Search query={query} autoFocus/>

        {
          query.search_string
            ? (
              <>
                {
                  places.length
                    ? (
                      <div className="search-list__places">
                        <div className="search-list__places-caption">
                          <div className="search-list__places-caption-icon">
                            <PlaceIcon/>
                          </div>
                            Места ({places.length})
                        </div>
                        <div className="search-list__places-list">
                          {
                            places.map(item => (
                              <Link className="search-list__places-item" to={item.link} key={item.id}>
                                <div className="search-list__places-item-image">
                                  <Picture image={item.image} alt={item.name}/>
                                </div>
                                <div className="search-list__places-item-info">
                                  <div className="search-list__places-item-caption">
                                    <PlaceName name={item.name}/>
                                  </div>
                                  <div className="search-list__places-item-type">
                                    {item.placeType}
                                  </div>
                                </div>
                              </Link>
                            ))
                          }
                        </div>
                      </div>
                    )
                    : null
                }

                <div className="search-list__other">
                  <div className="search-list__other-caption">
                      Найдено результатов по запросу: {total}
                  </div>
                  <div className="search-list__other-list">
                    {items.map(item => (
                      <div key={item.id} className="search-list__item">
                        <div className="search-list__item-side">
                          <Link className="search-list__item-link" to={item.link}>
                            {item.name}
                          </Link>
                          <div>
                            {ReactHtmlParser(item.context)}
                          </div>
                        </div>
                      </div>
                    ),
                    )}
                  </div>
                </div>

                {
                  items.length < total
                    ? (
                      <div className="search-list__button-row">
                        <ButtonLoader
                          isLoading={status === 'partial-loading'}
                          text="Загрузить ещё"
                          onClick={handleMore}
                        />
                      </div>
                    )
                    : null
                }
              </>
            )
            : (
              <div className="search-list__infographic">
                  Введи название или&nbsp;начало названия события/заведения
              </div>
            )
        }
      </div>
    );
    
  }
}

export default SearchList;
