import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthContainer from '../../components/users/auth/AuthContainer';
import MyProfile from '../../components/users/myprofile/MyProfile';

class Users extends Component {

  render () {

    const { page, user } = this.props; 
    const { method } = page;

    if (method !== 'login') {
      return (
        <Switch>
          <Route path="/users/(auth|forget|registrate|activate|restore)/">
            <AuthContainer page={page} user={user}/>
          </Route>
          <Route path="/users/me/:action?/">
            <MyProfile page={page} user={user}/>
          </Route>
          <Route>
            <div>
              Метод не найден
            </div>
          </Route>
        </Switch>
      );
    }

    if (page.path !== '/users/auth/') {
      return (
        user.isGuest
          ? (
            <Redirect to="/users/auth/"/>
          )
          : (
            <div>
              Нет доступа
            </div>
          )
      );
    }
 
    return null;
  }
}

export default Users;
