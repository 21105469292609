const blogsInitialState = {
  items: [],
  pageNum: 0,
};

export const blogs = (state = blogsInitialState, action) => {
  switch ( action.type ) {

    case 'BLOGS_LIST_LOADING':
      return {
        ...state,
        status: 'loading', 
      };

    case 'BLOGS_LIST_LOADED': {
      return {
        ...state,
        items: action.payload.items?.item.doArray() || [],
        status: 'loaded',
        pageNum: action.payload.page,
        total: action.payload.total,
      };
    }

    default:
      return state;
  }
};

const blogsAuthorInitialState = {
  users: [],
};

export const blogsAuthor = (state = blogsAuthorInitialState, action) => {
  switch ( action.type ) {

    case 'BLOGS_AUTHOR_LOADING': {
      return {
        ...state,
        status: 'loading',
      };
    }

    case 'BLOGS_AUTHOR_LOADED': {
      return {
        ...state,
        users: action.payload.users?.item.doArray() || [],
        status: 'loaded',
      };
    }

    default:
      return state;
  }
};

const blogsPostInitialState = {
  post: {},
};

export const blogsPost = (state = blogsPostInitialState, action) => {
  switch ( action.type ) {

    case 'BLOGS_ITEM_LOADING': {
      return {
        ...state,
        status: 'loading',
      };
    }

    case 'BLOGS_ITEM_LOADED': {
      return {
        ...state,
        post: action.payload,
        status: 'loaded',
      };
    }

    default:
      return state;
  }
};
