export const loadUserRequest = (id = null) => {
  if(!id)
    return null;

  return fetch(`/udata/users/user/${id}.json`, { credentials: 'same-origin' });
};

export const uploadAvatarRequest = file => {
  const data = new FormData();
  const time = (new Date()).getTime();

  data.append('data[user_avatar]', file, `avatar.${time}.png`);

  return fetch(
    '/udata/users/upload_avatar.json',
    {
      credentials: 'same-origin',
      method: 'POST',
      body: data,
    },
  );
};

export const changeNameRequest = (fname) => {
  const data = new FormData();

  data.append('fname', fname);

  return fetch(
    '/udata/users/change_name.json',
    {
      credentials: 'same-origin',
      method: 'POST',
      body: data,
    },
  );
};

export const changePasswordRequest = (params) => {
  const data = new FormData();

  data.append('password', params.password);
  data.append('password_confirm', params.password_confirm);

  return fetch(
    '/udata/users/settings_do.json',
    {
      credentials: 'same-origin',
      method: 'POST',
      body: data,
    },
  );
};

export const changeRadioRequest = (params) => {
  const data = new FormData();

  data.append('radiostation', params.radiostation);

  return fetch(
    '/udata/users/change_radiostation.json',
    {
      credentials: 'same-origin',
      method: 'POST',
      body: data,
    },
  );
};

export const loadMyAfficheRequest = (type = 'go') => fetch(`/udata/users/me_affiche/${type}.json`, { credentials: 'same-origin' });

export const loadMyAffichePartialRequest = (type = 'go', page) => fetch(`/udata/users/me_affiche/${type}.json?p=${page}`, { credentials: 'same-origin' });

export const loadPlacesRequest = () => fetch('/udata/users/me_places.json', { credentials: 'same-origin' });
