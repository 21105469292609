import { fork, all } from 'redux-saga/effects';

export const routesSaga = function* routesSaga ({ path, routes }) {
  const sagas = [];

  Object.keys(routes).forEach(routesListItem => {
    const routesList = routesListItem.split('|');

    routesList.forEach(route => {
      const params = {};
      const saga = routes[routesListItem];
      const pathParts = path.split('/').filter(item => item !== '');
      const routeParts = route.split('/').filter(item => item !== '');
      const mappedParts = routeParts.map((item, index) => {
        const matches = item.match(/^:([^?]+)/);
        const paramName = matches ? matches[1] : null;
        const paramValue = paramName ? pathParts[index] : null;

        if ( paramName )
          params[paramName] = paramValue;
        return (`${item  }\\/`).replace(/^:[^?]+(\?)?\\\//, '([^/]+\\/)$1');
      });
      const regString = `^\\/${  mappedParts.join('')  }$`;
      const reg = new RegExp(regString);

      if ( reg.test(path) )
        sagas.push(fork(saga, { path, params }));
    });
  });
  yield all(sagas);
};
