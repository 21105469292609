import { put, select } from 'redux-saga/effects';
import {
  loadAuthor,
  loadList,
  loadPost,
} from '../actions/blogs';

export function* blogsSaga ({ path }) {
  yield put(loadAuthor({ path }));
}

export function* blogsListSaga ({ path }) {
  const { isCached } = yield select(state => state.page);

  if ( !isCached )
    yield put(loadList({ path }));
}

export function* blogsItemSaga ({ path }) {
  const { isCached } = yield select(state => state.page);

  if ( !isCached )
    yield put(loadPost({ path })); 
}
