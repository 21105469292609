import { loadListRequest , loadPartialRequest } from '../api/search';

export const loadList = ( searchString ) => ({
  type: 'PROMISE',
  actions: ['SEARCH_LIST_LOADING', 'SEARCH_LIST_LOADED', 'SEARCH_LIST_LOAD_FAILURE'],
  promise: loadListRequest(searchString),
});

export const loadPartial = ( searchString, cur_page ) => ({
  type: 'PROMISE',
  actions: ['SEARCH_PARTIAL_LOADING', 'SEARCH_PARTIAL_LOADED', 'SEARCH_PARTIAL_LOAD_FAILURE'],
  promise: loadPartialRequest(searchString, cur_page),
});
