export const loadListRequest = ( pagePath = null, query = '' ) => {
  const path = pagePath ? `/(${  pagePath  })` : '';

  return fetch(`/udata/parties/get_main_list${path}/.json${query}`, { credentials: 'same-origin' });
};

export const loadTypesRequest = () => fetch(`/udata/parties/get_types_list/.json`);

export const loadItemRequest = ({ path }) => fetch(`/udata/parties/get_item/(${path})/.json`);

export const loadDetails = ( pageId = null ) => {
  const id = pageId ? `/${  pageId}` : '';

  return fetch(`/udata/parties/get_party_details${id}/.json`, { credentials: 'same-origin' });
};

export const setLikeRequest = ({ id, action = 'add' }) =>
  fetch(`/udata/parties/set_like/${id}/${action}`);

export const setWillGoRequest = ({ id, action = 'add' }) =>
  fetch(`/udata/parties/set_will_go/${id}/${action}`);
