import React from 'react';
import PropTypes from 'prop-types';

const PhoneNumber = props => {

  const { phone, itemProp } = props;
  const reg = /(\+7|8)[\s-]?(\d{3})[\s-]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/;
  const string = phone.trim();

  return (
    <a className="phone-link" href={`tel:${string.replace(reg, '$1$2$3$4$5')}`} itemProp={itemProp}>
      {string.replace(reg, '$1 ($2) $3-$4-$5')}
    </a>
  );
};

PhoneNumber.propTypes = {
  phone: PropTypes.string.isRequired,
};

export default PhoneNumber;
