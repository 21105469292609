export const loadListRequest = ({ path }) => fetch(`/content/api/places/(${path})/.json`);

export const loadPartialRequest = ({ path, pageNum }) => fetch(`/content/api/places/(${path})/${pageNum}/.json`);

export const loadTypesRequest = () => fetch(`/content/api/place_types/`);

export const loadPlaceRequest = ({ path }) => fetch(`/content/api/place/(${path})/`);

export const getIsSubscriberRequest = ({ path }) => fetch(`/udata/places/get_is_subscriber/(${path})/.json`);

export const setSubscribersRequest = ({ id, type = 'add' }) => fetch(`/udata/places/set_subscribers/${id}/${type}`);

export const getUpcomingRequest = (pageId) => {
  const url = `/udata/places/get_upcoming_list${  pageId ? `/${  pageId}` : ''}`;

  return fetch(`${url  }/.json`, { credentials: 'same-origin' });
};

export const loadListForMapRequest = () => fetch('/udata/places/get_list_for_map/.json', { credentials: 'same-origin' });
