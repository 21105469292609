const partiesInitialState = {
  days: [],
  description: null,
};

export const parties = (state = partiesInitialState, action) => {
  switch (action.type){

    case 'PARTIES_LIST_LOADING':
      return {
        ...state,
        status: 'loading',
      }; 

    case 'PARTIES_LIST_LOADED': {
      const { days, description } = action.payload;

      return {
        ...state,
        status: 'loaded',
        description,
        days: days?.item?.doArray() || [],
      };
    }

    default:
      return state;
  }
};

const typesInitialState = {
  types: [],
};

export const partyTypes = (state = typesInitialState, action) => {
  switch (action.type){

    case 'PARTIES_TYPES_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'PARTIES_TYPES_LOADED':
      return {
        ...state,
        status: 'loaded',
        types: action.payload.items.item.doArray(),
      };

    default:
      return state;
  }
};

const itemInitialState = {
  party: {
    likes: [],
    willGo: [],
  },
};

export const partyItem = (state = itemInitialState, action) => {
  switch (action.type){

    case 'PARTIES_ITEM_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'PARTIES_ITEM_LOADED':
      return {
        ...state,
        status: 'loaded',
        party: {
          ...action.payload,
          likes: action.payload.likes?.doArray() || [],
          willGo: action.payload.willGo?.doArray() || [],
        },
      };

    case 'PARTIES_ITEM_FAILURE':
      return {
        ...state,
        status: 'error',
        party: null,
      };

    case 'PARTY_LIKES_LOADING': {
      const { userId, isLiked } = action.meta;
      const { likes } = state.party;

      return {
        ...state,
        party: {
          ...state.party,
          likedUsersBlocked: true,
          likes: !isLiked ? [...likes, userId] : likes.filter(i => i !== userId),
        },
      };
    }

    case 'PARTY_LIKES_LOADED':
      return {
        ...state,
        party: {
          ...state.party,
          likedUsersBlocked: false,
          likes: action.payload,
        },
      };

    case 'PARTY_WILLGO_LOADING': {
      const { userId, isWillGO } = action.meta;
      const { willGo } = state.party;

      return {
        ...state,
        party: {
          ...state.party,
          willGoUsersBlocked: true,
          willGo: !isWillGO ? [...willGo, userId] : willGo.filter(i => i !== userId),
        },
      };
    }

    case 'PARTY_WILLGO_LOADED':
      return {
        ...state,
        party: {
          ...state.party,
          willGoUsersBlocked: false,
          willGo: action.payload,
        },
      };

    default:
      return state;
  }
};
