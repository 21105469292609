import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import './styles.scss'

const Content = ({ page: { title, content }})=> (
  <div className="content-section">
    <h1>
      {title}
    </h1>
    <div className="content-section__area">
      {ReactHtmlParser(content)}
    </div>
  </div>
)

export default Content
