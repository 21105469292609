import { fork, take, put, select } from 'redux-saga/effects';
import {
  loadList,
  loadTypes,
  loadPlace,
  getIsSubscriber,
} from '../actions/places';

export function* placesListSaga ({ path }) {
  const { isCached } = yield select(state => state.page);

  if ( !isCached ) {
    if(window.serverSidePlaces){
      yield put({ type: 'PLACES_LIST_LOADED', payload: window.serverSidePlaces });
      delete window.serverSidePlaces;
    } else {
      yield put(loadList({ path }));
    }
  }
}

export function* placeSaga ({ path }) {
  const { isCached } = yield select(state => state.page);

  if ( !isCached ) {
    if(window.serverSidePlace){
      yield put({ type: 'PLACE_ITEM_LOADED', payload: window.serverSidePlace });
      delete window.serverSidePlace;
    } else {
      yield put(loadPlace({ path }));
    }

    yield take('PAGE_LOADED');

    const { user } = yield select(state => state.page);

    if(user.type !== 'guest') yield put(getIsSubscriber({ path }));
  }
}

function* placesTypesSaga () {
  if(window.serverSidePlaceTypes){
    yield put({ type: 'PLACES_TYPES_LOADED', payload: window.serverSidePlaceTypes });
  }

  const { types: { length } } = yield select(state => state.placeTypes);

  if ( !length )
    yield put(loadTypes());
}

export function* placesSaga () {
  yield fork(placesTypesSaga);
}
