import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './styles.sass';
import Affiche from '../affiche/Affiche';
import Places from '../places/Places';
import ProfileBar from '../ProfileBar';
import Settings from '../settings/Settings';

class MyProfile extends PureComponent {

  render () {

    const { user, page: { device } } = this.props; 

    if ( user.isGuest )
      return null;

    return (
      <div className="my-profile-container">

        <div className="my-profile-container__area">
          <div className="my-profile-container__section">
            <h1 className="my-profile-container__title">
              <Switch>
                <Route path="/users/me/affiche/">
                  Моя афиша
                </Route>
                <Route path="/users/me/places/">
                  Мои места
                </Route>
                <Route path="/users/me/settings/">
                  Настройки
                </Route>
              </Switch>
            </h1>

            <Switch>
              <Route exact path="/users/me/">
                <Redirect to="/users/me/affiche/"/>
              </Route>
              <Route
                path="/users/me/affiche/:type?/"
                render={(props) => (
                  <Affiche device={device} {...props.match.params} />
                )}
              />
              <Route path="/users/me/places/">
                <Places/>
              </Route>
              {/* <Route exact path="/users/me/notifications/">
                <Notifications user={user} notifications={notifications}/>
              </Route> */}
              <Route exact path="/users/me/settings/">
                <Settings user={user}/>
              </Route>
            </Switch>
          </div>

          {
            !device.mobile
              ? (
                <div className="my-profile-container__side">
                  <ProfileBar user={user}/>
                </div>
              )
              : null
          }
        </div>

      </div>
    );
  }
}

/* const getProps = (state) => ({
  notifications: state.notifications
})
const setActions = () => ({}) */

export default MyProfile; // connect(getProps, setActions)(MyProfile)
