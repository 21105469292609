import { put, select } from 'redux-saga/effects';
import {
  loadList,
  loadTypes,
} from '../actions/blog';

export function* blogSaga ({ path }) {
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || 0;

  yield put(loadList({ path, page }));

  const typeIsAlreadyLoaded = yield select(({ blogTypes }) => blogTypes.items.length)
  if(!typeIsAlreadyLoaded) {
    yield put(loadTypes());
  }
}

export function* blogTypesSaga () {
  const typeIsAlreadyLoaded = yield select(({ blogTypes }) => blogTypes.items.length)
  if(!typeIsAlreadyLoaded) {
    yield put(loadTypes());
  }
}
