import {
  loadListRequest,
  loadTypesRequest,
  loadItemRequest,
  setLikeRequest,
  setWillGoRequest,
} from '../api/parties';

export const loadList = (pagePath, query) => ({
  type: 'PROMISE',
  actions: ['PARTIES_LIST_LOADING', 'PARTIES_LIST_LOADED', 'PARTIES_LIST_LOAD_FAILURE'],
  promise: loadListRequest(pagePath, query),
});

export const loadTypes = () => ({
  type: 'PROMISE',
  actions: ['PARTIES_TYPES_LOADING', 'PARTIES_TYPES_LOADED', 'PARTIES_TYPES_FAILURE'],
  promise: loadTypesRequest(),
});

export const loadItem = ({ path }) => ({
  type: 'PROMISE',
  actions: ['PARTIES_ITEM_LOADING', 'PARTIES_ITEM_LOADED', 'PARTIES_ITEM_FAILURE'],
  promise: loadItemRequest({ path }),
});

export const setLiked = ({ id, isLiked, userId }) => ({
  type: 'PROMISE',
  actions: ['PARTY_LIKES_LOADING', 'PARTY_LIKES_LOADED', 'PARTY_LIKES_FAILURE'],
  meta: {
    userId,
    isLiked,
  },
  promise: setLikeRequest({ id, action: isLiked ? 'remove' : 'add' }),
});

export const setWillGo = ({ id, isWillGo, userId }) => ({
  type: 'PROMISE',
  actions: ['PARTY_WILLGO_LOADING', 'PARTY_WILLGO_LOADED', 'PARTY_WILLGO_FAILURE'],
  meta: {
    userId,
    isWillGO: isWillGo,
  },
  promise: setWillGoRequest({ id, action: isWillGo ? 'remove' : 'add' }),
});
