/**
 * Reducer for libs
 *
 * @param state
 * @param action
 * @returns {*}
 */

export default function ymaps(state = null, action) {
  switch (action.type) {
    case 'YMAPS_LOADED':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
