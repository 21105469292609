import React, { Component } from 'react';
import './styles.sass';
import { Logo }  from '..';

class Top extends Component {

  render () {

    return (
      <div className="top">
        <div className="top__tag-line">
          где ты будешь в этот
        </div>
        <div className="top__logo">
          <Logo mod="mod_animate"/>
        </div>
      </div>
    );
  } 
}

export default Top;
