import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import FastSettings from '../settings/FastSettings';
import { ReactComponent as AfficheIcon } from './images/affiche.icon.svg';
import { ReactComponent as PlacesIcon } from './images/places.icon.svg';
import { ReactComponent as SettingsIcon } from './images/settings.icon.svg';

class ProfileBar extends PureComponent {

  render () {

    const nav = [
      {
        icon: <AfficheIcon/>,
        caption: 'Моя афиша',
        path: '/users/me/affiche/', 
      },
      {
        icon: <PlacesIcon/>,
        caption: 'Мои места',
        path: '/users/me/places/',
      },
      {
        icon: <SettingsIcon/>,
        caption: 'Настройки',
        path: '/users/me/settings/',
      },
      /* {
        caption: 'Мои подписки',
        path: '/users/me/artists'
      }
      */
    ];

    const { user } = this.props;

    return (
      <div>
        <div className="my-profile-container__sign-out">
          <a href="/users/logout/">Выйти</a>
        </div>

        <div className="my-profile-container__user">
          <FastSettings user={user}/>
        </div>

        <ul className="my-profile__nav">
          {
            nav.map((item, key) => (
              <li className="my-profile__nav-item" key={key}>
                {item.icon}
                <NavLink
                  to={item.path}
                  className="my-profile__nav-item-link"
                  activeClassName="mod_active"
                >
                  {item.caption}
                </NavLink>
              </li>
            ))
          }
          {/* <li className="my-profile__nav-item">
            <NavLink
              to="/users/me/notifications/"
              className="my-profile__nav-item-link"
              activeClassName="mod_active"
            >
              Уведомления
            </NavLink>
            {
              notifications.count > 0
                ? (
                  <span className="my-profile__nav-notifications">
                    {notifications.count}
                  </span>
                )
                : null
            }
          </li> */}
        </ul>
      </div>
    );
  }

}

export default ProfileBar;
