const initialState = {
  places: [],
  items: [],
};

export default function search(state = initialState, action){
  switch (action.type){

    case 'SEARCH_LIST_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'SEARCH_LIST_LOADED':
      return {
        ...state,
        ...action.payload,
        places: action.payload.places.item?.doArray() || [],
        items: action.payload.items.item?.doArray() || [],
        status: 'loaded',
      };

    case 'SEARCH_PARTIAL_LOADING':
      return {
        ...state,
        status: 'partial-loading',
      };

    case 'SEARCH_PARTIAL_LOADED':
      return {
        ...state,
        ...action.payload,
        places: state.places,
        items: [...state.items, ...action.payload.items.item?.doArray() || []],
        status: 'loaded',
      };

    default:
      return state;
  }
}
