import React, { Component } from 'react';
import './styles.scss';
import { ReactComponent as IconClosedEye } from './images/eye-closed.icon.svg';
import { ReactComponent as IconOpenedEye } from './images/eye-open.icon.svg';
import { ReactComponent as IconChecked } from './images/checked.icon.svg';
import { ReactComponent as IconHelp } from './images/help.icon.svg';

class Input extends Component {

  constructor (props) {
    super(props); 

    this.state = {
      value: props.value || '',
      focused: false,
      filled: !!props.value?.length,
      valid: undefined,
      canShowPassword: false,
      isPasswordShow: false,
      isHelpShow: false,
      isLengthEnabled: false,
      isCaseEnabled: false,
      isNumberEnabled: false,
    };
  }

  componentDidMount () {
    const { isFocused, value, onChange } = this.props;

    if ( isFocused )
      this.field.focus();

    if(value && value.length){
      const valid = this.validate();

      if ( valid ) {
        this.setState({
          valid,
        });
        onChange?.(valid, value);
      }
    }

    document.body.addEventListener('click', this.handleClickOutsideHelp);
  }

  UNSAFE_componentWillUpdate (nextProps) {
    const { value } = this.props;

    if ( value !== nextProps.value )
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        value: nextProps.value,
        filled: !!nextProps.value.length,
      });
  }

  componentDidUpdate (prevProps, prevState) {
    const { value } = this.state;

    if ( value && JSON.stringify(prevProps) !== JSON.stringify(this.props) ) {
      const valid = this.validate();

      if ( valid !== prevState.valid )
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          valid,
        });
    }
  }

  componentWillUnmount () {
    document.body.removeEventListener('click', this.handleClickOutsideHelp);
  }

  handleEyeClick = () => {
    const { isPasswordShow, value } = this.state;

    this.setState({
      isPasswordShow: !isPasswordShow,
    });

    const valueLength = value.length;

    this.field.focus();
    setTimeout(() => {
      this.field.setSelectionRange(
        valueLength * 2,
        valueLength * 2,
      );
    }, 0);
  }

  handleClickOutsideHelp = event => {
    const isIcon = event.target.matches('[data-role="help-opener"]') || event.target.closest('[data-role="help-opener"]');
    const { isHelpShow } = this.state;

    this.setState({
      isHelpShow: !isIcon ? false : !isHelpShow,
    });
  }

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  }

  handleBlur = () => {
    const { onBlur } = this.props;

    this.setState({
      focused: false,
    });

    onBlur?.();
  }

  handleInput = event => {
    const { validate, onChange, type } = this.props;
    const { value } = event.target;
    let valid = true;

    if ( validate ) {
      valid = this.validate();
    }

    let state = {
      value,
      filled: !!value.length,
      valid,
    };

    if ( type === 'password' ) {
      state = {
        ...state,
        ...this.password(value),
      };
    }

    this.setState(state);

    const newValue = type === 'file' ? event.target.files : value;

    onChange?.(valid, newValue);
  }

  handleKeyPress = event => {
    const { onKeyPress } = this.props;

    onKeyPress?.(event);
  }

  password = value => {
    const { minlength } = this.props;
    let state = {};

    if ( value.length === 0 ) {
      state = {
        ...{
          canShowPassword: false,
          isPasswordShow: false,
        },
      };
    }

    if ( value.length === 1 ) {
      state.canShowPassword = true;
    }

    state = {
      ...state,
      ...{
        isLengthEnabled: minlength ? value.length >= minlength : true,
        isCaseEnabled: /[A-ZА-ЯЁ]+/.test(value),
        isNumberEnabled: /[0-9]+/.test(value),
      },
    };

    return state;
  }

  validate = () => {
    const { required, email, minlength, maxlength, type, customValidate } = this.props;
    const { value } = this.field;

    if ( required ) {
      const valid = value.trim().length;

      if ( !valid )
        return false;
    }

    if ( email ) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
      const valid = re.test(value);

      if ( !valid )
        return false;
    }

    if ( minlength ) {
      if ( value.length < minlength )
        return false;
    }

    if ( maxlength ) {
      if ( value.length > maxlength )
        return false;
    }

    if ( customValidate && typeof customValidate === 'function' ) {
      const custom = customValidate(value);

      if ( !custom )
        return false;
    }

    if ( type === 'password' ) {
      if ( !/[A-ZА-ЯЁ]+/.test(value) || !/[0-9]+/.test(value) )
        return false;
    }

    return true;
  }

  render () {

    const {
      placeholder,
      type,
      name,
      minlength,
      maxlength,
      autoComplete,
      validate,
      accept,
      fixMaxLength,
      hideHelp,
    } = this.props;

    const {
      value,
      focused,
      filled,
      valid,
      isPasswordShow,
      canShowPassword,
      isHelpShow,
      isLengthEnabled,
      isCaseEnabled,
      isNumberEnabled,
    } = this.state;

    const attrs = {
      type: type === 'password' && isPasswordShow ? 'text' : type,
      name,
      ...(type !== 'file' ? null : { accept }),
      ...(type === 'password' ? null : { value }),
      ...(fixMaxLength && maxlength ? { maxLength: maxlength } : null),
    };

    if ( autoComplete )
      attrs.autoComplete = autoComplete;

    return (
      <div className={
        `input-text${ 
          focused ? ' mod_focused' : '' 
        }${filled ? ' mod_filled' : '' 
        }${isPasswordShow ? ' mod_eye-opened' : '' 
          // eslint-disable-next-line no-nested-ternary
        }${validate && typeof valid !== 'undefined' ? (!valid ? ' mod_invalid' : ' mod_valid') : '' 
          // eslint-disable-next-line no-nested-ternary
        }${type === 'password' ? (canShowPassword ? ' mod_password-show' : ' mod_password') : ''}`
      }>
        <input
          ref={ref => {
            this.field = ref;
          }}
          className="input-text__field"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleInput}
          onKeyPress={this.handleKeyPress}
          {...attrs}
        />
        <div className="input-text__placeholder">{placeholder}</div>
        <div className="input-text__indicator">
          <div className="input-text__indicator-filled"/>
          <div className="input-text__indicator-invalid"/>
          <div className="input-text__indicator-focused"/>
          <div className="input-text__indicator-valid"/>
        </div>
        {
          type === 'password' && canShowPassword
            ? (
              // eslint-disable-next-line
              <div
                className="input-text__eye"
                onClick={this.handleEyeClick}
              >
                {
                  isPasswordShow
                    ? <IconOpenedEye/>
                    : <IconClosedEye/>
                }
              </div>
            )
            : null
        }
        {
          type === 'password' && !hideHelp
            ? (
              <>
                <div
                  className={`input-text__help-opener${  isHelpShow ? ' mod_enabled' : ''}`}
                  data-role="help-opener"
                >
                  <IconHelp/>
                </div>
                <div className={`input-text__help${  isHelpShow ? ' mod_enabled' : ''}`}>
                  <div className="input-text__help-inner">
                    <div className="input-text__help-caption">
                      Пароль должен содержать:
                    </div>
                    {
                      minlength
                        ?
                        <div className="input-text__help-item">
                          <div className={`input-text__help-icon${  isLengthEnabled ? ' mod_enabled' : ''}`}>
                            <IconChecked/>
                          </div>
                          не меньше {minlength} символов
                        </div>
                        : null
                    }
                    <div className="input-text__help-item">
                      <div className={`input-text__help-icon${  isCaseEnabled ? ' mod_enabled' : ''}`}>
                        <IconChecked/>
                      </div>
                      хотя бы одну заглавную букву
                    </div>
                    <div className="input-text__help-item">
                      <div className={`input-text__help-icon${  isNumberEnabled ? ' mod_enabled' : ''}`}>
                        <IconChecked/>
                      </div>
                      хотя бы одну цифру
                    </div>
                  </div>
                </div>
              </>
            )
            : null
        }
      </div>
    );
  }
}

export default Input;
