import { put, select } from 'redux-saga/effects';
import { loadTypes, loadItem } from '../actions/parties';

export function* partiesSaga () {
  const { types: { length } } = yield select(state => state.partyTypes);

  if ( !length )
    yield put(loadTypes());
}

export function* partiesItemSaga ({ path }) {
  yield put(loadItem({ path }));
}
