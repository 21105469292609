import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import {
  SquarePicture,
  CombinedDate,
  PhoneNumber,
} from '../../../../common';
import PlaceCard from '../../../../parties/PlaceCard';
import Infographic from './Infographic';
import {
  IconPlace,
  IconPhone,
  IconClock,
} from '../../../../common/images'; 

const List = props => {

  const { parties } = props;

  return (
    <>
      {
        parties.length
          ? (
            <div className="my-profile-affiche__items">
              {
                parties.map((party, i) => {

                  const { place } = party;
                  const startDate = party['date-start']['unix-timestamp'];
                  const endDate = party['date-end']['unix-timestamp'];

                  return (
                    <div className="parties-list-item" key={i}>
                      <Link to={party.link}>
                        <SquarePicture
                          image={party?.image}
                          alt={party.name}
                          mobileSimple
                        />
                      </Link>
                      <div className="parties-list-item__title">
                        <Link to={party.link}>
                          {party.name}
                        </Link>
                      </div>

                      <div className="parties-list-item__properties">
                        <div className="parties-list-item__property parties-list-item__property_date">
                          <i className="parties-list-item__icon mod_time">
                            <IconClock/>
                          </i>
                          <CombinedDate
                            startDate={startDate}
                            endDate={endDate}
                            useWordInTime
                            allwaysShowDates
                          />
                        </div>

                        <div className="parties-list-item__property">
                          <i className="parties-list-item__icon">
                            <IconPlace/>
                          </i>
                          <div>
                            <PlaceCard place={place}/>
                          </div>
                        </div>

                        {
                          place?.phone
                            ?
                            <div className="parties-list-item__property parties-list-item__property_phone">
                              <i className="parties-list-item__icon">
                                <IconPhone/>
                              </i>
                              <div>
                                <PhoneNumber phone={place.phone}/>
                              </div>
                            </div>
                            : null
                        }
                      </div>

                    </div>
                  );
                })
              }
            </div>
          )
          : (
            <Infographic/>
          )
      }
    </>
  );
};

export default List;
