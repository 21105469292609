const initialState = {
  items: [],
};

export default function promo(state = initialState, action){
  switch (action.type){

    case 'PROMO_LIST_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'PROMO_LIST_LOADED':
      return {
        ...state,
        ...action.payload,
        items: action.payload.items?.item.doArray() || [],
        status: 'loaded',
      };

    default:
      return state;
  }
}
