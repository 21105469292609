import {
  loadAuthorRequest,
  loadListRequest,
  loadPostRequest,
} from '../api/blogs';

export const loadAuthor = ({ path }) => ({
  type: 'PROMISE',
  actions: ['BLOGS_AUTHOR_LOADING', 'BLOGS_AUTHOR_LOADED', 'BLOGS_LIST_AUTHOR_FAILURE'],
  promise: loadAuthorRequest({ path }),
});

export const loadList = ({ path }) => ({
  type: 'PROMISE',
  actions: ['BLOGS_LIST_LOADING', 'BLOGS_LIST_LOADED', 'BLOGS_LIST_LOAD_FAILURE'],
  promise: loadListRequest({ path }),
});

export const loadPost = ({ path }) => ({
  type: 'PROMISE',
  actions: ['BLOGS_ITEM_LOADING', 'BLOGS_ITEM_LOADED', 'BLOGS_ITEM_LOAD_FAILURE'],
  promise: loadPostRequest({ path }),
});
