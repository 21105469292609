import React, { useEffect, useState } from 'react';
import './styles.sass';

export const  ButtonLoader = (
  {
    mod= '',
    icon,
    text= 'Действие',
    isDisabled= false,
    isLoading= false,
    isResponsive = false,
    onClick,
  },
) => {
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    if(isLoading){
      setCounter(setTimeout(
        () => setCounter(null),
        800,
      ));
    }

    return () => clearTimeout(counter);
  }, [isLoading]);

  const handler = typeof onClick === 'function' && !isDisabled && !isLoading ? { onClick } : null;
  const isAnimated = isLoading || counter;
  const className = `button-loader${isResponsive ? '-responsive' : ''}`;
  const iconElement = icon ? <div className={`${className}__icon`}>{icon}</div> : null;

  return (
    <div className={className + (isAnimated ? ' mod_loading' : '') + (isDisabled ? ' mod_disabled' : '') + (mod ? ` ${  mod}` : '')}>
      <div className={`${className}__circle`}/>
      <div className={`${className}__button`} {...handler}>
        {iconElement}
        <div className={`${className}__caption`}>{text}</div>
      </div>
    </div>
  );
};
