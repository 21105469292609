import React, { useContext } from 'react';
import './styles.sass';
import { NavLink } from 'react-router-dom';
import PageContext from '../../../contexts/page';

const NavBar = ({ links, isActive, children, ...rest }) => {

  const { page: { path } } = useContext(PageContext);

  return (
    <div className="nav-bar" {...rest}>
      {links.map((item, i) => {
        const isCurrentPageLink = path === item.link;
        const Node = isCurrentPageLink ? 'span' : NavLink;
        const settings = isCurrentPageLink 
          ? {
            className: 'nav-bar__item mod_active',
          }
          : {
            exact: true,
            to: item.link,
            className: 'nav-bar__item',
            activeClassName: 'mod_active',
            isActive: isActive ? isActive(item.link) : null,
          };

        return (
          <Node
            key={item.id || `index-${i}`}
            {...settings}
          >
            {item.name}
          </Node>
        );
      })}
      {children}
    </div>
  );
};

export default NavBar;
