export const generateSeoTitle = ({ page }) => {
  let prefix = '';
  const
    { title } = page;

  if(page.method === 'notfound'){
    const title = 'Страница не найдена';

    document.getElementById('og-title').setAttribute('content', title);
    document.title = title;

    return title;
  }

  const seoTitle = `${prefix}${title}`;

  document.getElementById('og-title').setAttribute('content', seoTitle);
  document.title = seoTitle;

  return seoTitle;
};

export const setSeoDescription = (description) => {
  const cleanDescription = description.replace(/(<([^>]+)>)/gi, '').replace('"', '&quot;');

  document
    .getElementById('meta-description')
    .setAttribute('content', cleanDescription);
  document
    .getElementById('og-description')
    .setAttribute('content', cleanDescription);
};

export const generateSeoDescription = ({ page }) => {
  if(page.method === 'notfound'){
    setSeoDescription('Страница не найдена');
  } else {
    const { description } = page;

    setSeoDescription(description || '');
  }
};

export const generateSeoKeywords = ({ page }) => {
  if(page.method === 'notfound'){
    document
      .getElementById('meta-keywords')
      .setAttribute('content', '');
  } else {
    const { keywords } = page;

    document
      .getElementById('meta-keywords')
      .setAttribute('content', keywords || '');
  }
};

export const generateSeoCanonical = ({ page: { domain, path } }) => {
  const element = document.getElementById('link-canonical');

  if(element){
    element.setAttribute('href', `https://${domain}${path}`);
  }
};
