import React from "react";
import moment from "moment";

export const TopDate = ({ startDate, endDate, weekly, isMobile }) => {
  const startDateDay = startDate.date();
  const endDateDay = endDate.date();
  const endDateHour = endDate.hour();
  const endDateMinute = endDate.minute();


  const visibleEndDate = moment(endDate);

  if ( endDateHour < 9 )
    visibleEndDate.subtract(1, 'days');

  const isOneDay = !(endDateDay - startDateDay) ||
    (endDateDay - startDateDay === 1 && (endDateHour < 9 ||
      (endDateHour === 9 && endDateMinute === 0)));
  const isManyDays = !isOneDay;
  const isOneMonth = startDate.month() === endDate.month();

  return (
    <>
      <div className="party-item__date-item">
        {
          weekly
            ? (
              <>
                <div className="party-item__date-digits">
                  {startDate?.format('ddd')}
                </div>
                <div className="party-item__date-word"/>
              </>
            )
            : (
              <>
                <div className="party-item__date-digits">
                  {startDate?.format('DD')}
                </div>
                {
                  (!isManyDays || !isOneMonth) &&
                  <div className="party-item__date-word">
                    {
                      !isMobile
                        ? startDate?.format('MMM').substring(0, 3)
                        : startDate?.format('DD MMMM').substring(3)
                    }
                  </div>
                }
              </>
            )
        }
      </div>
      {
        isManyDays &&
        <div className="party-item__date-item mod_end">
          <div className="party-item__date-digits">
            {visibleEndDate?.format('DD')}
          </div>
          <div className="party-item__date-word">
            {
              !isMobile
                ? visibleEndDate?.format('MMM').substr(0, 3)
                : visibleEndDate?.format('DD MMMM').substr(3)
            }
          </div>
        </div>
      }
    </>
  )
}
