import React, { useState } from 'react';

const Picture = ({ image, alt = '', width = 282, className = '', onImageLoaded = () => {}, loading, itemProp }) => {
  const [webp, setWebp] = useState(image?.webp);
  const loadingAttr = loading ? { loading } : null;

  if(!image){
    console.warn('Picture: image not defined');
    return null;
  }

  const onError = () => {
    setWebp(null); 
  };

  const title = alt ? { title: alt } : '';

  return (
    <picture className={className}>
      {webp && <source type="image/webp" srcSet={webp}/>}
      <img
        src={image.src}
        width={width}
        alt={alt}
        itemProp={itemProp}
        onLoad={onImageLoaded}
        onError={onError}
        {...title}
        {...loadingAttr}
      />
    </picture>
  );
};

export default Picture;
