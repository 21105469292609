import React, { Component } from 'react';
import './styles.sass';

class Select extends Component {
  constructor() {
    super();

    this.state = {
      selected: 0,
      isOpened: false,
      value: null,
    };
  }

  componentDidMount () {
    this.setDefaults();
  }

  componentDidUpdate (prevProps) {
    this.setDefaults(prevProps);
  }

  setDefaults = (prevProps = {}) => {
    const { options, value } = this.props;

    let selected = options
      .map(item => item.value)
      .indexOf(value);

    selected = selected >= 0 ? selected : 0;

    if ( JSON.stringify(prevProps) === JSON.stringify(this.props) )
      return;

    this.setState({
      selected,
      value,
    });
  }

  handleOpen = () => {
    const { isOpened } = this.state;

    this.setState({
      isOpened: !isOpened,
    });
  }

  handleClose = () => {
    this.setState({
      isOpened: false,
    });
  }

  handleOptionClick = event => {
    const { index, value } = event.target.dataset;

    this.selectNode.selectedIndex = index;

    this.setState({
      selected: index,
      isOpened: false,
      value,
    });

    const { onChange } = this.props;

    onChange(value);
  }

  render () {

    const { name, options } = this.props;
    const { isOpened, selected, value } = this.state;

    return (
      <div
        className={`select-box${  isOpened ? ' mod_opened' : ''}`}
        tabIndex="0"
        onBlur={this.handleClose}
      >
        {/* eslint-disable-next-line */}
        <div className="select-box__wrap" onClick={this.handleOpen}>
          <div className="select-box__label">
            {options[selected]?.label}
          </div>
        </div>
        <div className="select-box__list">
          {
            options.map((item, i) =>
              // eslint-disable-next-line
              <div
                key={i}
                data-index={i}
                data-value={item.value}
                onClick={this.handleOptionClick}
              >
                {item.label}
              </div>,
            )}
        </div>
        <select
          name={name}
          ref={element => { this.selectNode = element; }}
          style={{ position: 'fixed', top: '-5000px' }}
          defaultValue={value}
        >
          {
            options.map((item, i) =>
              <option
                key={i}
                value={item.value}
              >
                {item.label}
              </option>,
            )}
        </select>
      </div>
    );
  }
}

export default Select;
