interface PagesCache {
  store: Record<string, unknown>[],
  add: (key: string, item: unknown) => void,
  get: (key: string) => void
}

export const createPageCache = (): PagesCache => {
  const pagesCache: PagesCache = {
    store: [],
    add: (key, page) => {
      const exist = pagesCache.store.findIndex(item => item[key]);

      if (exist >= 0)
        pagesCache.store.splice(exist, 1);

      pagesCache.store.push({
        [key]: page,
      });

      if (pagesCache.store.length > 5)
        pagesCache.store.shift();
    },
    get: (key) => {
      const foundPage = pagesCache.store.find(page => page[key]);

      return foundPage ? foundPage[key] : null;
    },
  };

  return pagesCache;
};

export const queryToObject = (query: string): Record<string, string> => {
  if (!query)
    return {};

  const pairs = query.substring(1).split('&');
  const obj = {};
  let pair;

  try {
    pairs.forEach(item => {
      if (item === ''){
        return;
      }

      pair = item.split('=');

      // @ts-ignore
      obj[decodeURIComponent(pair[0])]
        = decodeURIComponent(pair[1]);
    });
  } catch (e) {
    return {};
  }

  return obj;
};
