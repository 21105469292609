import React, { Component } from 'react';
import './styles.scss';

class FooterSpoiler extends Component {
  constructor() {
    super();

    const { opened } = this.props || {};

    this.state = {
      opened: opened || false,
    };
  }

  handleClick = () => {
    const { opened } = this.state;
 
    this.setState({
      opened: !opened,
    });
  }

  render() {

    const { caption, children } = this.props;
    const { opened } = this.state;
    const openedClassName = opened ? 'mod_opened' : '';

    return (
      <div className={`footer-spoiler ${openedClassName}`}
        aria-label="Область с ссылками дополнительной навигации">
        {/* eslint-disable-next-line */}
        <div className="footer-spoiler__caption" onClick={this.handleClick}
          aria-label="Заголовок спойлера"
          aria-expanded={opened}>
          {caption}
        </div>
        <div className="footer-spoiler__body"
          aria-label="Коентент под спойлером"
          aria-hidden={!opened}>
          {children}
        </div>
      </div>
    );
  }
}

export default FooterSpoiler;
