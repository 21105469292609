const initialState = {
  items: [],
};

export default function mainMenu(state = initialState, action){
  switch (action.type){

    case 'MENU_LOADING':
      return {
        ...state,
        status: 'loading',
      };

    case 'MENU_LOADED':
      return {
        ...state,
        status: 'loaded',
        items: action.payload.items?.item.doArray() || [],
      };

    default:
      return state;
  }
}
