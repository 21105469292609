import { combineReducers } from 'redux';
import ymaps from './ymaps';
import { page } from './page';
import mainMenu from './mainMenu';
import user from './user';
import {
  parties,
  partyTypes,
  partyItem,
} from './parties';
import {
  places,
  placeTypes,
  placeItem,
} from './places';
import {
  blog,
  blogTypes,
} from './blog';
import {
  blogs,
  blogsAuthor,
  blogsPost,
} from './blogs';
import artists from './artists';
import mapPlaces from './mapPlaces';
import search from './search';
import comments from './comments';
import myaffiche from './myaffiche';
import promo from './promo';
import notifications from './notifications';

const rootReducer = combineReducers({
  page,
  mainMenu,
  user,
  parties,
  partyTypes,
  partyItem,
  places,
  placeTypes,
  placeItem,
  mapPlaces,
  blog,
  blogTypes,
  blogs,
  blogsAuthor,
  blogsPost,
  artists,
  ymaps,
  search,
  comments,
  myaffiche,
  promo,
  notifications,
});

export default rootReducer;
